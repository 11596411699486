<script lang="ts">
  import TelField from "$components/form/TelField.svelte";
  import TenantField from "$components/form/TenantField.svelte";
  import TextField from "$components/form/TextField.svelte";
  import Copy from "$components/util/Copy.svelte";
  import { fetchSend } from "$utils/api";
  import { tenantsByUnit, units } from "$utils/propertystores";
  import { createEventDispatcher } from "svelte";

  export let property: Property;
  //export let unit: Unit;

  export const values: Record<string, any> = {};

  let submittable = false;
  let submitting = false;
  let form: HTMLFormElement;
  const events = createEventDispatcher();

  //$: logger(tenant);

  async function onsubmit(e: SubmitEvent) {
    e.preventDefault();
    if (!submittable || submitting) return;
    e.preventDefault();

    // preprocess
    const form = e.target as HTMLFormElement;
    const formdata = new FormData(form);

    submitting = true;

    // do the stuff
    return fetchSend(property, formdata)
      .then(function (json) {
        if (json.message) alert(json.message);
        else {
          var items = Object.values(json.send?.created ?? [])
            .map(
              (value: string) => json.items[value] ?? json.send.items?.[value]
            )
            .reduce((result, item) => {
              result[item.id] = item;
              return result;
            }, {});

          events("complete", property);
          form.reset();
        }
      })
      .catch(function (error) {
        alert("Whoops, something went wrong!");
      })
      .then(function () {
        // cleanup
        submitting = false;
      }); // how do we handle statuses here?
  }

  function onreset(e) {
    events("cancel", property);
  }

  function updateSubmittable(form) {
    submittable =
      !!form?.checkValidity() && (values.to || values.tel || values.email);
  }

  function change(name, value) {
    values[name] = value;
    events("change", values);
  }

  function validate(e) {
    //logger("onchange=", e.target.name, e.target.value, e);
    updateSubmittable(e.target.form); // additional logic?
  }

  $: form && updateSubmittable(form);
</script>

<form
  class="send"
  bind:this={form}
  on:submit={onsubmit}
  on:input={validate}
  on:change={validate}
  on:reset={onreset}
  novalidate
>
  <input type="hidden" name="subject" value={property.id} />
  <input type="hidden" name="context" value="welcome" />
  <header>
    <h1>Accessing the directory:</h1>
  </header>
  <ul>
    <li>
      <figure class="value">
        <figcaption>Direct Link</figcaption>

        <a
          href="https://my.communityhq.app?property={property.id}"
          target="_blank">Directory Map & Guide</a
        >
        <Copy value="https://my.communityhq.app?property={property.id}" />
      </figure>
    </li>
    <!-- <li>
      <fieldset>
        <label for="welcome-notify-format">Message</label>
        <select name="context">
          <option value="welcome">welcome</option>
          <option value="parking">parking</option>
          <option value="amenity">amenities</option>
        </select>
      </fieldset>
    </li> -->
    <li>
      <TextField
        name="to"
        label="Send To"
        placeholder="email or text"
        on:change={(e) => change(e.detail.name, e.detail.value)}
      />
    </li>
  </ul>
  <footer>
    <button disabled={!submittable || submitting} type="submit"
      >{submitting ? "Sending…" : "Send Welcome"}</button
    >
  </footer>
</form>
