<script lang="ts" context="module">
  //policies.subscribe(($value) => logger("policies changed=", $value));
  const now = time("PT1M");
</script>

<script lang="ts">
  import Warning from "$components/Warning.svelte";
  import Loading from "$components/Loading.svelte";
  import {
    policies,
    permits as allPermits,
    externalCRMOccupancyData,
  } from "$utils/propertystores";
  import PermitPolicySelector from "$components/permit/PermitPolicySelector.svelte";
  import ItemsList from "$components/item/ItemsList.svelte";
  import {
    comparer,
    dateAsc,
    textAsc,
    textDesc,
    textPlainAsc,
  } from "$utils/sort";
  import { time } from "$utils/timestores";
  import { parseISO } from "date-fns";
  import Time from "$components/Time.svelte";
  import { smscreen } from "$utils/behaviorstores";
  import TenantNotify from "$components/tenant/TenantNotify.svelte";
  import { level } from "$components/space";

  export let property: Property = null;

  $: activeTempVehicles =
    $allPermits?.items &&
    Object.values($allPermits?.items)
      .filter(
        (permit: any) =>
          permit.vehicle &&
          !permit.continuous &&
          !permit.media &&
          !permit.expired &&
          !permit.cancelled
      )
      .sort(comparer("vehicle.display", textPlainAsc));

  $: assignedPermits =
    $allPermits?.items &&
    Object.values($allPermits?.items).filter(
      (permit: any) =>
        permit.continuous &&
        "parking" === permit.amenity &&
        !permit.expired &&
        !permit.cancelled
    );

  $: printedPassPolicies = $policies?.filter(
    (p) =>
      p.amenity === "parking" &&
      !!p.audience?.admin &&
      p.permit.printed?.required
  );

  $: activePasses =
    $allPermits?.items &&
    Object.values($allPermits?.items)
      .filter(
        (permit: any) =>
          permit.pass &&
          !permit.expired &&
          //parseISO(permit.valid.max.datetime) > $now &&
          parseISO(permit.valid.min.datetime) < $now
      )
      .sort(comparer("valid.max.datetime", dateAsc));

  $: upcomingPasses =
    $allPermits?.items &&
    Object.values($allPermits?.items)
      .filter(
        (permit: any) =>
          permit.pass && parseISO(permit.valid.min.datetime) > $now
      )
      .sort(comparer("valid.min.datetime", dateAsc));

  // $: activeMedia =
  //   $allPermits?.items &&
  //   Object.values($allPermits?.items)
  //     .filter(
  //       (permit: any) =>
  //         "parking" === permit.amenity && permit.media && !permit.expired
  //     )
  //     .sort(comparer("media.display", textAsc));

  // $: activeAssignedSpaces =
  //   $allPermits?.items &&
  //   Object.values($allPermits?.items)
  //     .filter(
  //       (permit: any) =>
  //         "parking" === permit.amenity &&
  //         permit.space &&
  //         !permit.media &&
  //         permit.continuous &&
  //         !permit.expired
  //     )
  //     .sort(comparer("space.display", textAsc));

  $: parkingPolicies = $policies
    ?.filter((item) => item.amenity === "parking" && item.enabled)
    .sort(comparer("title"));

  //$: logger("policies=", $policies);
</script>

<section class="parking legacy">
  <header>
    {#if property}
      <h1>Parking</h1>

      {#if !property.parking?.enabled}
        <Warning message="Parking isn't enabled" />
      {/if}
      {#if !!property.parking?.enabled && !$smscreen}
        <a href="/properties/{property.id}/parking/map">
          <img
            alt="Map preview"
            src={`https://static.carto.communityboss.app/cdn-cgi/image/w=600,h=400,format=auto/?property=${
              property.id
            }&level=${$level ?? ""}&size=900x600`}
          />
          <dfn>Smart Parking Map</dfn></a
        >
        <!-- <PropertyMap {property} interactive={false}>
          <a href="/properties/{property.id}/parking/map"
            ><dfn>Smart Parking Map</dfn></a
          >
        </PropertyMap> -->
      {/if}
      <dl>
        <dt>Synced</dt>
        <dd><Time datetime={$allPermits?.generated} /></dd>
      </dl>
    {:else}
      <Loading />
    {/if}
  </header>

  {#if property?.parking?.enabled}
    {#if property.vehicles?.permits}
      <section class="permitted">
        <header>
          <h1>{property.attendant?.title || "Vehicle Permits"}</h1>
          {#if $policies}
            <nav>
              <ul>
                <li>
                  <PermitPolicySelector
                    {property}
                    policies={$policies.filter(
                      (p) =>
                        p.amenity === "parking" &&
                        p.permit.temporary &&
                        p.audience.admin &&
                        p.vehicle?.required
                    )}
                  />
                </li>
              </ul>
            </nav>
          {/if}
        </header>
        <ItemsList
          loading="Loading vehicle permits"
          class="info items"
          items={activeTempVehicles}
        />
      </section>
    {/if}
    <!-- <section class="permitted">
    <header>
      <h1>Recent & Future</h1>
    </header>
    
  </section> -->
    <section class="assigned">
      <header>
        <h1>Assigned</h1>
        {#if $policies}
          <nav>
            <ul>
              <li>
                <PermitPolicySelector
                  {property}
                  policies={$policies.filter(
                    (p) =>
                      p.amenity === "parking" &&
                      !!p.audience?.admin &&
                      //p.media?.required &&
                      p.permit.continuous
                  )}
                />
              </li>
            </ul>
          </nav>
        {/if}
        <h2>Last 20 assigned:</h2>
      </header>
      <ItemsList
        loading="Loading latest"
        class="info items"
        items={assignedPermits
          ?.sort(comparer("issued.datetime", textDesc))
          .slice(0, 20)}
      />
    </section>
    <!-- {#if property.media?.enabled}
      <section class="media">
        <header>
          <h1>Active Smart Decals</h1>
          {#if $policies}
            <nav>
              <ul>
                <li>
                  <PermitPolicySelector
                    {property}
                    policies={$policies.filter(
                      (p) =>
                        p.amenity === "parking" &&
                        !!p.audience?.admin &&
                        p.media?.required &&
                        p.permit.continuous
                    )}
                  />
                </li>
              </ul>
            </nav>
          {/if}
        </header>
        <ItemsList
          loading="Loading active Smart Decals"
          class="info items"
          items={activeMedia}
        />
      </section>
    {/if}
    {#if property.spaces?.permits}
      <section class="spaces">
        <header>
          <h1>Assigned Spaces</h1>
          {#if $policies}
            <nav>
              <ul>
                <li>
                  <PermitPolicySelector
                    {property}
                    policies={$policies.filter(
                      (p) =>
                        p.amenity === "parking" &&
                        !!p.audience?.admin &&
                        p.space?.required &&
                        p.permit.continuous
                    )}
                  />
                </li>
              </ul>
            </nav>
          {/if}
        </header>
        <ItemsList
          loading="Loading assigned spaces"
          class="info items"
          items={activeAssignedSpaces}
        />
      </section>
    {/if} -->
    {#if printedPassPolicies?.length}
      <section class="passes">
        <header>
          <h1>Printed Passes</h1>

          <nav>
            <ul>
              <li>
                <PermitPolicySelector
                  {property}
                  policies={printedPassPolicies}
                />
              </li>
            </ul>
          </nav>
        </header>
        <ItemsList
          loading="Loading active passes"
          class="info items"
          items={activePasses}
        />
        <ItemsList
          loading="Loading upcoming passes"
          class="info items"
          items={upcomingPasses}
        />
      </section>
    {/if}
    <section>
      <header><h1>Parking Types</h1></header>
      <nav>
        <ul>
          <li>
            <TenantNotify variant="parking" />
            <!-- <a
                href="https://amenitypass.app/properties/{property.id}"
                target="_blank">Amenity Pass App</a
              >
              <Copy
                value="https://amenitypass.app/properties/{property.id}"
              /> -->
          </li>
        </ul>
      </nav>
      <ItemsList class="info" items={parkingPolicies} />
    </section>
  {/if}
</section>
