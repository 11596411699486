<script>
  import { record } from "$utils/filter";
  import Record from "$components/record/RecordItem.svelte";
  import { format, toZonedTime } from "date-fns-tz";
  import startCase from "lodash-es/startCase";
  import TimeIntervalItem from "./TimeIntervalItem.svelte";
  import ViolationExceptionChartItem from "./ViolationExceptionChartItem.svelte";
  import Time from "./Time.svelte";
  import { title as proper } from "$utils/text";
  export let item = null;
  //$: logger(item);
</script>

{#if item}
  <article
    class="violations details"
    class:exception={item.active}
    data-id={item.id}
  >
    {#if item.active}
      <header>
        <h1>
          <data value={item.id}
            ><dfn>{startCase(item.title.toLowerCase())}</dfn></data
          >
          <!-- <TimeIntervalItem {item} /> -->
        </h1>
        <time datetime={item.interval}>
          <data class="violations count">{item.count}</data>
        </time>
      </header>
      <!-- <Time
        datetime={item.latest}
        timezone={item.timezone}
        format={"h:mm a EEE MMM d yyyy zzz"}
      /> -->
      <!-- <p>
        <data class="limit" value={item.limit.count}
          ><dfn>After</dfn>
          <time datetime={item.duration}>{item.limit.display}</time></data
        >
      </p> -->
      <!-- <ul class="subjects">
        {#each [item.subject].filter(record) as item}
          <li><Record {item} /></li>
        {/each}
      </ul> -->
      <!-- {#if item.violations}
    <ViolationExceptionChartItem {item} />
    {/if} -->
      <dl>
        {#each [item.subject].filter(record) as record}
          <dt>{proper(record.format || record.type)}</dt>
          <dd><Record item={record} /></dd>
          <dd>{item.display}</dd>
        {/each}
        <dt>Latest</dt>
        <dd>
          <Time
            datetime={item.latest}
            timezone={item.timezone}
            format={"h:mm a EEE MMM d yyyy zzz"}
          />
        </dd>
        <dt>Threshold</dt>
        <dd>{item.limit.display}</dd>
        <!-- <dt>Status</dt>
        
        <dt>Latest</dt> -->
      </dl>
      <!-- <p>
        <data class="count" value={item.count}
          ><dfn>Has {item.display}</dfn>{#if item.latest}
            <time datetime={item.latest}
              >, <dfn>most recently at</dfn>
              <abbr
                >{format(
                  toZonedTime(item.latest, item.timezone),
                  "h:mm a MMM d yyyy zzz",
                  { timeZone: item.timezone }
                )}</abbr
              ></time
            >{/if}</data
        >
      </p> -->
    {/if}
  </article>
{/if}
