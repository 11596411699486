<script lang="ts">
  import Time from "$components/Time.svelte";

  export let permit: Permit;
  $: validations = Object.values(
    permit?.validations?.items || permit?.validations || {}
  );
</script>

{#if permit}
  <dl>
    <dt>{permit.issued.title}</dt>
    <dd>
      <Time
        datetime={permit.issued.utc}
        timezone={permit.timezone}
        format="h:mm a EEE MMM d yyyy zzz"
      />
    </dd>
    <dt>By</dt>

    {#each [permit.issued.by, permit.issued.user]
      .filter((item) => item && item.id)
      .slice(0, 1) as user}
      <dd>
        <a href="mailto:{user.email}"
          ><data class="user {user.type}" value={user.id}
            ><dfn>by</dfn> {user.name}</data
          ></a
        >
      </dd>
    {:else}
      <dd>Self Service Registration</dd>
    {/each}
    {#if validations.length}
      <dd>Validated by Smart Guard</dd>
    {/if}
    <dt>Policy</dt>
    <dd>{permit.policy?.title ?? "--"}</dd>

    <dt>Record #</dt>
    <dd><data value={permit.number || ""}>{permit.number || "--"}</data></dd>
  </dl>
{/if}
