<script lang="ts" context="module">
  const sameYear = new Intl.DateTimeFormat("en-US", {
    //weekday: "short",
    year: "numeric",
    month: "short",
    //day: "numeric",
  });

  const diffYear = new Intl.DateTimeFormat("en-US", {
    //weekday: "short",
    year: "numeric",
    month: "short",
    //day: "numeric",
  });

  export function format(
    plaindate: string | Temporal.PlainDate | Temporal.PlainDateTime
  ) {
    const formattable = parseISO(plaindate.toString());

    const dateFormat =
      formattable.getFullYear() !== new Date().getFullYear()
        ? diffYear
        : sameYear;

    const parts = dateFormat.formatToParts(formattable);
    //logger(parts);
    return parts
      .filter(({ type }) => type !== "literal")
      .map(({ value }) => value)
      .join(" ");
  }

  async function fetchPropertiesPaymentMetrics(
    properties: Properties,
    intervals: Temporal.PlainDate[][]
  ): Promise<any[]> {
    const urls: URL[] = [];
    for (const k of Object.keys(properties.items)) {
      for (const [min, max] of intervals) {
        const url = new URL(
          `${base()}/payments/collected/metrics?payments=net&metric=total&viewpoint=${viewpoint()}&client=${client}&${authHeader}`
        );
        url.searchParams.append(
          "interval",
          `${min.toString()}/${max.toString()}`
        );
        url.searchParams.append("scope", k);
        urls.push(url);
      }
    }

    logger(urls);

    var results = all(
      urls.map((url) => () => fetch(url).then(responseJson)),
      { concurrency: 8 }
    ).then((results) => results.flatMap((result) => result.metrics.items));

    results.then(console.log);

    return results;
  }
</script>

<script lang="ts">
  import PropertyDataItem from "$components/property/PropertyDataItem.svelte";
  import { authHeader, base, responseJson, viewpoint } from "$utils/api";
  import { client } from "$utils/auth";
  import { comparer } from "$utils/sort";
  import { parseISO } from "date-fns";
  import groupBy from "lodash-es/groupBy";
  import range from "lodash-es/range";

  import all from "p-all";
  import { currency } from "$utils/format";

  export let properties: Properties;

  $: sorted = Object.values(properties?.items ?? []).sort(comparer("name"));

  $: intervals = range(0, 13).map((offset) => [
    Temporal.Now.plainDateISO()
      .with({
        day: 1,
      })
      .subtract({
        months: offset,
      })
      .toPlainDateTime("00:00"),
    Temporal.Now.plainDateISO()
      .with({
        day: 1,
      })
      .subtract({
        months: offset - 1,
      })
      .toPlainDateTime("00:00"),
  ]);

  $: logger("intervals=", intervals);

  $: metrics =
    properties && fetchPropertiesPaymentMetrics(properties, intervals);

  function isSameInterval(
    a: Temporal.PlainDateTime[],
    b: Temporal.PlainDateTime[]
  ) {
    logger(
      "is same=",
      ...a.map((v) => v.toString()),
      ...b.map((v) => v.toString())
    );
    return a[0].equals(b[0]) && a[1].equals(b[1]);
  }
</script>

<section>
  <!-- <header>
    <h1>Payments</h1>
  </header> -->
  <figure>
    <figcaption />
    <dl>
      <!-- <dt>Month</dt>
      {#each sorted as property}
        <dd>
          
        </dd>
      {/each} -->
      <dt>Months</dt>
      {#each intervals as [min, max]}
        <dd>
          <time datetime="{min.toString()}/{max.toString()}"
            ><abbr>{format(min)}</abbr></time
          >
        </dd>
      {/each}
      {#if metrics}
        {#await metrics then metrics}
          <!-- <dt>
              <time datetime="{min.toString()}/{max.toString()}"
                ><abbr>{format(min)}</abbr></time
              >
            </dt> -->
          {#each sorted as property}
            <dt><PropertyDataItem {property} /></dt>
            {#each intervals as [min, max]}
              {@const cents = Object.values(
                metrics.find(
                  (item) =>
                    item.property === property.id &&
                    isSameInterval(
                      [min, max],
                      item.interval
                        .split("/")
                        .map((v) => Temporal.PlainDateTime.from(v))
                    )
                ).values
              )[0]}
              <dd>
                <data class="cents" value={cents.toString()}
                  >{currency(cents / 100) || ""}</data
                >
              </dd>
            {/each}
          {/each}
        {/await}
      {/if}
    </dl>
    <!-- <table>
      <thead>
        <tr>
          <th scope="col">Month</th>
          {#each sorted as property}
            <th scope="col">
              <PropertyDataItem {property} />
            </th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#if metrics}
          {#await metrics then metrics}
            {#each intervals as [min, max]}
              <tr>
                <th scope="row"
                  ><time datetime="{min.toString()}/{max.toString()}"
                    ><abbr>{format(min)}</abbr></time
                  ></th
                >

                {#each sorted as property}
                  {@const cents = Object.values(
                    metrics.find(
                      (item) =>
                        item.property === property.id &&
                        isSameInterval(
                          [min, max],
                          item.interval
                            .split("/")
                            .map((v) => Temporal.PlainDateTime.from(v))
                        )
                    ).values
                  )[0]}
                  <td
                    ><data class="cents" value={cents.toString()}
                      >{currency(cents / 100) || ""}</data
                    ></td
                  >
                {/each}
              </tr>
            {/each}
          {/await}
        {/if}
      </tbody>
    </table> -->
  </figure>
</section>
