<script context="module">
  import { scaleTime, scaleLinear } from "d3-scale";
  import { addDays, format, isWithinInterval } from "date-fns";
  import range from "lodash-es/range";
  import { stringToDateInterval } from "./item/ItemsList.svelte";
  import { time } from "$utils/timestores";
  import { toZonedTime } from "date-fns-tz";
  import SvgTimeNow from "./svg/SVGTimeNow.svelte";
  const nowutc = time({ minutes: 30 });
  const currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 0,
  }).format;
  const calendar = {
    P1D: "day",
    P1W: "week",
    P1M: "month",
    P1Y: "year",
  };
</script>

<script>
  const scale = 80;

  export let item;

  $: nowlocal = toZonedTime($nowutc, item && item.timezone);

  $: interval =
    item && item.interval && stringToDateInterval(item.interval, item.timezone);

  //$: logger("item=", item);

  $: tickFormat = "MMM d";

  $: intervals = Object.entries(item?.values || {}).map(
    ([interval, count]) => ({
      interval,
      count,
      per: item.datetimes,
      ...stringToDateInterval(interval, item.timezone),
    })
  );

  $: average =
    item &&
    Math.round(
      Object.values(item?.values || {}).reduce((sum, i) => sum + i, 0)
    ) / Object.values(item?.values || {}).length;
  $: max = Math.max(average * 1.5, ...Object.values(item?.values || {}));

  $: timescale = scaleTime()
    .domain([interval.start, addDays(interval.start, 31)])
    .range([0, 100]);
  $: ticks = range(0, 31, 8).map((i) => addDays(interval.start, i));
  $: numscale = scaleLinear()
    .domain([max, 0])
    .range([0, scale - 4]);
</script>

<article class="{item.type} details">
  <figure>
    <svg width="100%" height={scale + 20}>
      <svg class="values" width="100%" height={scale}>
        {#each intervals as { start, end, per, count }}
          {#if count > 0}
            <rect
              title={count}
              rx="3"
              ry="3"
              class="{item.type} {item.metric} {calendar[per]} {per}"
              x="{timescale(start)}%"
              y={numscale(count)}
              height="200%"
              width="{timescale(end) - timescale(start)}%"
            />
          {/if}
        {/each}
      </svg>
      <g class="legend">
        <!-- <line class="date min" x1="0%" x2="0%" y1="0%" y2="100%" /> -->
        {#each ticks as tick}
          <line
            class="date"
            class:min={0 == timescale(tick)}
            x1="{timescale(tick)}%"
            x2="{timescale(tick)}%"
            y1="0%"
            y2="100%"
          />
          <text class="date min" x="{timescale(tick)}%" y="100%"
            >{format(tick, tickFormat)}</text
          >
        {/each}
        <line class="date max" x1="100%" x2="100%" y1="0%" y2="100%" />
      </g>
      <svg class="context" width="100%" height={scale}>
        {#if isWithinInterval(nowlocal, interval)}
          <SvgTimeNow x="{timescale(nowlocal)}%" />
        {/if}

        {#if average}
          <line
            stroke-width="2"
            class="average"
            x1="0"
            x2="100%"
            y1={numscale(average)}
            y2={numscale(average)}
          />
          <text
            class="average"
            x="0%"
            y={numscale(average)}
            y2={numscale(average)}
            >Average {currency(average / 100)} per day</text
          >
        {/if}
      </svg>
    </svg>
  </figure>
</article>
