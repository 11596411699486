<svelte:options runes />

<script lang="ts">
  import Loading from "$components/Loading.svelte";
  import type { Snippet } from "svelte";
  import { points as result } from ".";
  import EntryCapabilityItem from "$components/EntryCapabilityItem.svelte";

  const { property = null, title = null } = $props<{
    property: Property | null;
    title?: string;
  }>();
  //const { scope, result } = scoped();

  // $effect(() => {
  //   $scope = property?.id;
  //   logger("$result=", $result);
  // });
</script>

{#snippet Items(items: null | any[], renderer: Snippet<[any]>)}
  {#if null == items}
    <Loading />
  {:else if items.length}
    <ol class="info">
      {#each items as item}
        <li>{@render renderer(item)}</li>
      {/each}
    </ol>
  {:else}
    <aside class="empty history">There are no access points</aside>
  {/if}
{/snippet}

{#snippet Item(item: any)}
  <EntryCapabilityItem {item} />
{/snippet}

<section>
  <header><h1>{title || "Access Points"}</h1></header>
  {@render Items($result && Object.values($result?.items), Item)}
</section>
