<script>
  import Record from "$components/record/RecordItem.svelte";
  import { record as isRecord } from "$utils/filter";
  import identity from "lodash-es/identity";
  import { title as proper } from "$utils/text";
  import { format, toZonedTime } from "date-fns-tz";
  import FeesItem from "../FeesItem.svelte";
  import ItemImages from "$components/item/ItemFiles.svelte";
  import ItemExpandContent from "$components/item/ItemExpandContent.svelte";
  import ViolationNavItem from "./ViolationNavItem.svelte";
  import Time from "$components/Time.svelte";
  export let item = null;
  export let removable = true;

  //$: logger("violation=", item);
</script>

{#if item}
  <article
    class="{item.type} details"
    data-id={item.id}
    data-record="violations/{item?.id}"
  >
    <header>
      <h1>
        <data class={item.type} value={item.id}>
          {item.title}
          <!-- {item.issued.title} -->
        </data>
      </h1>
    </header>

    <!-- <time datetime={item.issued.utc}
        >{format(
          toZonedTime(item.issued.utc, item.timezone),
          "h:mm a MMM d yyyy zzz",
          { timeZone: item.timezone }
        )}</time
      >
      {#each [item.issued.by, item.issued.user]
        .filter((item) => item && item.id)
        .slice(0, 1) as user}
        <a href="mailto:{user.email}"
          ><data class="user {user.type}" value={user.id}
            ><dfn>by</dfn> {user.name}</data
          ></a
        >
      {/each} -->
    <!-- <data
        class="position"
        value={(item.geometry?.coordinates || []).join(",")}
        >{item.geometry ? "Located" : "Unlocated"}</data
      >
      {#each Object.values(item.fees?.items || {}) as fee}
        <a href={item.url} target="_blank"
          ><data class={fee.type} value={fee.id}
            >{fee.payments.full ? "Paid" : "Unpaid"}</data
          ></a
        >
      {:else}
        <data class="fee" value="">Free</data>
      {/each}
    </h1> -->
    <dl>
      <dt>For</dt>
      <dd>
        <ul class="subjects">
          {#each [item.vehicle, item.tenant, item.space].filter((item) => item && isRecord(item)) as item}
            <li>
              <Record {item} />
            </li>
          {/each}
        </ul>
      </dd>
      <!-- {#each [item.vehicle, item.tenant, item.space].filter((item) => item && isRecord(item)) as item} -->
      <!-- <dt>
          {item.title || proper(item.format) || proper(item.type)}
        </dt> -->
      <!-- <dd><Record {item} /></dd>
      {/each} -->

      <dt>Recorded</dt>
      <dd>
        <time datetime={item.issued.datetime}
          ><abbr
            >{format(
              toZonedTime(item.issued.datetime, item.timezone),
              "h:mm a EEE MMM d yyyy zzz",
              {
                timeZone: item.timezone,
              }
            )}</abbr
          >
        </time>
      </dd>
      <dd>
        {#each [item.issued.by, item.issued.user]
          .filter((item) => item && item.id)
          .slice(0, 1) as user}
          <a href="mailto:{user.email}"
            ><data class="user {user.type}" value={user.id}
              ><dfn>by</dfn> {user.name}</data
            ></a
          >
        {/each}
      </dd>

      <!-- </dl>
    <ul class="subjects">
      {#each [item.vehicle, item.tenant, item.space].filter((item) => item && isRecord(item)) as item}
        <li>
          <Record {item} />
        </li>
      {/each}

      {#each [item.notice].filter(isRecord) as notice}
        <li>
          <a href={item.url} target="_blank">
            <data
              class={["id", notice.type, notice._type, notice.format]
                .filter(identity)
                .join(" ")}
              value={notice.id || notice.key}>{notice.display}</data
            >
          </a>
        </li>
      {/each}
    </ul>
    <dl> -->
      <dt>Reason</dt>
      <dd>
        {item.issued.reason}
      </dd>
      {#each Object.values(item.fees?.items || {}) as fee}
        <dt>{fee.name}</dt>
        <dd>
          <data class="paid" value={fee.payments.full.toString()}
            >{!fee.payments.full ? "Unpaid " : "Paid"}
            {fee.total.display}</data
          >
        </dd>
        {#if fee.payments.full}
          <dd>
            <Time
              datetime={Object.values(fee.payments.items)[0].finalized}
              timezone={item.timezone}
            />
          </dd>
        {/if}
      {/each}
      {#each [item.notice].filter(isRecord) as notice}
        <dt>
          {"Warning" || item.title || proper(item.format) || proper(item.type)}
        </dt>
        <dd>
          <a href={item.url} target="_blank">
            <data
              class={["id", notice.type, notice._type, notice.format]
                .filter(identity)
                .join(" ")}
              value={notice.id || notice.key}>{notice.display}</data
            >
          </a>
        </dd>
      {/each}
      <dt>Location</dt>
      <dd>
        <data
          class="position"
          value={(item.geometry?.coordinates || []).join(",")}
          >{item.geometry ? "Recorded" : "No data"}</data
        >
      </dd>
    </dl>
    <!-- <ul class="notes">
      {#each [item.issued.reason].filter(identity) as text}
        <li>
          <blockquote class="note">
            <p>{text}</p>
            <cite>
              {#each [item.issued.by, item.issued.user]
                .filter((item) => item && item.id)
                .slice(0, 1) as user}
                <a href="mailto:{user.email}"
                  ><data class="user {user.type}" value={user.id}
                    ><dfn>by</dfn> {user.name}</data
                  ></a
                >
              {/each}
            </cite>
          </blockquote>
        </li>
      {/each}
    </ul> -->

    <ItemImages {item} create={true} />
    <!-- <ItemNotes {item} /> -->
    <!-- <ul class="attributes">
      {#each Object.values(item.fees?.items || {}) as fee}
        <a href={item.url} target="_blank"
          ><data class="paid" value={fee.payments.full.toString()}
            >{fee.payments.full ? "Paid" : "Unpaid"}
            {fee.total.display}
            {fee.name}</data
          ></a
        >
      {/each}
    </ul> -->
    <!-- <dl>
      
    </dl> -->

    <ViolationNavItem {item} {removable} />
    <ItemExpandContent {item}>
      <FeesItem {item} />
    </ItemExpandContent>
  </article>
{/if}
