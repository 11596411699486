<script lang="ts" context="module">
  const carto = param("carto");
  //const level = param("level", true);
  const nomedia = derived(param("nomedia"), ($value) => "true" === $value);
  // const nospace = derived(
  //   param("nospace", undefined, undefined, true),
  //   ($value) => "true" === $value
  // );
  const noempty = derived(
    param("noempty", undefined, undefined, true),
    ($value) => "true" === $value
  );
  const upcoming = derived(
    param("upcoming", undefined, undefined, true),
    ($value) => "true" === $value
  );
  const novehicle = derived(param("novehicle"), ($value) => "true" === $value);
  const pending = derived(param("pending"), ($value) => "true" === $value);
  const today = derived<Readable<Date>, string>(time(1000 * 60 * 5), ($now) =>
    format($now, "yyyy-MM-dd")
  );
  const valid = derived<[Readable<string>, typeof today], string>(
    [param("valid"), today],
    ([$valid, $today]) => $valid || $today
  );
</script>

<script lang="ts">
  import {
    spaceId,
    spacesStatus as spaces,
    tenantId,
    units,
    permits,
    mediaId,
    vehicleId,
    policies,
    pricingFor,
    unitId,
  } from "$utils/propertystores";
  import { level } from "$components/space";
  import PropertyMap from "./PropertyMap.svelte";
  import MapFeatureHighlight from "./map/MapFeatureHighlight.svelte";
  import type { MapGeoJSONFeature } from "./map";
  import MapPopup from "./map/MapPopup.svelte";
  import MapFeatureState from "./map/MapFeatureState.svelte";
  import { derived, type Readable } from "svelte/store";
  import { hover, localhost } from "$utils/behaviorstores";
  import { addDays, format, parseISO } from "date-fns";
  import { query } from "$utils/router";
  import { param } from "$utils/params";
  import MapFeatureHover from "./map/MapFeatureHover.svelte";
  import MapFeatureClick from "./map/MapFeatureClick.svelte";
  import PropertyLevelList from "./PropertyLevelList.svelte";
  import SpaceDetails from "$sections/SpaceDetailsSection.svelte";
  import TenantDetails from "$sections/TenantDetailsSection.svelte";
  import {
    tenantStatus,
    spaceStatus,
    vehicleStatus,
    mediaStatus,
  } from "$utils/recordstores";
  import PropertyMapHighlightList from "./PropertyMapHighlightList.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import MediaDetails from "$sections/MediaDetailsSection.svelte";
  import VehicleDetails from "$sections/VehicleDetailsSection.svelte";
  import { comparer } from "$utils/sort";
  import PropertyMapLocalServices from "./PropertyMapLocalServices.svelte";
  import Warning from "./Warning.svelte";
  import Help from "./Help.svelte";
  import { time } from "$utils/timestores";

  export let property: Property;

  let levels: Record<string, any>;
  //let selected: Record<string, boolean>;
  let hovered: MapGeoJSONFeature;
  // const today = format(new Date(), "yyyy-MM-dd");
  // const valid = param("valid", true, today);

  const selected: Readable<string> = derived(
    [tenantId, spaceId, param("selected")],
    (ids) => ids.find((i) => !!i)
  ) as Readable<string>;
  let highlight: (MapGeoJSONFeature) => boolean;
  let hoveredItem: {
    type: string;
    id: string;
    permitted?: any;
  };
  //let spaceState: Record<string, any>;

  const records = {
    media: true,
    vehicle: true,
    unit: true,
    tenant: true,
    space: true,
  };

  const clear = {
    ...Object.keys(records).reduce((result, k) => {
      result[k] = null;
      return result;
    }, {}),
    selected: null,
  };

  $: hoveredItem =
    $spaces?.[hovered?.properties?.["ref:boss:subject"]] ??
    (($units as any)?.items ?? $units)?.[
      hovered?.properties?.["ref:boss:subject"]
    ];

  // $: hoveredSpace = $spaces?.[hovered?.properties?.["ref:boss:subject"]];
  // $: hoveredUnit = (($units as any)?.items ?? $units)?.[
  //   hovered?.properties?.["ref:boss:subject"]
  //];
  // $: selectedSpaces = Object.entries(($selected && { [$selected]: true }) || {})
  //   .filter(([k, v]) => !!v)
  //   .map(([k, v]) => $spaces?.[k]);

  $: permitSubjectCrossref =
    $permits &&
    (Object.values($permits?.items ?? {}).reduce((map, permit) => {
      if ("parking" !== permit.amenity) return map;
      if (permit.valid.expired) return map; // only past
      for (const s1 of Object.values(permit.subjects)) {
        if (!records[s1.type]) continue;
        map[s1.id] ??= {};
        // this is a tenant, map it's subject too
        if ((s1 as Tenant).subject) map[(s1 as Tenant).subject] ??= {};
        for (const s2 of Object.values(permit.subjects)) {
          if (!records[s2.type]) continue;
          // outer is a tenant+subject and this is a different record
          // map it to the subject
          if ((s1 as Tenant).subject && s2.id !== s1.id)
            map[(s1 as Tenant).subject][s2.id] = s2;
          if (s2.id !== s1.id) map[s1.id][s2.id] = s2;
        }
      }
      return map;
    }, {}) as Record<string, Record<string, { id: string; type: string }>>);

  // $: logger(
  //   "hovered=",
  //   hoveredItem,
  //   permitSubjectCrossref[hoveredItem?.id]
  // );

  $: recordPolicies = Object.values($policies ?? []).reduce(
    (map: Record<string, Record<string, Policy>>, item) => {
      //if (item.amenity !== "parking") return map;
      // .concat(
      //   Object.values(item.units?.items ?? {})
      // )
      for (const record of Object.values(item.spaces?.items ?? {})) {
        if (!map[record.id]) map[record.id] = {};
        map[record.id][item.policy] = item;
      }

      return map;
    },
    {}
  );

  //$parking == "occupancy"
  // ? {
  //     "*": { permit: null, selectable: false, selected: false },
  //   }
  // : $parking == "prices"
  // ? Object.values($spaces ?? {}).reduce(
  //     (result, item, i) => {
  //       //if (item.permitted) {
  //       //if (0 === i) delete result["*"];
  //       if (item.prices)
  //         result[item.id] = {
  //           permit: null,
  //           selectable: true,
  //           selected: $selected == item.id,
  //         };
  //       //}
  //       return result;
  //     },
  //     {
  //       "*": { permit: null, selectable: false, selected: false },
  //     }
  //   )
  // :

  function onlevelchange(e: CustomEvent<string>) {
    logger("level change=", e.detail);
    query("level", e.detail);
  }

  $: statemap = ((
    $crossref,
    $spaces,
    $selected,
    $nomedia,
    $novehicle,
    $noempty,
    $upcoming,
    $pending,
    $recordPolicies,
    $policies
  ) => {
    //logger("crossref=", $crossref, $spaces);

    if (!$crossref || !$spaces) return null;

    const statusmap = Object.entries($crossref ?? {}).reduce(
      (map, [key, valuemap]) => {
        //logger("map=", map, key, map[key]);
        if (!map[key]) return map;

        if (!map[key]?.permitstatus) return map;

        // logger(
        //   "mapitem=",
        //   key,
        //   map[key],
        //   valuemap,
        //   $nomedia &&
        //     !Object.values(valuemap).find(({ type }) => "media" === type),
        //   $novehicle &&
        //     !Object.values(valuemap).find(({ type }) => "vehicle" === type)
        // );

        // nomedia or vehicle on space
        if (
          ($nomedia &&
            !Object.values(valuemap).find(({ type }) => "media" === type)) ||
          ($novehicle &&
            !Object.values(valuemap).find(({ type }) => "vehicle" === type))
        )
          (map[key] ??= {})["fill-color-hex"] = "000000"; // "FFE2B3"; // yellow

        // map[key] = {
        //   "fill-color-hex":

        //       ? "FFE2B3"
        //       : $upcoming && $spaces?.[key]?.permitted?.unpermitted
        //       ? "E7D9FF"
        //       : $noempty
        //       ? "FBD4D0"
        //       : true !== $spaces?.[key]?.permitted?.permitted
        //       ? "A9DBBE"
        //       : null,
        //   // status:
        //   //   ($nomedia &&
        //   //     !Object.values(valuemap).find(({ type }) => "media" === type)) ||
        //   //   ($novehicle &&
        //   //     !Object.values(valuemap).find(({ type }) => "vehicle" === type))
        //   //     ? "alert"
        //   //     : $noempty
        //   //     ? "error"
        //   //     : null,
        // };
        return map;
      },
      Object.values($spaces ?? {}).reduce(
        (map, item, i) => {
          //if (item.permitted) {
          //if (0 === i) delete result["*"];
          //logger("space item =", item);
          // result[item.id] = {
          //   status:
          //     // $parking !== "permits"
          //     //   ? null
          //     //   :
          //     //$upcoming && item.permitted?.unpermitted ? "highlight" :
          //     item.permitted?.permitted ? undefined : "ok",
          //   selectable: true,
          //   selected: $selected == item.id,
          // };
          //}

          // handle permitted state

          const key = item.id;

          const itemstate = (map[key] ??= {
            selectable: true, // this is the fallback
            selected: false,
          });

          // logger(
          //   "space policies = ",
          //   key,
          //   Object.values($policies?.[key] ?? {})
          // );

          itemstate.permitstatus =
            Object.values($recordPolicies?.[key] ?? {}).some(
              (policy) => policy.permit.continuous
            ) ||
            $policies?.some(
              (policy) =>
                policy.permit.continuous &&
                !!policy.space?.request &&
                !policy.spaces?.items
            ); // TODO remove this final check once legacy policies are gone
          //  &&
          // !Object.values(recordPolicies?.[key] ?? {}).some(
          //   (policy) => policy.permit.temporary
          // );

          if (!itemstate.permitstatus) {
            itemstate["fill-color-hex"] = undefined;
            return map;
          }

          // set the permitted state

          if (
            $pending &&
            Object.entries(item.permitted?.intervals ?? {})?.some(
              ([k, v]) =>
                v &&
                Temporal.Instant.compare(
                  k.split("/")[0],
                  Temporal.Now.instant()
                ) > 0
            )
          ) {
            logger("permitted=", item.permitted);
            itemstate["fill-color-hex"] = "E7D9FF"; // purple"E7E8EA"; // grey
          } else if ($upcoming && item.permitted?.unpermitted)
            itemstate["fill-color-hex"] = "F9C946";
          // yellow; "E7D9FF"; // purple
          else if ($noempty && true === item.permitted?.permitted)
            itemstate["fill-color-hex"] = "FBD4D0"; //red
          else if (true !== item.permitted?.permitted)
            itemstate["fill-color-hex"] = "A9DBBE"; // green
          else itemstate["fill-color-hex"] = undefined;

          return map;
        },
        // Object.entries(($selected && { [$selected]: true }) || {}).reduce(
        //   (state, [k, v]) => {
        //     state[k] = {
        //       selected: v, // seleted
        //     };
        //     return state;
        //   },
        {
          "*": {
            selectable: true, // this is the fallback
            selected: false,
          },
        }
        //)
      ) as Record<string, any>
    );

    // update selected status
    if ($selected) (statusmap[$selected] ??= {}).selected = true;
    for (const [k, v] of Object.entries(statusmap)) {
      v.selected = k === $selected;
    }
    return statusmap;
  })(
    permitSubjectCrossref,
    $spaces,
    $selected,
    $nomedia,
    $novehicle,
    $noempty,
    $upcoming,
    $pending,
    recordPolicies,
    $policies
  ) as Record<string, { status: string }>;

  //$: logger("state=", statemap);

  // $: console.warn("changed space state=", $spaceState);
</script>

{#if property && null != $level}
  <header>
    <a href="/properties/{property.id}/parking">Back</a>
    {#if !property.map}
      <Warning message="No property map has been setup yet" />
      <Help subject="Property Map" body="Interested in adding a property map"
        >I'd like to learn more</Help
      >
    {/if}
    <form>
      <fieldset>
        <!-- <legend>Parking Spaces</legend> -->
        <ul>
          <!-- <li>
            <input
              type="radio"
              name="parking"
              value="permits"
              checked={$parking == "permits"}
              id="parking-permits"
            /><label for="parking-permits">Space Availability</label>
          </li>
          {#if "permits" === $parking} -->
          <li>
            <input
              id="permit-valid"
              type="date"
              name="valid"
              disabled
              min={$today}
              max={format(addDays(parseISO($today), 364), "yyyy-MM-dd")}
              value={$valid}
              on:change={(e) =>
                query(e.currentTarget.name, e.currentTarget.value)}
            />
            <label for="permit-valid"
              ><data class="status" value="ok">No Permits</data>
              <data value={$valid}>
                <!-- {$valid ? format(parseISO($valid), "MMM d") : "today"} -->
              </data></label
            >
          </li>
          <li>
            <input
              id="permit-noempty"
              type="checkbox"
              name="noempty"
              value="true"
              class="error"
              checked={$noempty}
              on:change={({ currentTarget: target }) =>
                target instanceof HTMLInputElement &&
                query(target.name, target.checked ? target.value : "false")}
            /><label for="permit-noempty"
              ><data class="status" value="error">Has Permits</data></label
            >
          </li>
          <li>
            <input
              id="permit-expiring"
              type="checkbox"
              name="upcoming"
              value="true"
              class="status"
              checked={$upcoming}
              on:change={({ currentTarget: target }) =>
                target instanceof HTMLInputElement &&
                query(target.name, target.checked ? target.value : "false")}
            /><label for="permit-expiring"
              ><data class="status" value="expiring">Permits Ending</data
              ></label
            >
          </li>

          <li>
            <input
              id="permit-pending"
              type="checkbox"
              name="pending"
              value="true"
              class="pending"
              on:change={({ currentTarget: target }) =>
                target instanceof HTMLInputElement &&
                query(target.name, target.checked ? target.value : null)}
              checked={$pending}
            /><label for="permit-pending"
              ><data class="status" value="pending">Future Permits</data></label
            >
          </li>
          <li>
            <input
              id="permit-nomedia"
              type="checkbox"
              name="nomedia"
              value="true"
              class="missing"
              checked={$nomedia}
              on:change={({ currentTarget: target }) =>
                target instanceof HTMLInputElement &&
                query(target.name, target.checked ? target.value : null)}
            /><label for="permit-nomedia"
              ><data class="status" value="missing">Missing Decal</data></label
            >
          </li>
          <li>
            <input
              id="permit-novehicle"
              type="checkbox"
              name="novehicle"
              value="true"
              class="missing"
              on:change={({ currentTarget: target }) =>
                target instanceof HTMLInputElement &&
                query(target.name, target.checked ? target.value : null)}
              checked={$novehicle}
            /><label for="permit-novehicle"
              ><data class="status" value="missing">Missing Vehicle</data
              ></label
            >
          </li>
          <!-- <li>
            <input
              id="permits-no"
              type="checkbox"
              name="permits"
              value="no"
              disabled
              checked
            />
            <label for="permits-no"
              ><data class="permits" value="no">Available</data></label
            >
          </li> -->
          <!-- {/if}
          <li>
            <input
              type="radio"
              name="parking"
              value="prices"
              checked={$parking == "prices"}
              id="parking-prices"
            /><label for="parking-prices">Prices</label>
          </li> -->
          <!-- <li>
            <input
              type="radio"
              name="parking"
              value="occupancy"
              checked={$parking == "occupancy"}
              id="parking-occupancy"
            /><label for="parking-occupancy">Occupied & Empty</label>
          </li> -->
        </ul>
      </fieldset>

      <fieldset>
        <legend>Levels</legend>
        <PropertyLevelList {levels} level={$level} on:level={onlevelchange} />
      </fieldset>

      <!-- {#if "permits" === $parking}
        <fieldset>
          <input
            id="permit-valid"
            type="date"
            name="valid"
            min={today}
            value={$valid}
            on:change={(e) =>
              query(e.currentTarget.name, e.currentTarget.value)}
          />
          <label for="permit-valid"
            >Parking <data value={$valid}
              >{$valid
                ? format(parseISO($valid), "EEE MMM d yyyy")
                : "today"}</data
            ></label
          >
        </fieldset>
      {/if} -->

      <!-- <MapLevelSelect
        {levels}
        bind:level={$level}
        on:change={(e) => query("level", e.detail)}
      /> -->

      <fieldset>
        <legend>Highlight</legend>
        <PropertyMapHighlightList
          bind:highlight
          policies={$policies}
          spaces={$spaces}
        />
      </fieldset>
      {#if $localhost}
        <fieldset>
          <legend>Developer</legend>
          <ul>
            <PropertyMapLocalServices />
          </ul>
        </fieldset>
      {/if}
    </form>
  </header>
  <PropertyMap
    property={property?.id}
    level={$level}
    on:level={onlevelchange}
    bind:levels
    minzoom={17}
    carto={$carto}
  >
    <slot />

    <!-- highlighters can work on any feature -->
    <MapFeatureHighlight
      filter={[
        "all",
        ["==", ["geometry-type"], "Polygon"],
        ["==", ["get", "amenity"], "parking_space"],
      ]}
      {highlight}
    />
    <!--specifically boss state-->
    <MapFeatureState
      state={(feature) =>
        statemap?.[feature.properties["ref:boss:subject"]] ?? statemap?.["*"]}
      filter={[
        "all",
        ["==", ["geometry-type"], "Polygon"],
        ["has", "ref:boss:subject"],
      ]}
    />

    <!-- warning status state -->
    <!-- <MapFeatureState
      state={(feature) =>
        warnstate[feature.properties["ref:boss:subject"]] ?? warnstate["*"]}
      filter={[
        "all",
        ["==", ["geometry-type"], "Polygon"],
        ["has", "ref:boss:subject"],
      ]}
    /> -->

    <!-- specifically boss hovers -->
    <MapFeatureHover
      filter={[
        "all",
        ["==", ["geometry-type"], "Polygon"],
        ["has", "ref:boss:subject"],
      ]}
      bind:hovered
    >
      {#if $hover && hoveredItem}
        <MapPopup feature={hovered}>
          <h1>
            <RecordItem url={false} item={hoveredItem} />
          </h1>
          <ul>
            {#each Object.values($pricingFor?.[hoveredItem.id]?.items ?? {}) as item}
              <li>
                <data class="price" value={item.id}>{item.description}</data>
              </li>
            {/each}
            {#if Object.values(recordPolicies?.[hoveredItem.id] ?? {}).length > 2}
              <li>
                {Object.values(recordPolicies?.[hoveredItem.id] ?? {}).length} policies
              </li>
            {:else}
              {#each Object.entries(recordPolicies?.[hoveredItem.id] ?? {}) as [id, policy]}
                <li><data class="policy" value={id}>{policy.title}</data></li>
              {/each}
            {/if}
            {#if Object.values(permitSubjectCrossref?.[hoveredItem.id] ?? {}).length > 5}
              <li>
                {Object.values(permitSubjectCrossref?.[hoveredItem.id] ?? {})
                  .length} linked
              </li>
            {:else}
              {#each Object.values(permitSubjectCrossref?.[hoveredItem.id] ?? {}).sort(comparer("type")) as item}
                <li>
                  <RecordItem url={false} {item} />
                </li>
              {/each}
            {/if}
            {#if hoveredItem.permitted?.unpermitted}
              <li>
                <time datetime={hoveredItem.permitted.unpermitted.interval}
                  ><def>Ending</def>
                  <abbr
                    >{format(
                      parseISO(hoveredItem.permitted.unpermitted.datetime),
                      "MMM d, yyyy"
                    )}</abbr
                  ></time
                >
              </li>{/if}
          </ul>
        </MapPopup>
      {/if}
    </MapFeatureHover>
    <!-- selection can only be boss object -->
    <MapFeatureClick
      filter={[
        "all",
        ["==", ["geometry-type"], "Polygon"],
        ["has", "ref:boss:subject"],
      ]}
      on:click={(e) => {
        logger("map click=", e);
        query({
          ...clear,
          [e.detail.properties.type]: e.detail.properties["ref:boss:subject"],
        });
      }}
    />
  </PropertyMap>
  {#if $spaceId}
    <SpaceDetails
      id={$spaceId}
      {property}
      item={$spaceStatus?.spaces.item}
      data={$spaceStatus}
      minimal={true}
    >
      <a
        title="Open Full"
        class="expand"
        href="/properties/{property.id}/space/{$spaceId}"
      />
      <button
        title="Close"
        type="button"
        class="cancel"
        on:click={(e) => query(clear)}>cancel</button
      >
    </SpaceDetails>
  {:else if $tenantId || $unitId}
    <TenantDetails
      id={$tenantId || $unitId}
      {property}
      item={$tenantStatus?.tenants.item}
      data={$tenantStatus}
      url={false}
      minimal={true}
    >
      <a
        title="Open Full"
        class="expand"
        href="/properties/{property.id}/tenant/{$tenantId || $unitId}"
      />
      <button
        title="Close"
        type="button"
        class="cancel"
        on:click={(e) => query(clear)}>cancel</button
      ></TenantDetails
    >
  {:else if $mediaId}
    <MediaDetails
      id={$mediaId}
      {property}
      item={$mediaStatus?.media.item}
      data={$mediaStatus}
      minimal={true}
    >
      <button
        title="Close"
        type="button"
        class="cancel"
        on:click={(e) => query(clear)}>cancel</button
      ></MediaDetails
    >
  {:else if $vehicleId}
    <VehicleDetails
      id={$vehicleId}
      {property}
      item={$vehicleStatus?.vehicles.item}
      data={$vehicleStatus}
      minimal={true}
    >
      <a title="Open Full" class="expand" href="./vehicle/{$vehicleId}" />
      <button
        title="Close"
        type="button"
        class="cancel"
        on:click={(e) => query(clear)}>cancel</button
      ></VehicleDetails
    >
  {/if}
{/if}
