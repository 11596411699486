<script context="module" lang="ts">
  import {
    derived,
    writable,
    type Readable,
    type Writable,
  } from "svelte/store";

  function debounce(fn) {
    let raf;

    return (...args) => {
      if (raf) {
        logger("debounced");
        return;
      }

      raf = requestAnimationFrame(() => {
        fn(...args); // run useful code
        raf = undefined;
      });
    };
  }

  //const mapstyle = writable(null) as Writable<StyleSpecification>;

  //const mapinstance = writable(null) as Writable<VectorGLMap>;

  // const resolvedMapStyle = derived(mapstyle, function($style, set) {

  //   if(typeof $style == "string")

  // });

  function getMapStyleLoaded(
    $map: Readable<VectorGLMap>
  ): Readable<StyleSpecification> {
    return derived($map, function start($map, set) {
      if (!$map) return set(null);

      if ($map.isStyleLoaded()) set($map.getStyle() as StyleSpecification);

      //logger("setting up style derived store", $map);

      function onstyledata(e: MapStyleDataEvent) {
        //logger("styledata=", e);
        //if (e.target.isStyleLoaded())
        set(e.target.getStyle());
      }
      function onstyleload(e: MapStyleDataEvent) {
        //logger("style.load=", e);
        set(e.target.getStyle());
      }

      function onstyledataloading(e: MapStyleDataEvent) {
        set(null);
        //logger("onstyleloading", e);
        // e.target.once("styledata", onstyledata);
        //e.target.once("style.load", onstyleload);
      }

      // catch any scheduled changes
      //$map.once("styledata", onstyledata);
      //$map.once("style.load", onstyleload);

      // catch any changes
      $map.on("styledataloading", onstyledataloading);
      $map.on("styledata", onstyledata);
      //$map.on("style.load", onstyleload);

      return function stop() {
        $map.off("styledataloading", onstyledataloading);
        $map.off("styledata", onstyledata);
        $map.off("style.load", onstyleload);
      };
    });

    // return readable(null, function start(set) {
    //   function onstyledataloading() {
    //     set(null);
    //     $map.once("style.load", function (e: MapDataEvent) {
    //       logger("style.load=", e);
    //       set(e.target.getStyle());
    //     });
    //   }

    //   $map.on("styledataloading", onstyledataloading);

    //   return function stop() {
    //     $map.off("styledataloading", onstyledataloading);
    //   };
    // }) as Readable<StyleSpecification>;

    // return derived(readable($map), ($map, set) => {
    //   if (!$map) return set(null);
    //   set(null);
    //   $map.on("styledataloading", () => {
    //     set(null);
    //     $map.once("style.load", function (e: MapDataEvent) {
    //       logger("style.load=", e);
    //       set(e.target.getStyle());
    //     });
    //   });
    // }) as Readable<StyleSpecification>;
  }

  //mapinstance.subscribe(($value) => logger("map=", $value));

  // const mapstyleloaded = derived(mapinstance, ($map, set) => {
  //   if (!$map) return set(null);
  //   set(null);
  //   $map.on("styledataloading", () => {
  //     set(null);
  //     $map.once("style.load", function (e: MapDataEvent) {
  //       logger("style.load=", e);
  //       set(e.target.getStyle());
  //     });
  //   });

  //   //$map.on("styledata", () => set(true || $map.isStyleLoaded()));
  // }) as Readable<StyleSpecification>;

  //mapstyleloaded.subscribe(($value) => logger("mapstyleloaded=", $value));

  // const loadedMapStyle = mapstyleloaded;

  // const loadedMapStyle = derived(
  //   [mapinstance, mapstyleloaded],
  //   ([$map, $loaded]) => $map && $loaded && $map.getStyle()
  // );

  //loadedMapStyle.subscribe(($value) => logger("loadedMapStyle=", $value));

  // const selectionSource = derived(
  //   [mapinstance, loadedMapStyle],
  //   ([$map, $style]) => $map && $map.getStyle() && $map?.getSource("selection")
  // );
  function nandor(value, fallback) {
    return isNaN(value) ? fallback : value;
  }

  function createMap(init: MapOptions, library?: string) {
    try {
      return new (engines[library] ?? engine).Map(init);
    } catch (e) {
      logger("error creating map", e);
    }
    // const $map = new engine.Map(init);
    // //const $map = new mapbox.Map(init);
    // mapinstance.set($map);

    // return function destroy() {
    //   $map.remove;
    //   mapinstance.set(null);
    // };
  }
</script>

<script lang="ts">
  import { onDestroy, onMount, setContext, tick } from "svelte";
  import { resize } from "svelte-resize-observer-action";
  // import { Feature } from "geojson";
  import { setSvelteContext, engine, engines } from ".";
  import type {
    StyleSpecification,
    VectorGLMap,
    MapOptions,
    MapStyleDataEvent,
    LngLatBoundsLike,
    BBox,
    LngLatLike,
  } from ".";
  export let style: string | StyleSpecification;
  export let styledata: StyleSpecification = null; // this way we can reactive on loaded
  export let MAP: VectorGLMap = null;
  export let interactive: boolean = true;
  export let moveable: boolean = true;
  export let zoomable: boolean = true;
  export let rotatable: boolean = true;
  export let pitchable: boolean = true;
  export let maxbounds: LngLatBoundsLike | undefined | null = null;
  export let fitbounds: LngLatBoundsLike | BBox | undefined | null = null;
  export let minzoom = 0;
  export let maxzoom = 22;
  export let center: LngLatLike | null | undefined = null;
  export let zoom: number | null | undefined = null;
  export let bearing: number | null | undefined = null;
  export let pitch: number | null | undefined = null;
  let library: string = "maplibre";
  let loaded: Writable<boolean> = writable(false);
  let loadedMap: Writable<VectorGLMap> = writable(null);
  let pixelsPerMeter: Writable<number | null> = writable(null);
  let metersPerPixel: Writable<number | null> = writable(null);
  let loadedMapStyle = getMapStyleLoaded(loadedMap);
  let pixelSize: ResizeObserverSize[] = [
    {
      blockSize: 0,
      inlineSize: 0,
    },
  ];

  let classname: string = "";
  export { classname as class, library as engine };

  const styleinput = writable<string | StyleSpecification>(null);
  const styleresolved = derived<
    Readable<string | StyleSpecification | null>,
    StyleSpecification
  >(styleinput, ($style, set) => {
    if (null == $style) {
      set(null);
    } else if (typeof $style == "string") {
      set(null);
      fetch($style)
        .then((res) => (res.ok ? res.json() : null))
        .then((json) => set(json as StyleSpecification))
        .catch((e) => set(null));
    } else {
      set($style);
    }
  });

  // let getSourceFeatures: Function | null | undefined;
  // let setFeatureState: Function | null | undefined;
  // let removeFeatureState: Function | null | undefined;

  //export { getFeatureState, setFeatureState, removeFeatureState };

  $: if (loadedMapStyle && $loadedMapStyle) styledata = $loadedMapStyle;
  // $: if (!maxbounds) maxbounds = (styledata as any)?.metadata?.bounds;

  $: if (maxbounds && MAP) {
    //logger("setting max bounds = ", maxbounds);
    MAP.setMaxBounds(maxbounds as LngLatBoundsLike);
  }

  $: if (fitbounds && MAP) {
    //logger("setting max bounds = ", maxbounds);
    MAP.fitBounds(fitbounds as LngLatBoundsLike, {
      animate: true,
      duration: 450,
    });
  }

  $: if (null != styledata?.pitch && !pitch) changePitch(MAP, styledata.pitch);
  $: if (null != pitch) changePitch(MAP, pitch);
  $: if (null != bearing) changeBearing(MAP, bearing);
  $: if (null != zoom) changeZoom(MAP, zoom);
  $: if (null != center) changeCenter(MAP, center);

  function changePitch($map: VectorGLMap, $pitch: number) {
    //logger("changepitch", $map, $pitch);
    if (null == $map) return;
    if ($pitch === 0 && $map.getPitch() > 0) $map.setPitch(0);
    if ($pitch > 0 && $map.getPitch() === 0) $map.setPitch($pitch);
  }
  function changeBearing($map: VectorGLMap, $bearing: number) {
    //logger("changepitch", $map, $pitch);
    if (null == $map) return;
    if ($bearing === 0 && $map.getBearing() > 0) $map.setPitch(0);
    if ($bearing > 0 && $map.getPitch() !== $bearing) $map.setBearing($bearing);
  }
  function changeZoom($map: VectorGLMap, $zoom: number) {
    //logger("changepitch", $map, $pitch);
    if (null == $map) return;
    if ($zoom > 0 && $map.getZoom() !== $zoom) $map.setZoom($zoom);
  }
  function changeCenter($map: VectorGLMap, $center: LngLatLike) {
    //logger("changecenter", $map, $center);
    if (null == $map) return;
    $map.setCenter($center);
  }

  // $: getFeatureState = $mapinstance?.getFeatureState;
  // $: setFeatureState = $mapinstance?.setFeatureState;
  // $: removeFeatureState = $mapinstance?.removeFeatureState;

  let container: HTMLElement;

  let css = true;

  const updateMeterPixelRatio = debounce(function (e: MapLibreEvent) {
    const map = e.target;
    const bounds = map.getBounds();
    const southEastPoint = bounds.getSouthEast();
    const northEastPoint = bounds.getNorthEast();
    const mapHeightInMeters = southEastPoint.distanceTo(northEastPoint);
    const mapHeightInPixels =
      pixelSize[0]?.blockSize || map.getContainer().clientHeight;

    metersPerPixel.set(mapHeightInMeters / mapHeightInPixels);
    pixelsPerMeter.set(mapHeightInPixels / mapHeightInMeters);
    // mapMeasurement = {
    // 	metersPerPixel: mapHeightInMeters / mapHeightInPixels,
    // 	pixelsPerMeter: mapHeightInPixels / mapHeightInMeters
    // };

    logger(
      e.type,
      "updateMeterPixelRatio=",
      mapHeightInMeters + "m",
      mapHeightInPixels + "px"
      // mapMeasurement.metersPerPixel + 'm/px',
      // mapMeasurement.pixelsPerMeter + 'px/m'
    );
  });

  $: if (!MAP && container && css)
    loadedMap.set(
      (MAP = createMap(
        {
          container: container, // container ID
          attributionControl: false,
          style: "", // style URL
          // center: [-74.5, 40], // starting position [lng, lat]
          // zoom: 9, // starting zoom
          //hash: true,
          interactive,
          dragPan: moveable,
          scrollZoom: zoomable,
          doubleClickZoom: zoomable,
          touchPitch: pitchable,
          touchZoomRotate: zoomable && rotatable,
          maxZoom: maxzoom,
          minZoom: minzoom,
        },
        library
      ))
    );

  // $: if (style && MAP) {
  //   logger("setting style to=", style);
  //   MAP.setStyle(style);
  // }

  $: if (style) styleinput.set(style); // call any time style changes
  $: if (MAP && $styleresolved) {
    logger("setting style to=", $styleresolved);
    MAP.setStyle($styleresolved);
  }

  $: if (MAP) {
    MAP.on("error", (e) => logger("map.error=", e));
    MAP.on("load", (e) => {
      logger("map.load=", e);
      loaded.set(true);
    });
    MAP.once("idle", updateMeterPixelRatio);
    MAP.on("resize", updateMeterPixelRatio);
    MAP.on("zoomend", updateMeterPixelRatio);
    logger("caling update meter pixel ratio");
    updateMeterPixelRatio({
      target: MAP,
    });
    if (pixelSize) onresize(MAP);
  }

  $: if (MAP && pixelSize) onresize();

  $: if (MAP && minzoom) MAP.setMinZoom(minzoom);
  $: if (MAP && maxzoom) MAP.setMaxZoom(maxzoom);

  onDestroy(function () {
    MAP?.remove();
    loadedMap?.set((MAP = null));

    // set stores
    //MAP = null;
  });

  //$: if (MAP) loadedMap.set(MAP);

  //$: if (MAP)
  setSvelteContext({
    map: loadedMap,
    loaded: loaded,
    style: loadedMapStyle,
    styledmap: derived([loadedMapStyle, loadedMap], ([$style, $map], set) => {
      if (!$style || !$map) return set(null);
      tick().then(() => set($map));
    }),
    metersPerPixel,
    pixelsPerMeter,
  });

  // requestanimationframe debounce the resize events
  const onresize = debounce(() => {
    //logger("resizing map");
    MAP?.resize();
  });
</script>

<figure
  use:resize={onresize}
  on:resize={onresize}
  bind:contentBoxSize={pixelSize}
  class="map {classname}"
  bind:this={container}
>
  {#if MAP}
    <figcaption><slot /></figcaption>
  {/if}
  <!-- <slot name="contents" /> -->
</figure>
