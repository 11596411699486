<script lang="ts" context="module">
  function enforcefeature(item): Feature {
    // logger(
    //   "enforcefeature=",
    //   item,
    //   item.present.item.position.lon,
    //   item.present.item.position.lat
    // );
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          item.present.item.position.lon,
          item.present.item.position.lat,
        ],
      },
      id: item.present.item.id,
      properties: {
        type: "enforce",
        vehicle: item.vehicle.display ?? item.vehicle,
      },
    };
  }
  function enforceToGeojson(enforce): FeatureCollection {
    return {
      type: "FeatureCollection",
      features: Object.values(enforce?.items ?? {})
        .filter((item) => item.warn?.required || item.remove || item.immobilize)
        .map((enforce) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              enforce.present.item.position.lon,
              enforce.present.item.position.lat,
            ],
          },
          id: enforce.present.item.id,
          properties: {
            type: "enforce",
            vehicle:
              typeof enforce.vehicle == "string"
                ? autocreate(enforce.vehicle, enforce.scope)
                : enforce.vehicle,
          },
        })),
    };
  }
  const id = param("selected");
</script>

<script lang="ts">
  import MapLevelSelect from "$components/MapLevelSelect.svelte";
  import PropertyMap from "$components/PropertyMap.svelte";
  import MapMarker from "$components/map/MapMarker.svelte";
  import VehiclesPresentHeatmap from "./VehiclesPresentHeatmap.svelte";
  import { type FeatureCollection, type Feature } from "$components/map";
  import { autocreate } from "$utils/vehicle";
  import RecordItem from "$components/record/RecordItem.svelte";
  import { query } from "$utils/router";
  import { param } from "$utils/params";
  export let present: any;
  export let enforce: any;
  export let partitioned: [any[], any[], any[], any[], any[]] | null;
  export let theme = "cool";

  let level: string = "outside";
  let levels: { [key: string]: any } = {};
  let usesatellite = false;
  let selected: any | nullish;

  $: fc = enforceToGeojson(enforce);

  $: logger("fc=", fc);

  $: selected = fc?.features.find((feature: Feature) => feature.id == $id);

  $: logger("selected=", selected);

  $: partitioned = enforce
    ? Object.values(enforce.items).reduce(
        (results, item) => {
          if (item.permitted) results[0].push(item);
          else if (!item.present.active) results[4].push(item);
          else if (
            item.warn?.required &&
            !Object.values(item.warned.items).length
          )
            results[1].push(item);
          else if (
            item.warn?.required &&
            Object.values(item.warned.items).length
          )
            results[2].push(item);
          else if (item.remove || item.immobilize) results[3].push(item);
          else results[4].push(item);
          return results;
        },
        [[], [], [], [], []]
      )
    : null;

  function select(feature: Feature) {
    //logger("select", e);
    query({ selected: feature.id }, { history: false });
    //selected = feature;
  }
</script>

{#if present}
  <PropertyMap
    theme={usesatellite ? "satellite" : theme || "cool"}
    property={present.scope}
    {level}
    on:level={(e) => (level = e.detail)}
    bind:levels
    interactive={true}
    overlay
  >
    <!-- {#each fc.features as feature}
        <MapMarker
          {feature}
          on:click={(e) =>
            route(
              `/properties/${e.detail.properties.scope}/vehicle/${e.detail.properties.vehicle}`
            )}
          ><RecordItem
            item={autocreate(
              feature.properties.vehicle,
              feature.properties.scope?.id ?? feature.properties.scope
            )}
          /></MapMarker
        >
      {/each} -->

    {#if present}
      <VehiclesPresentHeatmap {present} />
    {/if}
    {#if partitioned}
      <!-- <VehiclesEnforceMapPoints features={fc} /> -->
      {#each [...partitioned[1], ...partitioned[2], ...partitioned[3]].map( (enforce) => [enforce, enforcefeature(enforce)] ) as [item, feature]}
        <MapMarker {feature} on:click={({ detail }) => select(detail)}>
          <data
            class="enforce"
            class:selected={selected && feature.id == selected.id}
            value={item.immobilize
              ? "immobilize"
              : item.remove
                ? "remove"
                : Object.values(item.warned.items).length
                  ? "rewarn"
                  : "warn"}
          >
            <RecordItem
              url={true}
              item={typeof item.vehicle == "string"
                ? autocreate(item.vehicle, item.scope)
                : item.vehicle}
            />
          </data>
        </MapMarker>
      {/each}
    {/if}
    <form>
      <fieldset>
        <input
          type="checkbox"
          name="style"
          value="satellite"
          on:change={({ currentTarget: target }) =>
            (usesatellite = target.checked)}
        />
        <MapLevelSelect {levels} bind:level />
      </fieldset>
    </form>
    <slot />
  </PropertyMap>
  <!-- {#if selected}
    <aside>
      <VehicleDetails
        property={enforce.scope}
        vehicle={selected.properties.vehicle}
        minimal
        on:close={() => query({ selected: null }, { history: false })}
        on:open={(e) =>
          route(`/property/${enforce.scope}/vehicle/${e.detail.id}`)}
      />
    </aside>
  {:else}
    <slot />
  {/if} -->
{/if}
