<script>
  import {
    addDays,
    isEqual,
    format,
    differenceInDays,
    addMonths,
  } from "date-fns";
  import { query } from "$utils/router";

  import { stringToDateInterval } from "./item/ItemsList.svelte";
  import Time from "./Time.svelte";

  export let interval;
  export let timezone;
  export let month = false;
  export let past = null;
  export let exact = false;
  export let indefinite = "";

  // function stringToDateInterval(input, timezone) {
  //   if (!input) return input;
  //   return input
  //     .split("/")
  //     .map((str) => str && toZonedTime(str, timezone))
  //     .reduce(reduceToInterval, {
  //       start: toZonedTime(new Date(), timezone),
  //       end: toZonedTime(new Date(), timezone),
  //     });
  // }

  $: parsed = interval && stringToDateInterval(interval, timezone);
  $: parts = interval?.split("/") ?? [];

  $: singleDay = parsed && isEqual(addDays(parsed.start, 1), parsed.end);
  $: singleMonth =
    month || (parsed && isEqual(addMonths(parsed.start, 1), parsed.end));
  $: isDifferentYears =
    parsed &&
    differenceInDays(parsed.end, parsed.start) > 180 &&
    parsed.start.getYear() < parsed.end.getYear();
  //$: logger(parsed && differenceInDays(parsed.end, parsed.start));
</script>

<time datetime={interval || ""}>
  {#if interval}
    {#if exact}
      <Time datetime={parts[0]} {timezone} /> - <Time
        datetime={parts[1]}
        {timezone}
      />
    {:else if singleMonth}
      <abbr>{format(parsed.start, "MMM yyyy")}</abbr>
    {:else}
      <time datetime={parts[0]}
        ><abbr
          >{format(
            parsed.start,
            isDifferentYears ? "MMM d yyyy" : "MMM d"
          )}</abbr
        ></time
      >
      {#if !singleDay}
        - <time datetime={parts[1]}
          ><abbr
            >{parts[1] || !indefinite
              ? format(parsed.end, isDifferentYears ? "MMM d yyyy" : "MMM d")
              : indefinite}</abbr
          ></time
        >
      {/if}
    {/if}
    {#if null != past}
      <form>
        <select
          name="past"
          on:change={(e) => query(e.target.name, e.target.value)}
        >
          <option value="">---</option>
          {#each Object.entries(past) as [iso, label]}
            <option value={iso}>{label}</option>
          {/each}
        </select>
      </form>
    {/if}
  {/if}
</time>
