<script lang="ts">
  import { createEventDispatcher, onDestroy } from "svelte";
  import { type VectorGLMap, type Marker, type LngLatLike } from ".";
  import { getSvelteContext, engine } from ".";

  export let position: LngLatLike;
  export let heading: number;

  let classname: string = "";
  export { classname as class };

  const { map } = getSvelteContext();

  // const events = createEventDispatcher<{
  //   click: typeof feature;
  // }>();

  $: point = position; // ?? position;

  let element: HTMLElement;
  let marker: Marker;

  $: marker =
    element &&
    new engine.Marker({
      className: ["map-user-location-heading", classname]
        .filter(Boolean)
        .join(" "),
      element: element,
      pitchAlignment: "map",
    });
  //$: logger("$marker=", marker, element);

  function init(
    $map: VectorGLMap,
    $marker: Marker,
    $position: LngLatLike,
    $center: Boolean = false
  ) {
    //logger("init popup", $position, $content);
    if (!$map || !$position) {
      $marker?.remove();
      return;
    }
    if (!$position || !$marker) return;

    $marker.setLngLat($position);

    $marker.addTo($map);

    if ($center)
      $map.flyTo({
        center: $position,
      });
  }

  $: init($map, marker, point);

  onDestroy(function () {
    marker?.remove();
  });
</script>

<data bind:this={element} value={heading}></data>
<!-- <aside class="{classname} map-marker" class:selected bind:this={element}>
  <button on:click={(e) => events("click", feature)}>
    <slot />
  </button>
</aside> -->
