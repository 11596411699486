<svelte:options runes={true} />

<script lang="ts" module>
  const inviewOptions = {
    threshold: 0.5,
  };
</script>

<script lang="ts">
  import { comparer, dateAsc, dateDesc } from "$utils/sort";
  import Loading from "$components/Loading.svelte";
  import { inview } from "svelte-inview";
  import type { Snippet } from "svelte";
  import { zoned } from "..";
  import IngressEgressComponent from "./IngressEgress.svelte";
  import { instant } from "$utils/temporal";

  const { property = null, sections = {} } = $props<{
    property: Property | null;
    sections?: Record<string, any>;
  }>();

  const minute = instant({ minutes: 1 });

  const hourly24ago = $derived(
    $minute.subtract({ hours: 24 }).with({ minute: 0 }).toInstant()
  );

  const { scope, result } = zoned();
  const { scope: prevscope, valid: prevvalid, result: prevresult } = zoned();
  const {
    scope: prev24scope,
    valid: prev24valid,
    result: prev24result,
  } = zoned();

  let noegress = $derived(
    $result &&
      Object.values($result.items)
        .filter((item) => !item.present.egress)
        .sort(comparer("present.ingress.sensor.datetime", dateAsc))
  );
  let noegressunpermitted = $derived(
    noegress?.filter(
      (item) => !item.unpermitted.grace.pending && item.unpermitted.status
    )
  );
  let noegressunpermittedpending = $derived(
    noegress?.filter(
      (item) =>
        (item.unpermitted.grace.pending && !item.unpermitted.grace.fulfilled) ||
        (!item.unpermitted.status && item.unpermitted.pending)
    )
  );
  let prevunpermitted = $derived(
    $prevresult && [
      ...Object.values($prevresult.items)
        .filter(
          (item) =>
            item.present.egress &&
            !item.unpermitted.grace.pending &&
            item.unpermitted.status
        )
        .sort(comparer("present.egress.sensor.datetime", dateDesc)),
      ...Object.values($prevresult.items)
        .filter(
          (item) =>
            !item.present.egress &&
            !item.unpermitted.grace.pending &&
            item.unpermitted.status
        )
        .sort(comparer("present.ingress.sensor.datetime", dateDesc)),
    ]
  );
  //   let noegresspermitted = $derived(
  //     noegress?.filter((item) => !item.unpermitted.status)
  //   );
  //   let ingressegress = $derived(
  //     $present &&
  //       Object.values($present.items)
  //         .filter((item) => item.egress)
  //         .sort(comparer("egress.sensor.datetime", dateDesc))
  //   );

  let prev24entry = $derived(
    $prev24result &&
      Object.values($prev24result.items)
        .filter(
          (item) =>
            Temporal.Instant.compare(item.present.ingress.valid, hourly24ago) >=
            0
        )
        .sort(comparer("present.ingress.valid", dateDesc))
  );

  let prev24permitted = $derived(
    prev24entry &&
      prev24entry.filter(
        (item) =>
          item.unpermitted.grace.pending ||
          item.unpermitted.grace.fullfilled ||
          !item.unpermitted.status
      )
    //.sort(comparer("present.ingress.valid", dateDesc))
  );

  let prev24unpermitted = $derived(
    prev24entry &&
      prev24entry.filter(
        (item) => !item.unpermitted.grace.pending && item.unpermitted.status
      )
    //.sort(comparer("present.ingress.valid", dateDesc))
  );
  let prev24exited = $derived(
    prev24unpermitted &&
      prev24unpermitted
        .filter((item) => item.present.egress)
        .sort(comparer("present.egress.valid", dateDesc))
  );
  let prev24remaining = $derived(
    prev24unpermitted &&
      prev24unpermitted.filter((item) => !item.present.egress)
    //.sort(comparer("present.ingress.valid", dateDesc))
  );

  let prev24noegress = $derived(
    $prev24result &&
      Object.values($prev24result.items)
        .filter((item) => !item.present.egress)
        .sort(comparer("present.ingress.sensor.datetime", dateAsc))
  );

  let prev24noegresspending = $derived(
    prev24noegress?.filter(
      (item) =>
        (item.unpermitted.grace.pending && !item.unpermitted.grace.fulfilled) ||
        (!item.unpermitted.status && item.unpermitted.pending)
    )
  );

  $effect(() => {
    // logger("$noegress=", noegress);
    // logger("$presence=", $result);
    logger("$prev24result=", $prev24result, "prev24entry=", prev24entry);
    $scope = property?.id;
    $prev24valid = `${hourly24ago.toString()}/`;
    $prev24scope = property?.id;
    $prevscope = property?.id;

    if ($result) {
      $prevvalid = $result.rules.valid.intervals[0];
      $prevscope = $result.scope;
    }
  });
</script>

{#snippet Items(items: null | any[] | undefined, renderer: Snippet<[any]>)}
  {#if null == items}
    <Loading />
  {:else}
    <ol class="info">
      {#each items as item}
        <li>{@render renderer(item)}</li>
      {/each}
    </ol>
  {/if}
{/snippet}

{#snippet IngressEgress(item: any)}
  <IngressEgressComponent {item} />
{/snippet}

<section>
  <header>
    <h1>Currently Unpermitted</h1>
    <figure class="data">
      <dl class="data">
        <dt>Vehicles</dt>
        <dd>
          <data class="count" value={prev24remaining?.length ?? ""}
            >{prev24remaining?.length ?? "…"}</data
          >
        </dd>
      </dl>
    </figure>
  </header>
  {@render Items(prev24remaining, IngressEgress)}
</section>
<section>
  <header><h1>Monitoring</h1></header>
  <figure class="data">
    <dl class="data">
      <dt>Vehicles</dt>
      <dd>
        <data class="count" value={prev24noegresspending?.length ?? ""}
          >{prev24noegresspending?.length ?? "…"}</data
        >
      </dd>
    </dl>
  </figure>
  {@render Items(prev24noegresspending, IngressEgress)}
</section>
<section>
  <header>
    <h1>Past 24 Hours</h1>
    <figure class="data">
      <dl class="data">
        <dt>Entered</dt>
        <dd>
          <data class="count" value={prev24entry?.length ?? ""}
            >{prev24entry?.length ?? "…"}</data
          >
        </dd>
        <!-- <dt>Exit</dt>
        <dd>
          <data class="count" value={prev24exited?.length ?? ""}
            >{prev24exited?.length ?? "…"}</data
          >
        </dd> -->
        <dt>OK</dt>
        <dd>
          <data class="count" value={prev24permitted?.length ?? ""}
            >{prev24permitted?.length ?? "…"}</data
          >
        </dd>
        <dt>Unpermitted</dt>
        <dd>
          <data class="count" value={prev24unpermitted?.length ?? ""}
            >{prev24unpermitted?.length ?? "…"}</data
          >
        </dd>
        <!-- <dt>Enforce</dt>
        <dd>
          <data class="count" value={prev24remaining?.length ?? ""}
            >{prev24remaining?.length ?? "…"}</data
          >
        </dd> -->
      </dl>
    </figure>
  </header>
  {@render Items(prev24exited, IngressEgress)}
</section>

{#if sections.ingressunpermitted}
  <section
    id="ingressunpermitted"
    bind:this={sections.ingressunpermitted.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("ingressunpermitted")}
  >
    <header>
      <h1>
        {sections.ingressunpermitted.title} <dfn class="preview">Preview</dfn>
      </h1>
    </header>
    <figure class="data">
      <dl class="data">
        <dt>Vehicles</dt>
        <dd>
          <data class="count" value={noegressunpermitted?.length ?? ""}
            >{noegressunpermitted?.length ?? "…"}</data
          >
        </dd>
      </dl>
    </figure>
    {@render Items(noegressunpermitted, IngressEgress)}
  </section>
{/if}
{#if sections.ingressgrace}
  <section
    id="ingressgrace"
    bind:this={sections.ingressgrace.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("ingressgrace")}
  >
    <header>
      <h1>
        {sections.ingressgrace.title} <dfn class="preview">Preview</dfn>
      </h1>
    </header>
    <figure class="data">
      <dl class="data">
        <dt>Vehicles</dt>
        <dd>
          <data class="count" value={noegressunpermittedpending?.length ?? ""}
            >{noegressunpermittedpending?.length ?? "…"}</data
          >
        </dd>
      </dl>
    </figure>
    {@render Items(noegressunpermittedpending, IngressEgress)}
  </section>
{/if}
{#if sections.ingressprev}
  <section
    id="ingressprev"
    bind:this={sections.ingressprev.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("ingressprev")}
  >
    <header>
      <h1>
        {sections.ingressprev.title} <dfn class="preview">Preview</dfn>
      </h1>
    </header>
    <figure class="data">
      <dl class="data">
        <dt>Vehicles</dt>
        <dd>
          <data class="count" value={prevunpermitted?.length ?? ""}
            >{prevunpermitted?.length ?? "…"}</data
          >
        </dd>
      </dl>
    </figure>
    {@render Items(prevunpermitted, IngressEgress)}
  </section>
{/if}
