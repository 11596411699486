import { parseISO } from "date-fns";
import get from "lodash-es/get";

function date(input) {
  if (!input) return input;
  if (input.getTime) return input;
  if (typeof input == "string") return parseISO(input);
  if (typeof input == "number") return new Date(input);
  return null;
}

export const numericAsc = (a, b) =>
  parseInt(a) < parseInt(b) ? -1 : parseInt(a) > parseInt(b) ? 1 : 0;

export const numericDesc = (a, b) =>
  parseInt(a) < parseInt(b) ? 1 : parseInt(a) > parseInt(b) ? -1 : 0;

export const dateAsc = (a, b) => date(a).getTime() - date(b).getTime();

export const dateDesc = (a, b) => null == a && null == b ? 0 : null == a ? 1 : null == b ? -1 : date(b).getTime() - date(a).getTime();

export const textAsc =
  !!self.Intl && !!self.Intl.Collator
    ? new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
        caseFirst: "lower",
      }).compare
    : (a, b) => (a < b ? -1 : a > b ? 1 : 0);

export const textDesc = (a, b) => textAsc(b, a);

export const textPlainAsc =
  !!self.Intl && !!self.Intl.Collator
    ? new Intl.Collator(undefined, {
        numeric: false,
        sensitivity: "base",
        caseFirst: "lower",
      }).compare
    : (a, b) => (a < b ? -1 : a > b ? 1 : 0);

export const comparer = function (property, by = textAsc, reverse = false) {
  return (a, b) =>
    by(
      typeof property == "function" ? property(a) : get(a, property, a),
      typeof property == "function" ? property(b) : get(b, property, b)
    ) * (reverse ? -1 : 1);
};

export function sortBy(list, property, by = textAsc, reverse = false) {
  list.sort(comparer(property, by, reverse));
  return list;
}
