import { authorize, base, viewpoint } from "$utils/api";
import merge from "lodash-es/merge";

export async function fetchAccess(scope: string) {
    // const res = await fetch(
    //   authorize(`${base()}/services?service=${service || ""}&for=${
    //     scope || ""
    //   }&viewpoint=${viewpoint()}`),
    //   {
    //     //method: !!code ? "post" : "get"
    //   }
    // );

    const json = await Promise.all([
        fetch(
            authorize(
                `${base()}/access/authorized?for=${scope || ""}&viewpoint=${viewpoint()}`
            )
        ),
    ])
        .then((values) => Promise.all(values.map((res) => res.json())))
        .then((values) => merge({}, ...values));

    // lol
    for (const v1 of [
        ...Object.values(json.access?.items ?? json.entry?.items ?? {}),
        ...Object.values(json.access?.["for"] ?? json.entry?.["for"] ?? {}).flatMap((v) =>
            Object.values(v.items)
        ),
    ]) {
        const item = json.items[v1] ?? v1;
        for (const [k2, v2] of Object.entries(item)) {
            item[k2] = json.items[v2 as string] ?? json.items[k2] ?? v2;
        }
    }

    //const json = await res.json();

    return json;
}