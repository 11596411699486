import { derived, readable, writable, type Readable, type Writable } from "svelte/store";
import { authorize } from "$utils/api";
import { scanned } from "./stores"

//export const scope = readable<Set<string>>(new Set<string>());
export {
    scanned
}

const eventsource = writable<EventSource | null | undefined>(null);
const eventstate = writable<number>(2);
export const connected = derived<[typeof eventsource, typeof eventstate], boolean>([eventsource, eventstate], ([$source, $state]) => !!$source && $state === 1);
export const connecting = derived<[typeof eventsource, typeof eventstate], boolean>([eventsource, eventstate], ([$source, $state]) => !!$source && $state === 0);

const watching: Writable<Record<string, boolean>> = writable({});
const statestores: Record<string, Writable<any>> = {};

const nullop = readable(null);

function state(scope: string): Writable<any> {
    return statestores[scope] ??= writable<any>();
}

export function watch(scope: string): Readable<any> {

    logger("start watching=", scope);

    //if (!scope || typeof scope !== "string") return readable({});
    if (!scope) return nullop;

    watching.update($watching => {
        $watching[scope] = true;
        return $watching;
    });

    return state(scope);

}
export function unwatch(scope: string | null | undefined) {
    if (!scope) return;
    watching.update($watching => {
        if ($watching[scope]) $watching[scope] = false;
        return $watching;
    });
}

function onerror(e: Event) {
    e.target
    console.error("EventSource failed:", e);
    eventstate.set((e.target as EventSource)?.readyState ?? 2);
}

function onopen(e: Event) {
    logger("EventSource connected", e);
    eventstate.set((e.target as EventSource)?.readyState ?? 2);
}

function onmessage(e: MessageEvent) {
    //logger("message", e);
    const data = JSON.parse(e.data);
    //logger("data=", data);
    if (!data) return;


    const scope = data.scope;
    if (!scope) return;

    const store = state(scope);
    if (!store) return;

    store.update($state => {

        return data; // just be dumb?
        //logger("updating $state=", $state);

        return $state;
    });


}

function close($source: EventSource) {
    if (!$source) return $source;
    $source.close();
    $source.removeEventListener("error", onerror);
    $source.removeEventListener("open", onopen);
    $source.removeEventListener("detection", onmessage);
    $source.removeEventListener("message", onmessage);
    return $source;
}

function open(url: URL) {
    const $source = new EventSource(authorize(url));

    $source.addEventListener("detection", onmessage);
    $source.addEventListener("message", onmessage);
    $source.addEventListener("error", onerror);
    $source.addEventListener("open", onopen);

    return $source;
}

watching.subscribe($watching => {
    logger("$watching=", $watching);
    // close out the existing source
    eventsource.update($source => {
        if (!$source) return $source; // nothing to update
        close($source);
        eventstate.set($source.readyState);
        return null;
    });

    // nothing else to watch
    if (!Object.values($watching).filter(watch => true === watch).length) {
        return;
    }

    const url = new URL("https://events.propertyboss.io/lprd/detect");
    for (const [id, watch] of Object.entries($watching)) {
        if (watch) url.searchParams.append("for", id);
    }

    eventsource.update($source => {
        $source = open(url);
        eventstate.set($source.readyState);
        return $source;
    });

});

