<script lang="ts">
  import { createEventDispatcher, tick } from "svelte";
  import Record from "$components/record/RecordItem.svelte";
  import Select from "../record/SelectVehicle.svelte";

  export let value = null;
  export let name = "vehicle";
  export let label = "Vehicle";
  export let property = null;
  export let required = false;
  export let scannable = false;
  export let readonly = false;
  export let items = [];

  let element: HTMLElement;

  //$: logger("vehicle.value=", value);

  $: id =
    value && (value.id || value.key || value.display || value)?.toString();

  const evented = createEventDispatcher();

  async function change(newvalue: any | null | undefined) {
    value = newvalue;
    await tick();
    if (value)
      element?.scrollIntoView({ behavior: "instant", block: "center" });
    evented("change", {
      name,
      value,
    });
  }
</script>

{#if id}
  <figure class="value record" bind:this={element}>
    {#if label}
      <figcaption>{label}</figcaption>
    {/if}
    <input type="hidden" {name} value={id} />
    <Record item={value} url={false} />
    {#if !readonly}
      <button class="clear" type="button" on:click={(e) => change(null)}
        >change</button
      >
    {/if}
  </figure>
{:else}
  <Select
    {name}
    {label}
    {scannable}
    {required}
    {items}
    {property}
    placeholder={required ? "plate/tag" : "plate/tag"}
    on:vehicle={(e) => change(e.detail)}
  />
{/if}
