<script lang="ts">
  import Fullscreen from "$components/Fullscreen.svelte";
  import MapLevelSelect from "$components/MapLevelSelect.svelte";
  import PropertyMap from "$components/PropertyMap.svelte";
  import Record from "$components/record/RecordItem.svelte";

  export let policy: any = null;
  let level: string;
  let levels: { [key: string]: any } = {};
  let usesatellite = false;
  let theme = "cool";
  let fullscreen = false;
  //$: logger("policy=", policy);
</script>

<section class="policy">
  <header>
    {#if policy}
      <h1>{policy.title}</h1>
    {:else}
      <figure class="loading">Loading</figure>
    {/if}
  </header>
  {#if policy}
    <section>
      <header><h1>Spaces</h1></header>
      <ul>
        {#each Object.values(policy?.spaces?.items ?? {}) as item}
          <li><Record {item} /></li>
        {/each}
      </ul>
      <article class="map">
        <Fullscreen bind:active={fullscreen}>
          <PropertyMap
            property={policy.scope}
            theme={usesatellite ? "satellite" : theme}
            interactive={fullscreen}
            {level}
            on:level={(e) => (level = e.detail)}
            bind:levels
          >
            <!-- <slot /> -->
            <form>
              <fieldset>
                <input
                  type="checkbox"
                  name="style"
                  value="satellite"
                  on:change={({ currentTarget: target }) =>
                    (usesatellite = target.checked)}
                />
                <MapLevelSelect {levels} bind:level />
              </fieldset>
              <fieldset>
                <input
                  type="checkbox"
                  name="fullscreen"
                  value="fullscreen"
                  bind:checked={fullscreen}
                />
              </fieldset>
            </form>
          </PropertyMap>
        </Fullscreen>
      </article>
    </section>
    <section class="preview">
      <header><h1>Preview</h1></header>
    </section>
  {/if}
</section>
