<script lang="ts" context="module">
  const scans = derived<
    [typeof propertyId, typeof valid, Readable<Date>],
    FeatureCollection | null
  >(
    [propertyId, valid, onlineVisibleTime({ minutes: 15 })],
    function ([$propertyId, $valid], set) {
      if (!$propertyId) return set(null);

      fetchDetectionsAndObservations(
        $propertyId,
        `${Temporal.Now.instant().subtract({ hours: 24 }).toString()}/`
      )
        .then((json) => {
          for (const feature of json.geojson.features) {
            if (feature?.properties.file)
              feature.properties.file =
                json.items[feature.properties.file] ?? feature.properties.file;
          }
          return json.geojson;
        })
        .then(set);
    }
  );
  const selected = param("place");
</script>

<script lang="ts">
  import MapMarker from "$components/map/MapMarker.svelte";
  import MapWebGl from "$components/map/MapWebGL.svelte";
  import { fetchDetectionsAndObservations } from "$utils/api";
  import {
    propertyId,
    validFiveCalendarWeeks,
    validPast24H as valid,
    validPastSevenDays,
  } from "$utils/propertystores";
  import { onlineVisibleTime } from "$utils/timestores";
  import { derived, type Readable } from "svelte/store";
  import {
    valid13CalendarWeeks,
    validOneCalendarDay,
  } from "$utils/propertystores";
  import type { Feature, FeatureCollection } from "geojson";
  import FeatureDataItem from "$components/geojson/FeatureDataItem.svelte";
  import { query } from "$utils/router";
  import { thumbnail } from "$utils/filepreview";
  import { param } from "$utils/params";
  import LI from "$components/LI.svelte";
  import { autocreate } from "$utils/vehicle";
  import RecordItem from "$components/record/RecordItem.svelte";
  import Time from "$components/Time.svelte";

  export let property;

  let selectedFeature: Feature;

  $: logger("$scans=", $scans);
</script>

<section class="enforcement">
  {#if $scans}
    <MapWebGl
      class="detections"
      fitbounds={$scans?.bbox}
      style="https://carto.communityboss.app/v1/properties/{$propertyId}/styles/cool.json?level=outside&parking=true&overlay=false"
    >
      <ol>
        {#each $scans.features as feature}
          <MapMarker
            selected={$selected === feature.id}
            {feature}
            on:click={(e) => query({ place: e.detail.id })}
            >{#if $selected === feature.id}
              <RecordItem
                item={autocreate(
                  feature.properties.vehicle,
                  feature.properties.scope?.id ?? feature.properties.scope
                )}
              />
            {:else}
              <FeatureDataItem item={feature}></FeatureDataItem>
            {/if}</MapMarker
          >
          <LI
            class={$selected === feature.id ? "selected" : null}
            inview={$selected === feature.id}
          >
            <Time datetime={feature.properties?.time} />
            <a target="_blank" href={feature.properties.file.url}
              ><img src={thumbnail(feature.properties.file, 200)} /></a
            >
            <h1>
              <RecordItem
                item={autocreate(
                  feature.properties.vehicle,
                  feature.properties.scope?.id ?? feature.properties.scope
                )}
              /><button
                type="button"
                on:click={(e) => query({ place: feature.id })}>map</button
              >
              <data class="confidence" value={feature.properties.confidence}>
                {feature.properties.confidence}
              </data>
              <!-- <data class="region" value={feature.properties.region}>
                {feature.properties.confidence}
              </data> -->
            </h1>
          </LI>
        {/each}
      </ol>
    </MapWebGl>
  {/if}
</section>
