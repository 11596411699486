<script lang="ts">
  import { now } from "$utils/recordstores";
  import ItemsList, { stringToDateInterval } from "../item/ItemsList.svelte";
  import orderBy from "lodash-es/orderBy";
  import range from "lodash-es/range";
  import UserSessionItem from "../UserSessionItem.svelte";
  import {
    areIntervalsOverlapping,
    endOfDay,
    isWithinInterval,
    parseISO,
  } from "date-fns";
  import { comparer, dateDesc, textAsc, textDesc } from "$utils/sort";
  import EnforcementChartItem from "../enforcement/EnforcementChartItem.svelte";
  import EnforcementSummaryMapItem from "../enforcement/EnforcementSummaryMapItem.svelte";
  import Time from "../Time.svelte";
  import EnforcementSummaryItem from "../enforcement/EnforcementSummaryItem.svelte";
  import PermitIssuedDetailsItem from "../permit/PermitIssuedDetailsItem.svelte";
  import { tenants, tenantsByUnit } from "$utils/propertystores";
  import UnitTenantsItem from "../unit/UnitTenantsItem.svelte";
  import TenantValidItem from "../tenant/TenantValidItem.svelte";

  export let property: Property = null;

  export let authorized: any;
  export let sessions: any;
  export let enforcement: any;
  export let permits: Permits;

  //$: logger("enforcement=", enforcement);

  $: last30 = $now &&
    property && [
      Temporal.Now.plainDateISO()
        .add({ days: -29 })
        .toPlainDateTime()
        .toZonedDateTime(property.timezone)
        .toString({ timeZoneName: "never" }),
      Temporal.Now.plainDateISO()
        .add({ days: 1 })
        .toPlainDateTime()
        .subtract({ milliseconds: 1 })
        .toZonedDateTime(property.timezone)
        .toString({ timeZoneName: "never" }),
    ];

  $: last7 = $now &&
    property && [
      Temporal.Now.plainDateISO()
        .add({ days: -6 })
        .toPlainDateTime()
        .toZonedDateTime(property.timezone)
        .toString({ timeZoneName: "never" }),
      Temporal.Now.plainDateISO()
        .add({ days: 1 })
        .toPlainDateTime()
        .subtract({ milliseconds: 1 })
        .toZonedDateTime(property.timezone)
        .toString({ timeZoneName: "never" }),
    ];
  $: last2months = $now &&
    property && [
      Temporal.Now.plainDateISO()
        .toPlainYearMonth()
        .add({ months: -1 })
        .toPlainDate({
          day: 1,
        })
        .toZonedDateTime(property.timezone)
        .toString({ timeZoneName: "never" }),
      Temporal.Now.plainDateISO()
        .toPlainYearMonth()
        .add({ months: 1 })
        .toPlainDate({
          day: 1,
        })
        .toPlainDateTime()
        .subtract({ milliseconds: 1 })
        .toZonedDateTime(property.timezone)
        .toString({ timeZoneName: "never" }),
    ];
  //$: [start, end] = interval?.map(parseISO);
  //$: logger("2mo interval=", last2months);

  $: days =
    $now &&
    range(0, -7).map((offset) =>
      Temporal.Now.plainDateISO()
        .add({ days: offset })
        .toPlainDateTime()
        .toZonedDateTime(property.timezone)
        .toString({ timeZoneName: "never" })
    );

  $: months =
    $now &&
    range(0, -2).map((offset) =>
      Temporal.Now.plainDateISO()
        .add({ months: offset })
        .toPlainYearMonth()
        .toPlainDate({
          day: 1,
        })
        .toZonedDateTime(property.timezone)
        .toString({ timeZoneName: "never" })
    );

  $: propertySessions =
    sessions &&
    Object.values(
      Object.values(sessions?.items || {}).reduce(
        (result, item) => {
          if (!result[item.principal.id]) return result;
          Object.assign(result[item.principal.id], item, {
            valid: last7.join("/"),
            timezone: property.timezone,
          });
          return result;
        },
        Object.values((sessions && authorized?.items) || {}).reduce(
          (result, item) => {
            result[item.principal.id] = {
              type: "sessions",
              principal: item.principal,
              values: [],
              seconds: 0,
              latest: "",
              generated: sessions.generated,
              valid: last7.join("/"),
              roles: item.roles,
              timezone: property.timezone,
            };
            return result;
          },
          {}
        )
      )
    )
      // .filter((value) => {
      //   const [start, end] = value.valid.split("/").map(parseISO);
      //   return (
      //     value.latest &&
      //     isWithinInterval(parseISO(value.latest), {
      //       start,
      //       end,
      //     })
      //   );
      // })
      .sort(comparer((item) => item.latest || "2000-01-01", dateDesc));

  $: violations =
    enforcement &&
    (Object.values(enforcement.items)
      .filter((item) => {
        if ("violation" !== item.type) return false;
        //const [start, end] = interval.map(parseISO);
        return item.issued.datetime >= last7[0];
      })
      .sort(comparer("issued.datetime", dateDesc)) as Violation[]);

  $: adminParkingPermits =
    permits?.items &&
    Object.values(permits.items)
      .filter((item) => item.amenity === "parking" && item.issued.by)
      .sort(comparer("issued.datetime", dateDesc));

  function enforcementByDay($enforcement, $day) {
    logger("enforcementbyday=", $day);
    if (!$enforcement || !$day) return null;
    const start = Temporal.Instant.from($day);
    const end = start.add({ hours: 24 });
    const interval = `${start.toString({
      //timeZoneName: "never",
      smallestUnit: "millisecond",
      timeZone: property.timezone,
    })}/${end.toString({
      smallestUnit: "millisecond",
      timeZone: property.timezone,
      //timeZoneName: "never",
    })}`;
    //logger("by day interval=", interval);
    return {
      ...$enforcement,
      interval,
    };
  }

  $: tenantsLatest =
    $tenants &&
    last7 &&
    (Object.values($tenants)
      .filter((item) => {
        const [start, end] = item.valid.interval.split("/");
        //logger("item=", item, start, end);
        item.valid.start = start;
        return !end && start > last2months[0];
      })
      .sort(comparer("valid.start", textDesc)) as Tenant[]);

  // $: turnedOver =
  //   $tenantsByUnit &&
  //   last7 &&
  //   Object.values($tenantsByUnit)
  //     .filter((meta) => meta.latest >= last30[0])
  //     .sort(comparer("latest", textDesc));
  // .filter((item) => {
  //   const [startutc, endutc] = item.valid.interval
  //     .split("/")
  //     .map((str) => str && parseISO(str));
  //   logger("item=", item, start, end);
  //   return !endutc && startutc > parseISO(interval[0]);
  // });
  //$: logger("units=", turnedOver);
</script>

<section class="permits">
  <header>
    <h1>Parking <a href="./parking">Full Details</a></h1>
  </header>
  {#each days as datetime}
    <h1>
      <Time
        timezone={property?.timezone}
        {datetime}
        class="day"
        weekday="eee"
        date="MMM d"
        time=""
        zone=""
      />
    </h1>
    <ItemsList
      class="info"
      types={{
        permit: PermitIssuedDetailsItem,
      }}
      items={adminParkingPermits?.filter(
        (item) =>
          //item.continuous &&
          // item.amenity === "parking" &&
          // item.issued.by &&
          item.issued.datetime >= datetime &&
          item.issued.datetime <
            Temporal.PlainDateTime.from(datetime)
              .toZonedDateTime(item.timezone)
              .add({ days: 1 })
              .toString({ timeZoneName: "never" })
        // isWithinInterval(parseISO(item.issued.datetime), {
        //   start: parseISO(datetime),
        //   end: endOfDay(parseISO(datetime)),
        // })
      )}
    />
  {/each}
</section>

<section class="enforcement">
  <header>
    <h1>Enforcement <a href="./enforcement">Full Details</a></h1>
  </header>
  <!-- {#if enforcement}
    <EnforcementSummaryItem
      item={{
        ...enforcement,
        interval: interval.join("/"),
      }}
    />
    <EnforcementSummaryMapItem
      item={{
        ...enforcement,
        interval: interval.join("/"),
        geo: {
          ...enforcement.geo,
          features: enforcement.geo.features.filter(
            (f) =>
              !f.properties?.interval ||
              areIntervalsOverlapping(
                { start, end },
                stringToDateInterval(
                  f.properties.interval,
                  enforcement.timezone
                )
              )
          ),
        },
      }}
    />
  {/if} -->
  {#each days as datetime}
    <h1>
      <Time
        timezone={property?.timezone}
        {datetime}
        date="MMM d"
        weekday="eee"
        time=""
        zone=""
        class="day"
      />
    </h1>
    <!-- {#if enforcement}
      <EnforcementSummaryItem item={enforcementByDay(enforcement, datetime)} />
    {/if} -->
    <ItemsList
      class="info"
      items={violations
        ?.filter(
          (item) =>
            item.issued.datetime >= datetime &&
            item.issued.datetime <
              Temporal.PlainDateTime.from(datetime)
                .toZonedDateTime(item.timezone)
                .add({ days: 1 })
                .toString({ timeZoneName: "never" })
          // isWithinInterval(parseISO(item.issued.datetime), {
          //   start: parseISO(datetime),
          //   end: endOfDay(parseISO(datetime)),
          // })
        )
        .sort(comparer("issued.datetime", dateDesc))}
    />
  {/each}
</section>

<section class="sessions">
  <header>
    <h1>Team <a href="./users">Full Details</a></h1>
  </header>
  {#each days as datetime}
    <h1>
      <Time
        timezone={property?.timezone}
        {datetime}
        date="MMM d"
        weekday="eee"
        time=""
        zone=""
        class="day"
      />
    </h1>
    <ItemsList
      class="items"
      items={propertySessions?.filter((item) => {
        return (
          item.latest &&
          item.latest >= datetime &&
          item.latest <
            Temporal.Instant.from(datetime)
              .toZonedDateTimeISO(item.timezone)
              .add({ days: 1 })
              .toString({ timeZoneName: "never" })
        );
      })}
      types={{
        sessions: UserSessionItem,
      }}
    />
  {/each}
  <h1>
    <time class="day" datetime=""><data class="date">Older</data></time>
  </h1>
  <ItemsList
    class="items"
    items={propertySessions?.filter((item) => {
      return !item.latest || item.latest < last7[0];
    })}
    types={{
      sessions: UserSessionItem,
    }}
  />
</section>
{#if property?.units?.enabled}
  <section class="turnover">
    <header>
      <h1>Units <a href="./units">Full Details</a></h1>
    </header>
    {#each months as datetime}
      <h1>
        <Time
          timezone={property?.timezone}
          {datetime}
          date="MMM yyyy"
          weekday=""
          time=""
          zone=""
          class="month"
        />
      </h1>
      <ItemsList
        class="info"
        items={tenantsLatest?.filter((item) => {
          const [start, end] = item.valid.interval.split("/");
          return (
            start >= datetime &&
            start <
              Temporal.PlainYearMonth.from(start)
                .add({
                  months: 1,
                })
                .toPlainDate({ day: 1 })
                .toZonedDateTime(item.timezone)
                .toString({ timeZoneName: "never" })
          );
        })}
        types={{
          tenant: TenantValidItem,
        }}
      />
    {/each}
  </section>
{/if}
