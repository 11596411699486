<script>
  export let item;
  export let interactive = true;
  export let record = null;

  //$: logger("item=", item);
</script>

<aside
  class="{item.type} alert"
  class:donotpermit={item.permittable === false}
  data-record="permittables/{item.id}"
>
  <h1>
    <dfn>{item.permittable === false ? "Banned" : "Exempted"}</dfn>
  </h1>
</aside>
