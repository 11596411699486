<script lang="ts">
  import { onMount } from "svelte";
  import {
    getSvelteContext,
    type AnyLayer,
    type VectorGLMap,
  } from "$utils/mapping";
  import type { FeatureCollection } from "geojson";
  export let item: any = null;
  export let record: any = null;

  const layers = [
    // {
    //   id: "property-outline",
    //   type: "line",
    //   source: "enforcement",
    //   filter: ["all", ["==", ["geometry-type"], "Polygon"], ["has", "landuse"]],
    //   paint: {
    //     "line-color": "red",
    //     "line-width": 2,
    //     //"line-opacity":0.6,
    //     "line-dasharray": [1.5, 0.5],
    //   },
    //   layout: {
    //     //"line-cap":"round",
    //     "line-join": "round",
    //   },
    // },
    // {
    //   id: "enforcement-background",
    //   type: "background",
    //   //source: "enforcement",
    //   //filter: ["all", ["==", ["geometry-type"], "Polygon"], ["has", "landuse"]],
    //   paint: {
    //     "background-color": "#f8f8f8",
    //     "background-opacity": 0.5,
    //   },
    //   layout: {},
    // },
    // {
    //   id: "property-grid-status",
    //   type: "fill",
    //   source: "enforcement",
    //   filter: [
    //     "all",
    //     ["==", ["geometry-type"], "Polygon"],
    //     ["has", "enforcement"],
    //   ],
    //   paint: {
    //     "fill-color": [
    //       "case",
    //       [">", ["get", "violations"], 3],
    //       "#b22e1e",
    //       [">", ["get", "violations"], 1],
    //       "#fc9647",
    //       [">", ["get", "violations"], 0],
    //       "#fad674",
    //       [">", ["get", "checks"], 10],
    //       "#1177d4",
    //       [">", ["get", "checks"], 3],
    //       "#44a5ff",
    //       [">", ["get", "checks"], 0],
    //       "#a1d2ff",
    //       "transparent",
    //     ],
    //     "fill-opacity": [
    //       "case",
    //       [">", ["get", "violations"], 3],
    //       0.75,
    //       [">", ["get", "violations"], 1],
    //       0.5,
    //       [">", ["get", "violations"], 0],
    //       0.25,
    //       [">", ["get", "checks"], 10],
    //       0.75,
    //       [">", ["get", "checks"], 3],
    //       0.5,
    //       [">", ["get", "checks"], 0],
    //       0.25,
    //       0,
    //     ],
    //     //"line-dasharray": [1.5, 0.5],
    //   },
    // },
    // {
    //   id: "property-grid",
    //   type: "line",
    //   source: "enforcement",
    //   filter: [
    //     "all",
    //     ["==", ["geometry-type"], "Polygon"],
    //     ["has", "enforcement"],
    //   ],
    //   paint: {
    //     "line-color": "#888",
    //     "line-width": 0.25,
    //     "line-opacity": 0.1,
    //     //"line-dasharray": [1.5, 0.5],
    //   },
    //   layout: {
    //     //"line-cap":"round",
    //     "line-join": "round",
    //   },
    // },
    {
      id: "enforcement-accessed-heat",
      type: "heatmap",
      source: "enforcement",
      filter: [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["get", "type"], "check"],
      ],
      //maxzoom: 20,
      paint: record
        ? {
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.2,
              "#A1D2FF",
              0.4,
              "#73BCFF",
              0.6,
              "#44A5FF",
              0.8,
              "#158FFF",
              1,
              "#1177D4",
            ],
            "heatmap-weight": 1,
            //"heatmap-opacity":0.5,
            "heatmap-opacity": 1,
            "heatmap-radius": 10,
          }
        : {
            // increase intensity as zoom level increases
            //   "heatmap-intensity": {
            //     stops: [
            //       [11, 1],
            //       [18, 3],
            //     ],
            //   },
            // assign color values be applied to points depending on their density
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.2,
              "#A1D2FF",
              0.4,
              "#73BCFF",
              0.6,
              "#44A5FF",
              0.8,
              "#158FFF",
              1,
              "#1177D4",
            ],
            // purple
            // "heatmap-color": [
            //   "interpolate",
            //   ["linear"],
            //   ["heatmap-density"],
            //   0,
            //   "rgba(0, 0, 255, 0)",
            //   0.2,
            //   "#D9C9F3",
            //   0.4,
            //   "#C6AEEE",
            //   0.6,
            //   "#B393E8",
            //   0.8,
            //   "#A078E2",
            //   1,
            //   "#7F48D8"
            // ],
            // increase radius as zoom increases
            "heatmap-weight": 0.2,
            // "heatmap-intensity":0.5,
            "heatmap-opacity": 1,
            "heatmap-radius": 10,
            // "heatmap-weight": [
            //     "interpolate",
            //     ["linear"],
            //     ["zoom"],
            //     16,
            //     0.5,
            //     17,
            //     1
            // ],
            // "heatmap-opacity": [
            //     "interpolate",
            //     ["linear"],
            //     ["zoom"],
            //     16,
            //     0.5,
            //     17,
            //     1
            // ],
            //   "heatmap-radius": [
            //     "interpolate",
            //     ["linear"],
            //     ["zoom"],
            //     15,
            //     5,
            //     17,
            //     10
            // ],
            // decrease opacity to transition into the circle layer
            //   "heatmap-opacity": {
            //     default: 1,
            //     stops: [
            //       [14, 1],
            //       [18, 0],
            //     ],
            //   },
          },
    },
    {
      id: "enforcement-violations-heat",
      type: "heatmap",
      source: "enforcement",
      filter: [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["get", "type"], "violation"],
      ],
      //maxzoom: 20,
      paint: record
        ? {
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.1,
              "#FEF8E8",
              0.2,
              "#FAD674",
              0.3,
              "#F7BB18",
              0.4,
              "#FC9647",
              0.5,
              "#EB6409",
              0.7,
              "#DF3A25",
              0.8,
              "#B22E1E",
              0.9,
              "#862316",
              1,
              "#2D0C07",
            ],
            "heatmap-radius": 10,
          }
        : {
            // increase intensity as zoom level increases
            //   "heatmap-intensity": {
            //     stops: [
            //       [11, 1],
            //       [18, 3],
            //     ],
            //   },
            // assign color values be applied to points depending on their density
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.1,
              "#FEF8E8",
              0.2,
              "#FAD674",
              0.3,
              "#F7BB18",
              0.4,
              "#FC9647",
              0.5,
              "#EB6409",
              0.7,
              "#DF3A25",
              0.8,
              "#B22E1E",
              0.9,
              "#862316",
              1,
              "#2D0C07",
            ],
            // increase radius as zoom increases
            "heatmap-radius": 5,
            //   "heatmap-radius": [
            //     "interpolate",
            //     ["linear"],
            //     ["zoom"],
            //     18,
            //     10,
            //     22,
            //     15
            // ],
            //   "heatmap-radius": {
            //     stops: [
            //       [11, 15],
            //       [18, 20],
            //     ],
            //   },
            // decrease opacity to transition into the circle layer
            //   "heatmap-opacity": {
            //     default: 1,
            //     stops: [
            //       [14, 1],
            //       [18, 0],
            //     ],
            //   },
          },
    },
  ] as AnyLayer[];

  function build(item) {
    const geojson = {
      type: "FeatureCollection",
      features: [
        ...(item.geo.features ?? []),
        //...(area ? [boundary] : []),
        // ...(bounds && area
        //   ? hexgrid(bounds, 0.0075)
        //       .features.filter((f) => intersect(f, boundary))
        //       .map((f) => {
        //         const enf = item.geo.features.filter(
        //           (feature) =>
        //             feature.geometry.type == "Point" &&
        //             (feature.properties.type == "check" ||
        //               feature.properties.type == "violation") &&
        //             inside(feature.geometry.coordinates, f)
        //         );

        //         f.properties = f.properties ?? {};
        //         f.properties.violations = enf.filter(
        //           (feature) => feature.properties.type == "violation"
        //         ).length;
        //         f.properties.checks = enf.filter(
        //           (feature) => feature.properties.type == "check"
        //         ).length;
        //         f.properties["enforcement"] = "yes";
        //         //logger("f=", f);
        //         return f;
        //       })
        //   : []),
      ],
    } as FeatureCollection;
    // geojson.features = item.geo.features || geojson.features;
    // if (area)
    //   geojson.features.push({
    //     type: "Feature",
    //     geometry: area,
    //   });
    // if (bounds) geojson.features.push(...hexgrid(bounds, 0.01).features);
    return geojson;
  }

  function data(map, geojson) {
    const id = "enforcement";
    var source = map.getSource(id);
    if (!!source) source.setData(geojson);
    else
      map.addSource(id, {
        type: "geojson",
        data: geojson,
      });
    source = map.getSource(id);
  }

  const { styledmap, style } = getSvelteContext();
  let MAP: VectorGLMap;

  //   onMount(function () {
  //     map.on("mousemove", onmousemove);
  //     return function destroy() {
  //       if (!map) return;
  //       map.off("mousemove", onmousemove);
  //     };
  //   });

  function init(
    $map: VectorGLMap,
    geojson: FeatureCollection,
    layers: AnyLayer[]
  ) {
    cleanup(); // cleanup previous map
    MAP = $map; // set

    if (!MAP) return;

    // about to do stuff
    //logger("init=", geojson, layers);

    data(MAP, geojson);
    for (const layer of layers) {
      if (!MAP.getLayer(layer.id)) MAP.addLayer(layer);
    }

    if (MAP.getMaxBounds())
      MAP.fitBounds(MAP.getMaxBounds(), {
        animate: false,
      });
  }
  function cleanup() {
    if (!MAP) return; // no map to cleanup

    // events
    MAP = null; //set
  }

  $: init($styledmap, build(item), layers);

  onMount(function () {
    //const unsubscribe = map.subscribe(init);
    return function destroy() {
      cleanup();
      //unsubscribe();
    };
  });
</script>
