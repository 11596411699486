<script>
  import PaymentCard from "./PaymentCard.svelte";
  import Time from "./Time.svelte";

  export let item;
  //$: logger("permit=", item);
  $: fees = item && item.fees && Object.values(item.fees.items || item.fees);
  // $: logger("fees=", fees);
  $: cancelled = item && item.cancelled;
</script>

<dl class="fees">
  <dt>Payment</dt>
  {#if fees && fees.length}
    {#each fees as fee}
      <dd>
        <h1>
          <data class="amount" value={fee.total.value}>{fee.total.display}</data
          >
          {fee.name}
          • {fee.description}
        </h1>
        <ul>
          {#each Object.values(fee.payments?.items || fee.payments || []) as payment}
            <li>
              {#if payment.captured}
                <Time
                  class="payment"
                  datetime={payment.captured.datetime}
                  definition="Paid"
                />
                <!-- <time class="payment" datetime={payment.captured.datetime}
                  ><dfn>Paid</dfn> <abbr>{payment.captured.datetime}</abbr>
                  </time
                > -->
              {:else if cancelled}
                <time datetime=""><dfn>Cancelled</dfn></time>
              {:else if payment.authorized && payment.capture && payment.capture.explicitly}
                <time class="payment" datetime={payment.authorized.datetime}
                  ><dfn>Pending</dfn>
                </time>
              {:else if payment.authorized}
                <time class="payment" datetime={payment.authorized.datetime}
                  ><dfn>Approved</dfn>
                </time>
              {:else}
                <time class="payment" datetime={payment.created}
                  ><dfn>Pending</dfn>
                </time>
              {/if}
              <PaymentCard item={payment.card} />
            </li>
          {:else}
            <li>Unpaid</li>
          {/each}
        </ul>
      </dd>
    {/each}
  {:else}
    <dd>No payment required</dd>
  {/if}
</dl>
<!-- {:else}
<dd>None</dd> -->
