<script lang="ts">
  import { createEventDispatcher, tick } from "svelte";
  import Record from "$components/record/RecordItem.svelte";
  import Select from "$components/record/SelectMedia.svelte";

  export let value: any = null;
  export let name = "media";
  export let required = false;
  export let readonly = false;
  export let scannable = false;
  export let items: any[] = [];
  export let label: string | null | undefined = "Decal/Tag";
  export let placeholder: string | null | undefined = null;

  let element: HTMLElement;

  //$: if (1 === items?.length) value = items[0];

  $: logger("media items=", items);

  $: id = value && (value?.id || value)?.toString();

  const evented = createEventDispatcher();

  async function change(newvalue: any | null | undefined) {
    value = newvalue;
    await tick();
    if (value)
      element?.scrollIntoView({ behavior: "instant", block: "center" });
    evented("change", {
      name,
      value,
    });
  }
</script>

{#if id}
  <figure class="value record" bind:this={element}>
    {#if label}
      <figcaption>{label}</figcaption>
    {/if}
    <input type="hidden" {name} value={id} />
    <Record item={value} url={false} />
    {#if !readonly}
      <button class="clear" type="button" on:click={(e) => change(null)}
        >change</button
      >
    {/if}
  </figure>
  <slot />
{:else}
  <Select
    placeholder={required ? "number" : "number"}
    {label}
    {required}
    {items}
    {scannable}
    on:media={(e) => change(e.detail)}
  />
  <slot />
{/if}
