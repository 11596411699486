<script>
  import debounce from "lodash-es/debounce";
  import orderBy from "lodash-es/orderBy";
  import uniqBy from "lodash-es/uniqBy";
  import MiniSearch from "minisearch";
  import { createEventDispatcher, onDestroy } from "svelte";
  import { comparer } from "$utils/sort";
  //import { identify } from "$utils/api";
  import MediaScanner from "$components/media/MediaScanner.svelte";
  import Record from "$components/record/RecordItem.svelte";

  const dispatch = createEventDispatcher();

  let selected;
  export let required = false;
  export let scannable = false;
  export let label = null;
  export let placeholder = "number";
  let scanning = false;

  export let items = [];
  //import { media as match } from "./select";
  import { event } from "$utils/track";

  //$: logger("items=", items);

  let type = "media";
  let name = null;
  let searched;
  let results = [];
  let datalist = null;
  let focused = false;
  let all = false;
  let query = "";

  //$: logger("match=", $match);

  const miniSearch = new MiniSearch({
    fields: ["display", "title", "label", "color"],
    searchOptions: {
      prefix: true,
      boost: { display: 2 },
    },
    storeFields: ["id", "key"],
    extractField: (document, fieldName) =>
      fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
  });

  $: itemsById = (items || []).reduce((map, item) => {
    return (map[item.id.toLowerCase()] = item) && map;
  }, {});

  //$: logger("items=", itemsById);

  $: if (items && !placeholder)
    placeholder = [
      ...new Set(items.map((i) => i.format.replace("-", " ").toLowerCase())),
    ].join("/");

  //$:logger("formats=", [...new Set((items || []).map(i => i.format))]);

  $: searched =
    items &&
    query &&
    uniqBy(
      orderBy(
        miniSearch.search(query),
        ["score", label],
        ["desc", "asc", "asc", "asc"]
      ),
      "id"
    );
  $: results =
    (searched && searched.map((result) => itemsById[result.id])) ||
    ((focused && items) || (all && items) || []).sort(comparer("display"));

  $: if (results.length == 1) select(results[0].id);

  // $: logger("results=", results);

  $: if (items) {
    miniSearch.removeAll();
    miniSearch.addAll(items);
  } else {
    miniSearch.removeAll();
  }

  const oninput = debounce(
    function (e) {
      // logger("suggest=", miniSearch.autoSuggest(e.target.value));
      // logger("search=", miniSearch.search(e.target.value));
      //logger("about to set match store = ", e.target.value);
      scanning = false;
      query = e.target.value;
    },
    150,
    {
      leading: true,
      trailing: true,
    }
  );

  $: if (selected) clearQuery();

  onDestroy(function () {
    scanning = false;
    clearQuery();
  });

  function select(value) {
    scanning = false;
    clearQuery();
    dispatch(
      type,
      (selected =
        (value?.toLowerCase && itemsById[value.toLowerCase()]) || value)
    );
  }

  function labeler(item) {
    return item.name || item.display;
  }
  async function onMediaScan(e) {
    //logger("onMediaInfotag", e);
    var { id, format, type } = e.detail;
    //logger("onmedia", id, format, itemsById[id.toLowerCase()]);
    if ("media" !== format && "media" !== type) return;
    let media;

    if (!!(media = itemsById[id.toLowerCase()])) select(media);
    event("mediascanned", "SelectMedia", !!media ? "matched" : "unmatched");
    //if ((media = itemsById[(await identify(id)).toLowerCase()])) select(media);
  }
  function onscanner(e) {
    scanning = !scanning;
    clearQuery();
    event("scanmedia", "SelectMedia", scanning ? "open" : "close");
  }

  function clearQuery() {
    query = "";
  }
</script>

<fieldset class="record {type}">
  {#if label}
    <label for="{type}-search">{label}</label>
  {/if}
  <input
    id="{type}-search"
    placeholder={focused ? "type to filter" : placeholder}
    type="text"
    on:input={oninput}
    on:change={oninput}
    autocorrect="off"
    autocapitalize="characters"
    spellcheck="false"
    autocomplete="off"
    required={!!required}
    on:focus={(e) => false && (focused = true)}
    on:blur={(e) => false && (focused = false)}
    list={datalist}
  />
  {#if scannable}
    <button class="scan media" class:scanning type="button" on:click={onscanner}
      >Scan</button
    >
  {/if}
  {#if items && items.length}
    <!-- <select on:change={(e) => select(e.target.value)}>
      <option value="">---</option>
      {#each items as item}
        <option value={item.id}>{labeler(item)}</option>
      {/each}
    </select> -->
    <button type="button" class="list all" on:click={(e) => (all = !all)}
      >List</button
    >
    {#if datalist}
      <datalist id={datalist}>
        {#each items as item}
          <option value={labeler(item)} />
        {/each}
      </datalist>
    {/if}
  {/if}
</fieldset>
{#if scanning}
  <MediaScanner on:media={onMediaScan} />
{/if}
<ul class="results">
  {#each results as item}
    <li>
      <button
        class="record"
        type="button"
        value={item.id}
        on:click={(e) => select(e.currentTarget.value)}
        ><Record {item} url={false} /></button
      >
    </li>
  {/each}
</ul>
