<script lang="ts">
  import { help } from "$utils/help";
  import DashboardLastWeek from "$components/DashboardLastWeek.svelte";
  import DashboardLastSevenDays from "$components/dashboard/DashboardLastSevenDays.svelte";
  import Time from "$components/Time.svelte";

  export let property: Property = null;

  export let authorized: any;
  export let sessions: any;
  export let enforcement: any;
  export let permits: Permits;

  //$: logger("enforcement=", enforcement);

  //$: logger("policies=", $policies);
</script>

<section class="dashboard">
  <header>
    {#if property}
      <h1>Welcome!</h1>
      <p>
        {property.name} has been Community Bossin' since <Time
          datetime={property.created}
          format="MMMM yyyy"
          timezone={property.timezone}
        />. Thanks for working with us!
      </p>
      <p>Here's a quick look at what your team has been up to recently:</p>
    {:else}
      <h1>Loading&hellip;</h1>
    {/if}
    <aside class="help">
      <p>
        <a
          on:click={help}
          href="mailto:help@communityboss.com?subject=Dashboard&body=I'd like to learn more about my activity:"
          >I'd like to learn more</a
        >
      </p>
    </aside>
  </header>

  <DashboardLastSevenDays
    {property}
    {authorized}
    {sessions}
    {enforcement}
    {permits}
  />

  <!-- {#if property}
    <section class="exempt">
      <header>
        <h1>Live</h1>
      </header>
    </section>
    <section class="exempt">
      <header>
        <h1>Last Week</h1>
      </header>
      <DashboardLastWeek {property} />
    </section>
  {/if} -->
</section>
