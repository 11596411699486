import { parseISO, format as formatDate } from "date-fns";
import { derived, readable, type Readable } from "svelte/store";
import { Temporal } from "temporal-polyfill";

const stores: Record<string, Readable<Temporal.ZonedDateTime>> = {};

export const midnight = new Temporal.PlainTime(0, 0, 0, 0, 0, 0);
export const maxtime = new Temporal.PlainTime(23, 59, 59);

export function instant(
  interval: string | Temporal.Duration | Temporal.DurationLike,
  timezone?: string
): Readable<Temporal.ZonedDateTime> {
  const ms = Temporal.Duration.from(interval).total({ unit: "milliseconds" });
  if (!timezone) timezone = Temporal.Now.timeZoneId();
  const key = `${ms}-${timezone || ""}`;
  return (stores[key] ??= readable(
    Temporal.Now.zonedDateTimeISO(timezone),
    (set) => {
      set(Temporal.Now.zonedDateTimeISO(timezone));
      const i = setInterval(
        () => set(Temporal.Now.zonedDateTimeISO(timezone)),
        ms
      );
      return () => clearInterval(i);
    }
  ));
}

export function format(
  value: Temporal.ZonedDateTime | Temporal.Instant | Temporal.PlainDateTime,
  format: string
) {
  var parsable = value.toString({
    timeZoneName: "never",
    calendarName: "never",
  });

  var date = parseISO(parsable);
  return formatDate(date, format);
}


export function before(first: Temporal.Instant, second: Temporal.Instant) {
  return Temporal.Instant.compare(first, second) === -1;
}

export function after(first: Temporal.Instant, second: Temporal.Instant) {
  return Temporal.Instant.compare(first, second) === 1;
}

export const zero = new Temporal.Duration(0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
export const second = Temporal.Duration.from({ seconds: 1 });
export const minute = Temporal.Duration.from({ minutes: 1 });
export const hour = Temporal.Duration.from({ hours: 1 });

//export const midnight = new Temporal.PlainTime(0, 0, 0, 0, 0, 0);
export const minInstant = new Temporal.Instant(-2208988800000000000n);
export const maxInstant = new Temporal.Instant(2208988800000000000n);
export const maxDuration = maxInstant.since(new Temporal.Instant(0n));

const timeformat: Intl.DateTimeFormatOptions = {
  hour12: true,
  hour: "numeric",
  minute: "numeric",
  //timeZone: "UTC",
  //second: "numeric",
  //timeZoneName: "short",
};
const basetimeformatter = new Intl.DateTimeFormat("en-US", timeformat);
const shortdateformat: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  //timeZone: "UTC"
};
const basedateformatter = new Intl.DateTimeFormat("en-US", shortdateformat);

const timeByTZ: Record<string, Intl.DateTimeFormat> = {};

export function time(value: Temporal.ZonedDateTime | Temporal.PlainTime): string {
  if (!value) return "";
  if (value instanceof Temporal.ZonedDateTime) return time(value.toPlainTime());

  //new Date(Temporal.Now.plainDateISO().toPlainDateTime(value).toString())


  //const tz = value.timeZoneId;

  // const formatter = (timeByTZ[tz] ??= new Intl.DateTimeFormat("en-US", {
  //   ...basetimeformat,
  //   timeZone: tz,
  // }));

  // this conversion to date is assuming parsed as local time
  return basetimeformatter.format(new Date(2000, 0, 1, value.hour, value.minute, value.second, value.millisecond));

  // const parts = formatter.formatToParts(value.epochMilliseconds).reduce(
  //   (result, part) => {
  //     if (part.type === "literal") return result;
  //     result[part.type] = part.value;
  //     return result;
  //   },
  //   {} as Record<string, string>
  // );

  //logger(date.toString(), parts, date.getTimeZone());

  //return `${parts.hour}:${parts.minute} ${parts.dayPeriod}`;

}

export function dateparts(value: Temporal.Instant | Temporal.ZonedDateTime | Temporal.PlainDateTime | Temporal.PlainDate, formatter: Intl.DateTimeFormat = basedateformatter): Record<Intl.DateTimeFormatPartTypes, string> {
  if (value instanceof Temporal.Instant) return dateparts(value.toZonedDateTimeISO(Temporal.Now.timeZoneId()).toPlainDate());

  if (value instanceof Temporal.ZonedDateTime) return dateparts(value.toPlainDate());
  if (value instanceof Temporal.PlainDateTime) return dateparts(value.toPlainDate());

  return formatter.formatToParts(new Date(value.year, value.month - 1, value.day)).reduce(
    (result, part) => {
      if (part.type === "literal") return result;
      result[part.type] = part.value;
      return result;
    },
    {} as Record<Intl.DateTimeFormatPartTypes, string>
  );
}

export function date(value: Temporal.ZonedDateTime | Temporal.PlainDateTime | Temporal.PlainDate, yearIfSame: boolean = true): string {
  if (!value) return "";
  // if (value instanceof Temporal.ZonedDateTime) return date(value.toPlainDate(), yearIfSame);
  // if (value instanceof Temporal.PlainDateTime) return date(value.toPlainDate(), yearIfSame);

  //new Date(Temporal.Now.plainDateISO().toPlainDateTime(value).toString())


  //const tz = value.timeZoneId;

  // const formatter = (timeByTZ[tz] ??= new Intl.DateTimeFormat("en-US", {
  //   ...basetimeformat,
  //   timeZone: tz,
  // }));

  //return base.format(new Date(value.toString()));

  // this conversion to date is assuming parsed as local time
  // const parts = basedateformatter.formatToParts(new Date(value.year, value.month - 1, value.day)).reduce(
  //   (result, part) => {
  //     if (part.type === "literal") return result;
  //     result[part.type] = part.value;
  //     return result;
  //   },
  //   {} as Record<string, string>
  // );

  const parts = dateparts(value);


  const sameYear = value.year === Temporal.Now.plainDateISO().year;

  //logger(parts, "sameYear", sameYear, yearIfSame);

  return yearIfSame || !sameYear ? `${parts.weekday} ${parts.month} ${parts.day} ${parts.year}` : `${parts.weekday} ${parts.month} ${parts.day}`;

}

const baseformat: Intl.DateTimeFormatOptions = {
  ...timeformat,
  ...shortdateformat,
  timeZoneName: "short",
};

const formattersByTZ: Record<string, Intl.DateTimeFormat> = {};

function defaultformattertz(values: Record<"hour" | "minute" | "dayPeriod" | "weekday" | "month" | "day" | "year" | "timeZoneName", string>) {
  return `${values.hour}:${values.minute} ${values.dayPeriod} ${values.weekday} ${values.month} ${values.day} ${values.year} ${values.timeZoneName}`;
}

function defaultformatter(values: Record<"hour" | "minute" | "dayPeriod" | "weekday" | "month" | "day" | "year" | "timeZoneName", string>) {
  return `${values.hour}:${values.minute} ${values.dayPeriod} ${values.weekday} ${values.month} ${values.day} ${values.year}`;
}


export function datetime(date: Temporal.ZonedDateTime | Temporal.Instant | string | null | undefined, timezone: Temporal.TimeZoneLike | boolean = true, toString: typeof defaultformatter = defaultformattertz) {
  if (!date) return "";

  if (typeof date == "string") date = Temporal.Instant.from(date);


  // we passed a timezone by id
  if (typeof timezone == "string" && date instanceof Temporal.Instant) return datetime(date.toZonedDateTimeISO(timezone), true, toString);

  if (date instanceof Temporal.Instant) return datetime(date.toZonedDateTimeISO(Temporal.Now.timeZoneId()), timezone, toString);

  // check for no timezone flag
  if (!timezone && toString === defaultformattertz) return datetime(date, timezone, defaultformatter);

  const tz = date.timeZoneId;

  const formatter = (formattersByTZ[tz] ??= new Intl.DateTimeFormat("en-US", {
    ...baseformat,
    timeZone: tz,
  }));

  const parts = formatter.formatToParts(date.epochMilliseconds).reduce(
    (result, part) => {
      if (part.type === "literal") return result;
      result[part.type] = part.value;
      return result;
    },
    {} as Record<string, string>
  );

  //logger(date.toString(), parts, date.getTimeZone());

  return toString(parts); //`${parts.hour}:${parts.minute} ${parts.dayPeriod} ${parts.weekday} ${parts.month} ${parts.day} ${parts.year} ${parts.timeZoneName}`;
}

export function indefinite(value: Temporal.ZonedDateTime | Temporal.Instant | Temporal.Duration): boolean {
  if (value instanceof Temporal.Duration) {
    //logger("indefinite", value.toString(), maxDuration.toString(), Temporal.Duration.compare(value, maxDuration) >= 0);
    return Temporal.Duration.compare(value, maxDuration) >= 0;
  }
  if (value instanceof Temporal.ZonedDateTime) return indefinite(value.toInstant());
  //if (value instanceof Temporal.Instant) {
  return Temporal.Instant.compare(value, maxInstant) >= 0 || Temporal.Instant.compare(value, minInstant) <= 0;
  //}
  //return Temporal.Instant.compare(value.toInstant(), maxInstant) >= 0 || Temporal.Instant.compare(value.toInstant(), minInstant) <= 0;
}


export function iso(
  value:
    | Temporal.ZonedDateTime
    | Temporal.Instant
    | Temporal.PlainDateTime
    | Temporal.PlainDate
    | Temporal.PlainTime
    | Temporal.Duration
    | string
    | null
    | undefined
): string | null | undefined {
  if (!value) return;
  if (typeof value == "string") return value;
  if (
    value instanceof Temporal.Instant &&
    Temporal.Instant.compare(value, maxInstant) >= 0
  )
    return "";
  if (value instanceof Temporal.Duration && indefinite(value)) return "";
  if (
    value instanceof Temporal.ZonedDateTime &&
    indefinite(value)
  )
    return "";
  if (
    value instanceof Temporal.Instant &&
    indefinite(value)
  )
    return "";
  return value.toString({
    timeZoneName: "never",
    calendarName: "never",
    //smallestUnit: "millisecond",
  });
}

export function dates(
  min: Temporal.PlainDate,
  max: Temporal.PlainDate
): Temporal.PlainDate[] {
  const items = [];
  for (
    var i = min;
    Temporal.PlainDate.compare(i, max) <= 0;
    i = i.add({ days: 1 })
  ) {
    items.push(i);
  }
  return items;
}

//export const midnight = Temporal.PlainTime.from("00:00:00");

export function times(
  min: Temporal.PlainTime = Temporal.PlainTime.from("00:00:00"),
  max: Temporal.PlainTime = Temporal.PlainTime.from("23:59:59"),
  increment: Temporal.Duration = Temporal.Duration.from("PT15M")
): Temporal.PlainTime[] {
  // logger(
  //   "times eval",
  //   min.toString(),
  //   max.toString(),
  //   increment.toString()
  // );
  if (Temporal.PlainTime.compare(min, max) == 0) return [min];

  const items = [];
  if (Temporal.PlainTime.compare(min, max) > 0) {
    // crosses midnight

    // go from min to midnight
    for (
      var i = min;
      Temporal.PlainTime.compare(i, midnight) > 0 &&
      Temporal.PlainTime.compare(i, Temporal.PlainTime.from("23:59:59")) < 0;
      i = i.add(increment)
    ) {
      items.push(i);
    }

    //items.push(midnight);

    // go from midnight to max
    for (
      var i = Temporal.PlainTime.from("00:00");
      Temporal.PlainTime.compare(i, max) <= 0;
      i = i.add(increment)
    ) {
      items.push(i);
    }
  } else {
    // go from min to max
    // handle midnight
    if (Temporal.PlainTime.compare(min, midnight) == 0) {
      items.push(min);
      min = min.add(increment);
    }
    for (
      var i = min;
      Temporal.PlainTime.compare(i, midnight) > 0 &&
      Temporal.PlainTime.compare(i, max) <= 0; // greater than midnight less than max
      i = i.add(increment)
    ) {
      items.push(i);
    }
  }

  return items;
}


export class PlainDayTime {
  day: number;
  time: Temporal.PlainTime;
  constructor(day: number, time: Temporal.PlainTime) {
    this.day = day;
    this.time = time;
  }
  static from(s: string): PlainDayTime {
    const [day, time] = s.split("T");
    return new PlainDayTime(+day, Temporal.PlainTime.from(time));
  }
  addDays(days: number): PlainDayTime {
    if (days > 7) return new PlainDayTime(this.day + (days % 7), this.time); // man gpt got this one
    if (this.day + days > 7)
      return new PlainDayTime(this.day + days - 7, this.time);
    return new PlainDayTime(this.day + days, this.time);
  }
  static compare(a: PlainDayTime, b: PlainDayTime): number {
    if (a.day > b.day) return 1;
    if (a.day < b.day) return -1;
    return Temporal.PlainTime.compare(a.time, b.time);
  }
  toString(): string {
    return `${this.day}T${this.time}`;
  }
}

export class PlainDayTimeInterval {
  start: PlainDayTime;
  end: PlainDayTime;
  constructor(start: PlainDayTime, end: PlainDayTime) {
    this.start = start;
    this.end = end;
  }
  static from(s: string): PlainDayTimeInterval {
    const [start, end] = s.split("/");
    return new PlainDayTimeInterval(
      PlainDayTime.from(start),
      PlainDayTime.from(end)
    );
  }
  toString(): string {
    return `${this.start}/${this.end}`;
  }

  duration(): Temporal.Duration {
    if (this.wrapsWeek()) {
      // two parts
      const days = 6 - this.start.day + this.end.day;
      return Temporal.Duration.from({ days })
        .add(this.start.time.until(midnight))
        .add(this.end.time.since(midnight));
    }
    const days = this.end.day - this.start.day;
    return Temporal.Duration.from({ days })
      .add(this.start.time.until(midnight))
      .add(this.end.time.since(midnight));

    //return null;
  }
  contains(t: PlainDayTime | PlainDayTimeInterval): boolean {
    if (t instanceof PlainDayTimeInterval) {
      // logger(
      //   "contains interval",
      //   this.toString(),
      //   t.toString(),
      //   this.contains(t.start) && this.contains(t.end)
      // );
      return this.contains(t.start) && this.contains(t.end);
    }
    //   logger(
    //     "contains",
    //     this.start.toString(),
    //     t.toString(),
    //     this.end.toString(),
    //     PlainDayTime.compare(this.start, t),
    //     PlainDayTime.compare(this.end, t),
    //     PlainDayTime.compare(this.start, t) <= 0 &&
    //       PlainDayTime.compare(this.end, t) > 0
    //   );
    if (this.wrapsWeek()) {
      return (
        PlainDayTime.compare(this.start, t) <= 0 ||
        PlainDayTime.compare(this.end, t) > 0
      );
    }
    return (
      PlainDayTime.compare(this.start, t) <= 0 &&
      PlainDayTime.compare(this.end, t) > 0
    );
  }
  // overlaps(other: PlainDayTimeInterval): boolean {
  //   return (
  //     this.contains(other.start) ||
  //     this.contains(other.end) ||
  //     other.contains(this.start) ||
  //     other.contains(this.end)
  //   );
  // }
  wrapsWeek(): boolean {
    return this.start.day > this.end.day;
  }
}
export function* months(
  a: Temporal.PlainYearMonth,
  b: Temporal.PlainYearMonth
): Generator<Temporal.PlainYearMonth, void, any> {
  while (Temporal.PlainYearMonth.compare(a, b) <= 0) {
    yield a;
    a = a.add({ months: 1 });
  }
}
export function* days(
  month: Temporal.PlainYearMonth
): Generator<Temporal.PlainDate, void, any> {
  for (var day = 1; day <= month.daysInMonth; day++) {
    yield month.toPlainDate({ day });
  }
}
export function* daysparts(
  month: Temporal.PlainYearMonth,
  formatter: Intl.DateTimeFormat = basedateformatter
): Generator<
  [Temporal.PlainDate, Record<Intl.DateTimeFormatPartTypes, string>],
  void,
  any
> {
  for (const day of days(month)) {
    yield [day, dateparts(day, formatter)];
  }
}
export function plaindate(
  value:
    | Temporal.ZonedDateTime
    | Temporal.PlainDate
    | Temporal.PlainDateLike
    | string
) {
  if (value instanceof Temporal.PlainDate) return value;
  if (value instanceof Temporal.ZonedDateTime) return value.toPlainDate();
  return Temporal.PlainDate.from(value);
}
export function plainyearmonth(
  value:
    | Temporal.ZonedDateTime
    | Temporal.PlainDate
    | Temporal.PlainYearMonth
    | Temporal.PlainYearMonthLike
    | string
): Temporal.PlainYearMonth {
  if (value instanceof Temporal.PlainYearMonth) return value;
  if (value instanceof Temporal.ZonedDateTime)
    return value.toPlainYearMonth();
  if (value instanceof Temporal.PlainDate) return value.toPlainYearMonth();
  return Temporal.PlainYearMonth.from(value);
}