<script lang="ts" context="module">
  let i = 0;
</script>

<script lang="ts">
  import { format as formatDate } from "./DateInput.svelte";
  import TimeInput, { format as formatTime } from "./TimeInput.svelte";
  import { createEventDispatcher } from "svelte";

  import DateInput from "./DateInput.svelte";
  import { maxtime, midnight } from "$utils/temporal";
  export let name = "end";
  export let value: string | null = null;
  export let label: string = "End";
  export let empty: string = "";
  export let time: string | Temporal.PlainTime | Temporal.PlainTimeLike =
    maxtime;

  //$: logger("validdatetime=", value);

  $: required = !empty;

  const id = `valid-${name}-datetime-${i++}`;

  const events = createEventDispatcher();

  $: if (!value && required) value = Temporal.Now.plainDateTimeISO().toString();

  $: datevalue = value && Temporal.PlainDate.from(value);
  $: timevalue = value && Temporal.PlainTime.from(value);

  function change(name: string, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    if (name)
      events("change", {
        name,
        value,
      });
  }

  function changeDate(newvalue: string) {
    change(
      name,
      (newvalue &&
        Temporal.PlainDate.from(newvalue)
          .toPlainDateTime(timevalue || time || midnight)
          .toString()) ||
        ""
    );
  }

  function changeTime(newvalue: string) {
    if (!datevalue) return;
    change(
      name,
      (newvalue && datevalue.toPlainDateTime(newvalue).toString()) || ""
    );
  }
</script>

<fieldset class="valid max">
  <label for={id}>{label}</label>

  {#if value}
    <input
      type="hidden"
      name="{name}Date"
      value={datevalue?.toString() || ""}
    />
    <input
      type="hidden"
      name="{name}Time"
      value={timevalue?.toString() || ""}
    />

    <data class="date" value={datevalue?.toString() || ""}
      >{formatDate(value)}<DateInput
        id="{id}-date"
        value={datevalue?.toString() || ""}
        {empty}
        on:change={(e) => changeDate(e.detail)}
        min={Temporal.Now.plainDateISO().toString()}
      />
    </data>
    <data class="time" value={timevalue?.toString() || ""}
      >{formatTime(value)}<TimeInput
        id="{id}-time"
        value={timevalue?.toString() || ""}
        on:change={(e) => changeTime(e.detail)}
      />
    </data>
  {:else}
    <data class="date" value=""
      >{empty}<DateInput
        id="{id}-date"
        value={datevalue?.toString() || ""}
        on:change={(e) => changeDate(e.detail)}
        min={Temporal.Now.plainDateISO().toString()}
        {empty}
      /></data
    >
  {/if}
  <!-- <input
    type="date"
    id="{id}-date"
    on:input={oninput}
    on:change={oninput}
    value={datevalue?.toString() || ""}
    min={Temporal.Now.plainDateISO().toString()}
  />
  <input
    type="datetime-local"
    {id}
    on:input={oninput}
    on:change={oninput}
    value={datevalue?.toString() || ""}
    min={Temporal.Now.plainDateTimeISO().toString()}
  /> -->
  <!-- {#if value && !required}<button
      class="clear"
      type="button"
      on:click={(e) => changeDate(null)}>clear</button
    >
  {/if} -->
</fieldset>
