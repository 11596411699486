<script lang="ts">
  import { onMount } from "svelte";
  import omit from "lodash-es/omit";
  import {
    getSvelteContext,
    type AnyLayer,
    type VectorGLMap,
  } from "$utils/mapping";
  import type { Feature, FeatureCollection } from "geojson";
  export let present: any;

  $: logger("VehiclesPresentHeatmap=", present);

  const layers = [
    // {
    //   id: "property-outline",
    //   type: "line",
    //   source: "enforcement",
    //   filter: ["all", ["==", ["geometry-type"], "Polygon"], ["has", "landuse"]],
    //   paint: {
    //     "line-color": "red",
    //     "line-width": 2,
    //     //"line-opacity":0.6,
    //     "line-dasharray": [1.5, 0.5],
    //   },
    //   layout: {
    //     //"line-cap":"round",
    //     "line-join": "round",
    //   },
    // },
    // {
    //   id: "enforcement-background",
    //   type: "background",
    //   //source: "enforcement",
    //   //filter: ["all", ["==", ["geometry-type"], "Polygon"], ["has", "landuse"]],
    //   paint: {
    //     "background-color": "#f8f8f8",
    //     "background-opacity": 0.5,
    //   },
    //   layout: {},
    // },
    // {
    //   id: "property-grid-status",
    //   type: "fill",
    //   source: "enforcement",
    //   filter: [
    //     "all",
    //     ["==", ["geometry-type"], "Polygon"],
    //     ["has", "enforcement"],
    //   ],
    //   paint: {
    //     "fill-color": [
    //       "case",
    //       [">", ["get", "violations"], 3],
    //       "#b22e1e",
    //       [">", ["get", "violations"], 1],
    //       "#fc9647",
    //       [">", ["get", "violations"], 0],
    //       "#fad674",
    //       [">", ["get", "checks"], 10],
    //       "#1177d4",
    //       [">", ["get", "checks"], 3],
    //       "#44a5ff",
    //       [">", ["get", "checks"], 0],
    //       "#a1d2ff",
    //       "transparent",
    //     ],
    //     "fill-opacity": [
    //       "case",
    //       [">", ["get", "violations"], 3],
    //       0.75,
    //       [">", ["get", "violations"], 1],
    //       0.5,
    //       [">", ["get", "violations"], 0],
    //       0.25,
    //       [">", ["get", "checks"], 10],
    //       0.75,
    //       [">", ["get", "checks"], 3],
    //       0.5,
    //       [">", ["get", "checks"], 0],
    //       0.25,
    //       0,
    //     ],
    //     //"line-dasharray": [1.5, 0.5],
    //   },
    // },
    // {
    //   id: "property-grid",
    //   type: "line",
    //   source: "enforcement",
    //   filter: [
    //     "all",
    //     ["==", ["geometry-type"], "Polygon"],
    //     ["has", "enforcement"],
    //   ],
    //   paint: {
    //     "line-color": "#888",
    //     "line-width": 0.25,
    //     "line-opacity": 0.1,
    //     //"line-dasharray": [1.5, 0.5],
    //   },
    //   layout: {
    //     //"line-cap":"round",
    //     "line-join": "round",
    //   },
    // },
    {
      id: "present-heat",
      type: "heatmap",
      source: "present",
      filter: [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["get", "type"], "detection"],
      ],
      //maxzoom: 20,
      paint: {
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(0, 0, 255, 0)",
          0.2,
          "#7F48D8",
          0.4,
          "#A078E2",
          0.6,
          "#B393E8",
          0.8,
          "#D9C9F3",
          1,
          "#E5DAF7",
        ],
        "heatmap-weight": 0.2,
        //"heatmap-opacity":0.5,
        "heatmap-opacity": 1,
        "heatmap-radius": 10,
      },
    },
    {
      id: "present-offenders-heat",
      type: "heatmap",
      source: "present",
      filter: [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["get", "type"], "offender"],
      ],
      // layout: {
      //   visibility: "none",
      // },
      //maxzoom: 20,
      paint: {
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(0, 0, 255, 0)",
          0.1,
          "#FEF8E8",
          0.2,
          "#FAD674",
          0.3,
          "#F7BB18",
          0.4,
          "#FC9647",
          0.5,
          "#EB6409",
          0.7,
          "#DF3A25",
          0.8,
          "#B22E1E",
          0.9,
          "#862316",
          1,
          "#2D0C07",
        ],
        "heatmap-radius": 5,
      },
    },
  ] as AnyLayer[];

  function build(item: any) {
    const geojson = {
      type: "FeatureCollection",
      features: Object.values(item?.items ?? []).reduce(function (
        features: Feature[],
        detection: any
      ) {
        //logger("detection=", detection);
        if (!detection?.position?.lat) return features;
        if (!detection?.position?.lon) return features;
        features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [detection.position.lon, detection.position.lat],
          },
          properties: {
            //...omit(detection.position, ["lat", "lon"]),
            ...omit(detection, "position"),
          },
        });

        return features;
      }, [] as Feature[]),
    } as FeatureCollection;
    //logger("features=", geojson);
    return geojson;
  }

  function data(map, geojson) {
    const id = "present";
    var source = map.getSource(id);
    if (!!source) source.setData(geojson);
    else
      map.addSource(id, {
        type: "geojson",
        data: geojson,
      });
    source = map.getSource(id);
  }

  const { styledmap, style } = getSvelteContext();
  let MAP: VectorGLMap;

  //   onMount(function () {
  //     map.on("mousemove", onmousemove);
  //     return function destroy() {
  //       if (!map) return;
  //       map.off("mousemove", onmousemove);
  //     };
  //   });

  function init(
    $map: VectorGLMap,
    geojson: FeatureCollection,
    layers: AnyLayer[]
  ) {
    cleanup(); // cleanup previous map
    MAP = $map; // set

    if (!MAP) return;

    // about to do stuff
    // logger("init heatmap=", geojson, layers);

    data(MAP, geojson);
    for (const layer of layers) {
      if (!MAP.getLayer(layer.id)) MAP.addLayer(layer);
    }

    if (MAP.getMaxBounds())
      MAP.fitBounds(MAP.getMaxBounds(), {
        animate: false,
      });
  }
  function cleanup() {
    if (!MAP) return; // no map to cleanup

    // events
    MAP = null; //set
  }

  $: init($styledmap, build(present), layers);

  onMount(function () {
    //const unsubscribe = map.subscribe(init);
    return function destroy() {
      cleanup();
      //unsubscribe();
    };
  });
</script>
