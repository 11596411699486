<script>
  import TimeInterval from "./TimeInterval.svelte";

  import { parseToDateInterval } from "$utils/datetime";

  export let item;
  //$: logger("Resident Item", item);
</script>

<article class="resident details">
  <header><h1>{item.name}</h1></header>
  <dl>
    <!-- <dt>ID</dt>
    <dd>{item.id}</dd> -->
    {#each Object.values(item.mobile ?? []) as mobile}
      <dt>Mobile</dt>
      <dd>{mobile || "--"}</dd>
    {/each}
    {#each Object.values(item.email ?? []) as email}
      <dt>Email</dt>
      <dd>{email || "--"}</dd>
    {/each}
    {#if item.current?.occupancy}
      <dt>From</dt>
      <dd>
        <TimeInterval
          interval={item.current.occupancy}
          timezone={item.timezone}
          indefinite="present"
        />
      </dd>
    {/if}
    <!-- {#if item.resident}
      <dt>From</dt>
      <dd>
        <TimeInterval
          interval={item.resident}
          timezone={item.timezone}
          indefinite="present"
        />
      </dd>
    {/if}
    <dt>Source</dt>
    <dd>{item.service.name}</dd> -->
  </dl>
</article>
