<script>
  export let item;
  //$: logger("item=", item);
  import { state as editing } from "$utils/item";
  function click(e) {
    e.preventDefault();
    $editing.start("notify", item);
  }
</script>

<a
  on:click={click}
  href="mailto:?body={item.url}"
  data-sent={Object.values(item.attached?.items || {}).reduce((count, item) => {
    if (item.type == "sent") return count + 1;
    return count;
  }, 0)}>Notify</a
>
