<script>
  import { isToday, parseISO } from "date-fns";
  import { format as formatTime, toZonedTime } from "date-fns-tz";

  export let datetime;
  export let definition = null;
  export let empty = "";
  export let timezone = undefined;
  export let time = "h:mm a";
  export let weekday = "";
  export let date = "MMM d yyyy";
  export let zone = "zzz";
  export let format = ""; // = `${time} ${day} ${date} ${zone}`;
  let classname = "";
  export { classname as class };

  let zoned;
  //$: logger("datetime=", datetime);
  $: iso = datetime && parseISO(datetime);
  $: if (timezone) zoned = toZonedTime(iso, timezone);
  $: today = isToday(iso);
</script>

<time class={classname} datetime={datetime || ""} class:today>
  {#if definition}
    <dfn>{definition}</dfn>
  {/if}
  {#if datetime && format}
    <abbr
      >{zoned
        ? formatTime(zoned, format || "h:mm a MMM d yyyy zzz", {
            timeZone: timezone,
          })
        : formatTime(iso, format || "h:mm a MMM d yyyy zzz")}</abbr
    >
    <data value={timezone ?? ""} />
    <slot />
  {:else if datetime}
    {#if time}
      <data class="time" value="">{formatTime(zoned || iso, time)}</data>
    {/if}
    {#if weekday}
      <data class="day" value=""
        >{formatTime(
          zoned || iso,
          today && weekday === "eee" ? "'today'" : weekday,
          {
            timeZone: timezone,
          }
        )}</data
      >
    {/if}
    {#if date}
      <data class="date" value=""
        >{formatTime(zoned || iso, date, {
          timeZone: timezone,
        })}</data
      >
    {/if}
    {#if zone}
      <data class="date" value={timezone}
        >{formatTime(zoned || iso, zone, {
          timeZone: timezone,
        })}</data
      >
    {/if}
  {:else if empty}
    {empty}
    <slot />
  {:else}
    <slot name="empty">Loading&hellip;</slot>
  {/if}
</time>
