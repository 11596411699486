<svelte:options runes={true} />

<script lang="ts">
  const {
    connecting = false,
    connected = false,
    labels = {},
  } = $props<{
    connecting?: boolean;
    connected?: boolean;
    labels?: Record<string, string>;
  }>();
  let status = $derived(
    connected ? "connected" : connecting ? "connecting" : "disconnected"
  );
</script>

<figure class="connection status">
  <data><dfn>{labels?.[status] || status}</dfn></data>
</figure>
