<script lang="ts">
  import TenantField from "$components/form/TenantField.svelte";
  import TextField from "$components/form/TextField.svelte";
  import Copy from "$components/util/Copy.svelte";
  import { fetchSend } from "$utils/api";
  import { tenantsByUnit, units } from "$utils/propertystores";
  import { createEventDispatcher } from "svelte";

  //export let property: Property;
  export let tenant: Tenant;
  export let variant: string | string[] = null;

  export const values: Record<string, any> = {};

  let context: string;

  let submittable = false;
  let submitting = false;
  let form: HTMLFormElement;
  const events = createEventDispatcher();

  $: if (!context && variant && typeof variant == "string") context = variant;

  $: item =
    tenant ||
    (Object.values($tenantsByUnit?.[values.subject?.id]?.tenants ?? {}).find(
      (item: Tenant) => item.valid.interval.endsWith("/")
    ) as Tenant);

  $: urls = item && {
    welcome: {
      title: "Resident Map & Guide",
      url: `https://my.communityhq.app?account=${item.id}`,
    },
    amenities: {
      title: "Amenity Pass",
      url: `https://amenitypass.app/properties/${item.scope}`,
    },
    parking: {
      title: "Parking Attendant",
      url: `https://my.parkingattendant.app/${item.scope}`,
    },
  };
  //$: logger(tenant);

  async function onsubmit(e: SubmitEvent) {
    e.preventDefault();
    if (!submittable || submitting) return;
    e.preventDefault();

    // preprocess
    const form = e.target as HTMLFormElement;
    const formdata = new FormData(form);

    submitting = true;

    // do the stuff
    return fetchSend(item, formdata)
      .then(function (json) {
        if (json.message) alert(json.message);
        else {
          var items = Object.values(json.send?.created ?? [])
            .map(
              (value: string) => json.items[value] ?? json.send.items?.[value]
            )
            .reduce((result, item) => {
              result[item.id] = item;
              return result;
            }, {});

          events("complete", item);
          form.reset();
        }
      })
      .catch(function (error) {
        alert("Whoops, something went wrong!");
      })
      .then(function () {
        // cleanup
        submitting = false;
      }); // how do we handle statuses here?
  }

  function onreset(e) {
    events("cancel", item);
  }

  function updateSubmittable(form) {
    submittable =
      !!form?.checkValidity() &&
      item &&
      (values.to || values.tel || values.email);
  }

  function change(name, value) {
    values[name] = value;
    events("change", values);
  }

  function validate(e) {
    //logger("onchange=", e.target.name, e.target.value, e);
    updateSubmittable(e.target.form); // additional logic?
  }

  $: form && updateSubmittable(form);
</script>

<form
  class="send"
  class:new={!!tenant}
  bind:this={form}
  on:submit={onsubmit}
  on:input={validate}
  on:change={validate}
  on:reset={onreset}
  novalidate
>
  {#if variant && typeof variant == "string"}
    <input type="hidden" name="context" value={variant} />
  {/if}
  <ul>
    <li>
      <TenantField
        name="subject"
        value={item}
        required
        readonly={!!tenant}
        on:change={(e) => change(e.detail.name, e.detail.value || false)}
        items={Object.values($units?.items ?? {})}
      />
    </li>
    {#if null == variant || typeof variant != "string"}
      <li>
        <fieldset>
          <label for="welcome-notify-format">Type</label>
          <select name="context" required bind:value={context}>
            <option value="">select…</option>
            <option value="welcome">map & guide</option>
            <option value="parking">parking</option>
            <option value="amenities">amenities</option>
          </select>
        </fieldset>
      </li>
    {/if}
    {#if urls?.[context]}
      <li>
        <figure class="value">
          <figcaption>Direct Link</figcaption>

          <a href={urls[context].url} target="_blank">{urls[context].title}</a>
          <Copy value={urls[context].url} />
        </figure>
      </li>
    {/if}

    <li>
      <TextField
        autocomplete="off"
        name="to"
        label="Send To"
        placeholder="email or text"
        on:change={(e) => change(e.detail.name, e.detail.value)}
      />
    </li>
  </ul>

  <footer>
    <fieldset>
      <button disabled={!submittable || submitting} type="submit"
        >{submitting ? "Sending…" : "Send To Resident"}</button
      >
      <button type="reset">Cancel</button>
    </fieldset>
  </footer>
</form>
