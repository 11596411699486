<script lang="ts">
  import Record from "$components/record/RecordItem.svelte";
  import UnitStatusTimelineItem from "./UnitStatusTimelineItem.svelte";

  export let item;
  //$: logger("status=", item);
  $: attention = item.subject && (item.check?.vacant || item.check.reoccupied);
</script>

{#if item}
  <article class={item.type}>
    {#if item.subject && typeof item.subject !== "string"}
      <header>
        <h1>
          <Record item={item.subject} />
        </h1>
        <a
          class:attention
          href="/properties/{item.scope}/{item.subject.type}/{item.subject.id}"
          >{attention ? "Review" : "Details"}</a
        >
      </header>
    {:else if typeof item.subject === "string"}
      <h1>{item.unit} (LINKED TO DELETED UNIT)</h1>
    {:else}
      <h1>{item.unit} (UNLINKED)</h1>
    {/if}
    <UnitStatusTimelineItem {item} />
  </article>
{/if}
