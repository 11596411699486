import { authHeader, base, viewpoint } from "$utils/api";
import { autocreate } from ".";

export async function fetchVehiclesEnforceZoned(scope, valid) {
    if (!scope) return null;
    const json = await Promise.all([
        fetch(
            `${base()}/vehicles/enforce/zoned?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
                valid ?? ""
            )}&${authHeader}`
        ),
    ])
        .then((values) => Promise.all(values.map((res) => res.json())))
        .then((values) => Object.assign({}, ...values))
        .then((values) => {


            //logger('items=', values.items);
            for (const [k1, v1] of Object.entries((values.vehicles?.enforce?.for ?? {}) as Record<string, any>)) {

                for (const [k2, item] of Object.entries((v1.items ?? {}) as Record<string, any>)) {
                    // this should be a present item
                    //logger("item", item);
                    for (const k3 of ["vehicle"]) {
                        //logger("k3", k3, values.items[k3] ?? item[k3]);
                        if (!item[k3]) continue;
                        const prop = (item[k3] = (values.items[item[k3]] ?? item[k3]));
                        if (prop.file) prop.file = values.items[prop.file] ?? prop.file;
                    }
                    for (const k3 of ["ingress", "egress"]) {
                        //logger("k3", k3, values.items[k3] ?? item[k3]);
                        if (!item.present?.[k3]) continue;
                        const prop = (item.present[k3] = (values.items[item.present[k3]] ?? item.present[k3]));
                        if (prop.file) prop.file = values.items[prop.file] ?? prop.file;
                    }
                    const present = item.present;
                    for (const vehicled of [
                        item,
                        present,
                        present.ingress,
                        present.egress,
                        ...Object.values(present.items ?? []),
                    ]) {
                        if (!vehicled?.vehicle) continue;
                        if (typeof vehicled.vehicle === "string") {
                            vehicled.vehicle =
                                values.items[vehicled.vehicle] ??
                                autocreate(vehicled.vehicle, vehicled.scope);
                        }
                    }

                    if (item.warned?.latest) {
                        item.warned.latest = values.items[item.warned.latest] ?? item.warned.latest;
                    }
                }

            }

            //   for (const [k, v] of Object.entries(values.detections?.items || {})) {
            //     const item = values.detections.items[k] = values.items[v] || values.items[k] || v;
            //     if (item.file) item.file = values.items[item.file] || item.file;

            //   }

            return values;

        });

    return json;
}
export async function fetchVehiclesEnforcePlaced(scope, valid) {
    if (!scope) return null;
    const json = await Promise.all([
        fetch(
            `${base()}/vehicles/enforce/placed?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
                valid ?? ""
            )}&${authHeader}`
        ),
    ])
        .then((values) => Promise.all(values.map((res) => res.json())))
        .then((values) => Object.assign({}, ...values))
        .then((values) => {


            //logger('items=', values.items);
            for (const [k1, v1] of Object.entries((values.vehicles?.enforce?.for ?? {}) as Record<string, any>)) {

                var target = v1;
                if (json.services) {
                    target.services = json.services;
                    for (const [k2, v2] of Object.entries(json.services.items)) {
                        target.services.items[k2] = json.items[v2 as string] ?? v2;
                    }
                }

                for (const item of Object.values(target.items)) {
                    item.property = json.items[item.scope] ?? item.property;
                    item.present.item = json.items[item.present.item] ?? item.present.item;
                    if (item.present.item.file) item.present.item.file = json.items[item.present.item.file] ?? item.present.item.file;
                    item.vehicle = json.items[item.vehicle] ?? item.vehicle;
                    if (item.warned?.items) Object.entries(item.warned.items).reduce((result, [k, v]) => {
                        result[k] = json.items[v] ?? v;
                        return result;
                    }, item.warned.items);
                    if (item.permitted?.items) Object.entries(item.permitted.items).reduce((result, [k, v]) => {
                        v = result[k] = json.items[v] ?? v;
                        if (v.source) v.source = json.items[v.source] ?? v.source;
                        if (v.vehicle) v.vehicle = json.items[v.vehicle] ?? v.vehicle;
                        return result;
                    }, item.permitted.items);
                    if (item.present?.items) Object.entries(item.present.items).reduce((result, [k, v]) => {
                        v = result[k] = json.items[v] ?? v;
                        if (v.file) v.file = json.items[v.file] ?? v.file;
                        return result;
                    }, item.present.items);
                }

            }

            //   for (const [k, v] of Object.entries(values.detections?.items || {})) {
            //     const item = values.detections.items[k] = values.items[v] || values.items[k] || v;
            //     if (item.file) item.file = values.items[item.file] || item.file;

            //   }

            return values;

        });

    return json;
}