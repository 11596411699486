<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { infotag, type Infotag } from "$utils/infotag";
  import UrlBarcodeScanner from "./UrlBarcodeScanner.svelte";
  import { event } from "$utils/track";

  export let selected = null;
  export let property = null; // property gates observation flag?

  const dispatch = createEventDispatcher<{
    infotag: Infotag;
  }>();

  function select(value: Infotag) {
    //logger("infotag scanner value=", value);

    selected = value;
    event(
      "infoscanned",
      "InfoTagScanner",
      value.url ??
        `${value.type}.${value.version},${value.ref},${value.id},${value.serial}`
    );
    if (selected) dispatch("infotag", value);
  }

  function onbarcode(e: CustomEvent<URL>) {
    const url = e.detail;

    //logger("url=", url);

    const info = infotag.parse(url);
    logger("infotag=", info);
    if (!info) return;

    //info.url = url.href;
    return select(info);
  }
</script>

<UrlBarcodeScanner on:url={onbarcode}><slot /></UrlBarcodeScanner>
