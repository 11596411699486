<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import TextInputField from "$components/form/TextField.svelte";
  import { fetchSend } from "$utils/api";
  import ItemSent from "$components/item/ItemSent.svelte";
  import { comparer, dateDesc } from "$utils/sort";
  import Time from "../Time.svelte";

  export let item: Typed;
  export let property: Property;
  export let cancel = true;
  export let email = true;
  export let tel = false;

  //$: logger("notify item=", item, property);

  export let values: Record<string, any> = {};

  $: timezone = item.timezone;

  $: if (!!property?.send?.sms) tel = true; // read from property

  $: attached = item?.attached?.items ?? item?.items ?? {};
  //$: logger("attached=", items);

  $: items = Object.values(attached)
    .filter((i) => i.type == "sent")
    .sort(comparer("sent.utc", dateDesc));

  const events = createEventDispatcher();

  let editing: Typed = item;
  let submitting = false;
  let submittable = false;
  let form: HTMLFormElement;

  // I'm not sure we ever need to update this, values will handle any changes
  //$: if (!values || !editing) editing = permit;

  $: if (!values._init && form) {
    form.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    values._init = true;
  }

  async function onsubmit(e: SubmitEvent) {
    e.preventDefault();
    if (!submittable || submitting) return;
    e.preventDefault();

    // preprocess
    const form = e.target as HTMLFormElement;
    const formdata = new FormData(form);

    submitting = true;

    // do the stuff
    return fetchSend(editing, formdata)
      .then(function (json) {
        if (json.message) alert(json.message);
        else {
          var items = Object.values(json.send?.created ?? [])
            .map(
              (value: string) => json.items[value] ?? json.send.items?.[value]
            )
            .reduce((result, item) => {
              result[item.id] = item;
              return result;
            }, {});

          if (!item.attached) item.attached = {};
          if (!item.attached.items) item.attached.items = {};
          for (const [key, value] of Object.entries(items))
            item.attached.items[key] = value;

          events("complete", item);
          form.reset();
        }
      })
      .catch(function (error) {
        alert("Whoops, something went wrong!");
      })
      .then(function () {
        // cleanup
        submitting = false;
      }); // how do we handle statuses here?
  }

  function onreset(e) {
    events("cancel", editing);
  }

  function updateSubmittable(form) {
    submittable =
      !!form?.checkValidity() && (values.to || values.tel || values.email);
  }

  function change(name, value) {
    values[name] = value;
    events("change", values);
  }

  function validate(e) {
    //logger("onchange=", e.target.name, e.target.value, e);
    updateSubmittable(e.target.form); // additional logic?
  }

  $: form && updateSubmittable(form);

  //$: logger("edits=", values, editing);

  //$: values && events("change", values);
</script>

<!-- <ItemSent {item} /> -->
<form
  bind:this={form}
  class="send"
  on:submit={onsubmit}
  on:input={validate}
  on:change={validate}
  on:reset={onreset}
  novalidate
>
  <input type="hidden" name="container" value={item.id} />
  <header><h1>Notifications:</h1></header>
  <ul>
    {#each items as item}
      <li>
        <figure class="sent">
          {#each item.email as email}
            <a href="mailto:{email}">{email}</a>
          {/each}
          {#each item.tel as tel}
            <a href="tel:{tel}">{tel}</a>
          {/each}
          {#if item.viewed}
            <data value="delivered" title={item.viewed}><dfn>viewed</dfn></data>
            <!-- <Time datetime={item.viewed} {timezone} definition="Viewed" /> -->
          {:else if item.delivered}
            <data value="delivered" title={item.delivered}><dfn>sent</dfn></data
            >
            <!-- <Time datetime={item.delivered} {timezone} definition="Delivered" /> -->
          {:else if item.sent}
            <data value="sent" title={item.sent}><dfn>sent</dfn></data>
            <!-- <Time datetime={item.sent.utc} {timezone} definition="Sent" /> -->
          {/if}
        </figure>
        <!-- {#if item.viewed}
        <Time datetime={item.viewed} {timezone} definition="Viewed" />
      {/if}
      {#if item.delivered}
        <Time datetime={item.delivered} {timezone} definition="Delivered" />
      {/if}
      {#if item.sent}
        <Time datetime={item.sent.utc} {timezone} definition="Sent" />
      {/if} -->
      </li>
    {:else}
      <li>
        <figure class="sent">
          <data class="sent" value="0">Not sent to anyone yet</data>
        </figure>
      </li>
    {/each}
    <!-- {#if email}
    <li>
      <TextInputField
        label="Email"
        name="to"
        type="email"
        value={values.email}
        on:change={(e) => change("email", e.detail.value)}
      />
    </li>
    {/if}
    {#if tel}
    <li>
      <TextInputField
        label="Phone"
        name="to"
        type="tel"
        value={values.tel}
        on:change={(e) => change("tel", e.detail.value)}
      />
    </li>
    {/if} -->
    {#if email || tel}
      <li>
        <TextInputField
          label="Send to"
          name="to"
          type={email && tel ? "text" : email ? "email" : "tel"}
          required
          placeholder={email && tel
            ? "email or phone"
            : email
              ? "email"
              : "phone"}
          value={values.to}
          on:change={(e) => change(e.detail.name, e.detail.value)}
        />
      </li>
    {/if}
  </ul>
  <footer>
    <fieldset>
      <button disabled={!submittable || submitting} type="submit"
        >{submitting ? "Sending…" : "Send"}</button
      >
      {#if cancel && !submitting}
        <button type="reset">Done</button>
      {/if}
    </fieldset>
  </footer>
</form>
