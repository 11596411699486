import { param } from "$utils/params";
import { spaces } from "$utils/propertystores";
import { derived, type Readable } from "svelte/store";

export const level = derived<[Readable<string>, typeof spaces], string>(
  [param("level", true), spaces],
  ([$level, $spaces]) => {
    logger("level=", $level, $spaces);
    if (!$level && !$spaces) return null;
    if (null != $level) return $level;
    return Object.values($spaces.items ?? {}).reduce((level: string, space) => {
      if (null == space.level) return level;
      if (isNaN(parseFloat(space.level))) return level;
      if (isNaN(parseFloat(level))) return space.level;
      // pick the one closest to zero
      if (
        Math.abs(0 - parseFloat(space.level)) < Math.abs(0 - parseFloat(level))
      )
        return space.level;
      return level;
    }, $level ?? "");
    //if(!level)

    //return $level;
  }
);

level.subscribe(($level) => logger("$level=", $level));
