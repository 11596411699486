<script>
  import { intervalToDuration } from "date-fns";
  import { title as proper } from "$utils/text";
  import { onDestroy, onMount } from "svelte";
  import { help } from "$utils/help";
  import integrations from "$utils/integrations";
  import {
    externalCRMStatusData,
    externalCRM,
    unitTypes,
  } from "$utils/propertystores";
  import Time from "$components/Time.svelte";
  import UnitStatus from "$components/UnitStatus.svelte";
  import UnitTenants from "$components/UnitTenants.svelte";
  import ServiceConnectedRecordItem from "$components/service/ServiceConnectedRecordItem.svelte";

  export let property;
  export let units;
  export let tenants;

  // $: tenantsByUnit = (tenants && tenants.reduce((result, item) => {
  //     const list = result[item.subject] = (result[item.subject] || []);
  //     list.push(item);
  //     return result;
  // }, {})) || {};

  // $: unitsByKey = (units && units.reduce((result, item) => {
  //     result[item.key] = item;
  //     return result;
  // }, {})) || {};

  //$: logger("unitsbykey=", unitsByKey);

  // onMount(function() {
  //     document.documentElement.setAttribute("data-records", "units");
  // })
  // onDestroy(function() {
  //     if(document.documentElement.getAttribute("data-records") == "units") document.documentElement.removeAttribute("data-records");
  // });
</script>

<!-- <section data-records="units">

    <ul>
        {#if units}
        {#each units as unit}
        <li class="{unit.type} {unit.format}">
            <h1><data class="id {[ unit.type, unit.format, unit.size ].filter(s=>!!s).join(" ")}" value="{unit.id}">{unit.display}</h1>
            <button type="button">Move Out</button>
            <ol>
                {#each (tenantsByUnit[unit.id] || []).sort((a, b) => a.valid.utc - b.valid.utc) as tenant}
                    <li>
                    {#if tenant.valid.utc.split("/")[1]}
                    <time datetime="{tenant.valid.utc.split("/")[1]}">Ended: {display(utcToZoned(tenant.valid.utc.split("/")[1], unit.timezone))}</time>
                    {/if}
                </li>
                <li>
                    <time datetime="{tenant.valid.utc.split("/")[0]}">Started: {display(utcToZoned(tenant.valid.utc.split("/")[0], unit.timezone))}</time>
                </li>
                {/each}
            </ol>
        </li>
        {/each}
        {/if}
    </ul>
    
</section> -->
<section class="units" class:status={$externalCRM?.enabled}>
  <header>
    {#if !property}
      <h1>Loading&hellip;</h1>
    {:else}
      <h1>Units</h1>
      <dl>
        <dt>Types</dt>
        <dd>
          <ul>
            {#each $unitTypes as type}
              <li>{proper(type)}</li>
            {/each}
          </ul>
        </dd>

        <dt>Property Sync</dt>
        <dd>
          {#if null == $externalCRM}
            Loading&hellip;
          {:else if $externalCRM?.enabled}
            <ServiceConnectedRecordItem
              item={{
                service: $externalCRM.service,
                name: "units",
                logo: true,
                record: "Syncing updates",
              }}
            />
          {:else}
            Not configured
          {/if}
        </dd>
      </dl>
    {/if}
    <dl>
      <dt>Synced</dt>
      <dd><Time datetime={$externalCRMStatusData?.generated} /></dd>
    </dl>
    <aside class="help">
      <p>
        <a
          on:click={help}
          href="mailto:help@communityboss.com?subject=Units&body=I'd like to learn more about my unit setup:"
          >I'd like to learn more</a
        >
      </p>
    </aside>
  </header>
  {#if null == $externalCRM}
    <figure />
  {:else if $externalCRM?.enabled}
    <UnitStatus {property} data={$externalCRMStatusData} />
  {:else}
    <UnitTenants />
  {/if}
</section>
