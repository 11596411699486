import { derived, writable } from "svelte/store";
import { param } from "./params";

export const camera = writable({});
export const view = param("view");

export const minimal = derived(
  param("minimal", true),
  ($minimal) => $minimal == "true"
);

//view.subscribe($view => logger("view=", $view));
