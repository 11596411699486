import { writable, type Readable, readable, derived } from "svelte/store";

interface Bosse {
  disconnect: () => void;
  source: Readable<EventSource | null>;
  state: Readable<number>;
  connect: (params?: Record<string, string>) => Bosse;
  connected: Readable<boolean>;
  connecting: Readable<boolean>;
  params?: Readable<Record<string, string>>;
}

function bosse(params?: Record<string, string>): Bosse {
  //let subscribedPropertyId = null;

  let eventsource: EventSource | null = null;
  let source = writable<EventSource | null>(null);
  const sourceder = derived(source, ($source) => $source);

  const state = writable(2); // default to closed
  const stateder = derived(state, ($state) => $state);
  const connected = derived<[typeof source, typeof state], boolean>([source, state], ([$source, $state]) => !!$source && $state === 1);
  const connecting = derived<[typeof source, typeof state], boolean>([source, state], ([$source, $state]) => !!$source && $state === 0);

  const pstore = writable<Record<string, string>>(params ?? {});
  const pder = derived(pstore, ($pstore) => $pstore ?? {});

  function onerror(e: Event) {
    console.error("EventSource failed:", e);
    state.set(eventsource?.readyState ?? 2);
  }

  function onopen(e: Event) {
    logger("EventSource connected", e);
    state.set(eventsource?.readyState ?? 2);
  }

  function disconnect() {
    if (null != eventsource) {
      eventsource.close();
      state.set(eventsource.readyState);
      eventsource.removeEventListener("error", onerror);
      eventsource.removeEventListener("open", onopen);
      source.set((eventsource = null));
    }
  }

  // reconnect logic?
  function connect(params?: Record<string, string>): Bosse {
    // cleanup

    pstore.set(params ?? {});
    disconnect();

    if (params && Object.keys(params ?? []).length > 0) {

      const url = new URL("https://bosse.communityboss.app/");
      for (const [id, type] of Object.entries(params)) {
        url.searchParams.append(type, id);
      }
      source.set((eventsource = new EventSource(url)));
      state.set(eventsource.readyState ?? 2);

      eventsource.addEventListener("error", onerror);
      eventsource.addEventListener("open", onopen);

    }

    return {
      state: stateder,
      source: sourceder,
      connect,
      connected,
      connecting,
      disconnect,
      params: pder,
    };
  }

  return connect(params);
}
export { bosse };
export default bosse;
