<script lang="ts">
  import ItemsList from "$components/item/ItemsList.svelte";
  import Time from "$components/Time.svelte";
  import ServiceConnectedRecordItem from "$components/service/ServiceConnectedRecordItem.svelte";
  import SpaceDetailsItem from "$components/space/SpaceDetailsItem.svelte";
  import { help } from "$utils/help";
  import { externalCRM, externalCRMRentables } from "$utils/propertystores";
  import { comparer } from "$utils/sort";
  import { title } from "$utils/text";
  import Loading from "$components/Loading.svelte";
  import RentableRecordItem from "$components/rentable/RentableRecordItem.svelte";
  import RentableUnlinkedItem from "$components/rentable/RentableUnlinkedItem.svelte";
  import orderBy from "lodash-es/orderBy";
  import { sortBy } from "$utils/sort";

  export let property: Property;
  export let spaces: Spaces;
  export let availability: AvailabilityFor;

  $: logger("availability=", availability);

  $: types = Object.values(spaces?.items ?? {}).reduce(
    (types: string[], item: Space) => {
      if (!types.includes(item.format)) types.push(item.format);

      return types;
    },
    []
  );
  $: linkedRentables =
    $externalCRMRentables &&
    Object.values($externalCRMRentables)?.filter(
      (rentable) => rentable.subject?.id
    );
  $: unlinkedRentables =
    $externalCRMRentables &&
    Object.values($externalCRMRentables)?.filter(
      (rentable) => !rentable.subject?.id
    );
</script>

<section class="spaces">
  <header>
    {#if property}
      <h1>Spaces</h1>
      <dl>
        <dt>Types</dt>
        <dd>
          <ul>
            {#each types as type}
              <li>{title(type)}</li>
            {/each}
          </ul>
        </dd>

        <dt>Property Sync</dt>
        <dd>
          {#if null == $externalCRM}
            Loading&hellip;
          {:else if $externalCRM?.enabled}
            <ServiceConnectedRecordItem
              item={{
                service: $externalCRM.service,
                name: "units",
                logo: true,
                record: "Syncing updates",
              }}
            />
          {:else}
            Not configured
          {/if}
        </dd>
      </dl>
      <dl>
        <dt>Synced</dt>
        <dd><Time datetime={spaces?.generated} /></dd>
      </dl>
    {:else}
      <h1>Loading&hellip;</h1>
    {/if}
    <aside class="help">
      <p>
        <a
          on:click={help}
          href="mailto:help@communityboss.com?subject=Spaces&body=I'd like to learn more about my spaces:"
          >I'd like to learn more</a
        >
      </p>
    </aside>
  </header>
  <section>
    <header>
      <h1>Configured</h1>
    </header>
    <ItemsList
      class="items"
      items={spaces &&
        Object.values(spaces.items)
          .map((space) => ({
            ...space,
            availability:
              availability?.for?.[space.id] ??
              (availability?.interval && {
                interval: availability?.interval,
                timezone: space.timezone,
                intervals: {},
              }),
          }))
          .sort(comparer("display"))}
      types={{
        space: SpaceDetailsItem,
      }}
    />
  </section>
  <section>
    <header><h1>Rentables</h1></header>
    {#if linkedRentables}
      <ItemsList
        class="rentable items"
        items={sortBy(linkedRentables, "subject.display")}
        types={{
          rentable: RentableRecordItem,
        }}
        highlight={{
          //rentable: true,
        }}
      />
      <!-- {#each sortBy(Object.entries(groupBy($externalRentables, "category.name")), 0) as [name, rentables]}
      <header><h1>{name} Rentables</h1></header>
      <ItemsList
        class="rentable activity"
        items={sortBy(rentables, "name")}
        types={{
          rentable: RentableItem,
        }}
        highlight={{
          rentable: true,
        }}
      />
    {/each} -->
    {:else}
      <Loading />
    {/if}
    {#if unlinkedRentables}
      <ItemsList
        class="rentable items"
        items={orderBy(unlinkedRentables, ["category.name", "name"])}
        loading="Checking unlinked rentables"
        types={{
          rentable: RentableUnlinkedItem,
        }}
        highlight={{
          //rentable: true,
        }}
      />
    {:else}
      <Loading />
    {/if}
  </section>
</section>
