<script>
  import Record from "$components/record/RecordItem.svelte";
  import { sortBy } from "$utils/sort";
  import { format, toZonedTime } from "date-fns-tz";
  import { title as proper } from "$utils/text";
  import { fetchResetUnit as update } from "$utils/api";
  import { tick } from "svelte";
  import { event } from "$utils/track";

  export let item;
  //$: logger("item=", item);

  let submitting = false;

  //$: logger('tenantsByUnit=', $tenantsByUnit)

  let expand = false;

  function toggle(id) {
    expand = !expand;
  }
</script>

<article class={item.type}>
  <header>
    <h1>
      <Record item={item.subject} />
    </h1>
    <a
      href="/properties/{item.subject.scope}/{item.subject.type}/{item.subject
        .id}">Details</a
    >
  </header>

  <ol>
    {#if item.tenants}
      {#each sortBy(Object.values(item.tenants), "valid.utc").reverse() as tenant, i}
        {#if i <= 1 || expand}
          <li>
            {#if i > 0}
              <a href="/properties/{tenant.scope}/{tenant.type}/{tenant.id}"
                >View History</a
              >
            {/if}
            <time
              class="tenant"
              class:init={i == Object.values(item.tenants).length - 1}
              datetime={tenant.valid.utc}
              >{format(
                toZonedTime(tenant.valid.utc.split("/")[0], tenant.timezone),
                "EEE MMM d yyyy h:mm a",
                { timeZone: tenant.timezone }
              )}</time
            >
          </li>
        {/if}
      {/each}
    {/if}
  </ol>

  {#if item.tenants && Object.values(item.tenants).length > 2}
    <button
      class="expand"
      class:active={expand}
      type="button"
      on:click={(e) => toggle(item.subject.id)}
      >{expand ? "Less" : "More"}</button
    >
  {/if}
</article>
