<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { title as proper } from "$utils/text";
  import NotesField from "$components/form/NotesField.svelte";

  import { fetchContactCreate } from "$utils/api";
  import NameField from "$components/form/NameField.svelte";
  import EmailField from "$components/form/EmailField.svelte";
  import TelField from "$components/form/TelField.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import TenantField from "$components/form/TenantField.svelte";
  import VehicleField from "$components/form/VehicleField.svelte";
  import SelectField from "$components/form/SelectField.svelte";
  import Option from "$components/form/Option.svelte";

  export let record: Vehicle | Tenant = null;
  export let cancel = true;

  export let values: Record<string, any> = {};

  let active = false;
  let submittable = false;
  let submitting = false;
  let form: HTMLFormElement;

  // $: label = proper(record?.title || record?.format || record.type);

  const events = createEventDispatcher<{
    create: Contact;
    change: typeof values;
    cancel: typeof record;
  }>();

  async function onsubmit(e: SubmitEvent) {
    if (!submittable || submitting) return;
    e.preventDefault();

    // preprocess
    const form = e.target as HTMLFormElement;
    const formdata = new FormData(form);

    submitting = true;

    //post
    return fetchContactCreate(formdata)
      .then(function (json) {
        switch (json.status) {
          default:
            const items = json.contacts?.for?.[record?.id];
            const item = json.contacts?.item;
            events("create", item);
            form.reset();
            //policy = null;
            break;
        }
      })
      .catch(function (error) {
        logger("error=", error);
        alert("Whoops, something went wrong!");
      })
      .then(function () {
        // cleanup
        submitting = false;
        //permittablesUpdated(true);
      }); // how do we handle statuses here?
  }
  function updateSubmittable(form) {
    submittable = !!form?.checkValidity();
  }

  function validate(e) {
    //logger("onchange=", e.target.name, e.target.value, e);
    updateSubmittable(e.target.form); // additional logic?
  }

  function change(name, value) {
    values[name] = value;
    events("change", values);
  }
  function onreset(e) {
    active = false;
    events("cancel", record);
  }
</script>

{#if record && active}
  <form
    bind:this={form}
    class="contact"
    on:submit={onsubmit}
    on:input={validate}
    on:change={validate}
    on:reset={onreset}
    novalidate
  >
    <slot />
    <!-- <input type="hidden" name="scope" value={record.scope} /> -->
    <input type="hidden" name="subject" value={record.id} />
    <ul>
      <li>
        {#if "tenant" === record?.type}
          <TenantField predefined value={record} required readonly />
        {:else if "vehicle" === record?.type}
          <VehicleField value={record} required readonly />
        {/if}
      </li>
      <li>
        <fieldset>
          <SelectField label="Type" name="relationship" value="">
            <Option value="">n/a</Option>
            <Option value="occupant">occupant</Option>
            <Option value="resident">resident</Option>
            <Option value="employee">employee</Option>
            <Option value="owner">owner</Option>
          </SelectField>
        </fieldset>
      </li>
      <li>
        <NameField
          required={true}
          label="Name"
          value={values.name ?? ""}
          autocomplete="off"
          on:change={(e) => change(e.detail.name, e.detail.value)}
        />
      </li>
      <li>
        <EmailField
          label="Email"
          value={values.email ?? ""}
          autocomplete="off"
          on:change={(e) => change(e.detail.name, e.detail.value)}
        />
      </li>
      <li>
        <TelField
          label="Phone"
          value={values.tel ?? ""}
          autocomplete="off"
          on:change={(e) => change(e.detail.name, e.detail.value)}
        />
      </li>
      <!-- <li>
        <NotesField
          required={true}
          label="Notes"
          value={values.notes ?? ""}
          on:change={(e) => change(e.detail.name, e.detail.value)}
        />
      </li> -->
    </ul>

    <footer>
      <fieldset>
        <button disabled={!submittable || submitting} type="submit"
          >{submitting ? "Saving…" : `Add Person`}</button
        >
        {#if cancel && !submitting}
          <button type="reset">Cancel</button>
        {/if}
      </fieldset>
    </footer>
  </form>
{:else if record}
  <!-- <nav>
    <ul>
      <li> -->
  <button type="button" class="contact" on:click={(e) => (active = true)}
    >Add person…</button
  >
  <!-- </li>
    </ul>
  </nav> -->
{/if}
