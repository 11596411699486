<script lang="ts">
  export let active = false;
  // export let autofocus = false;
  //export let escapeFullscreen;

  function onkeydown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      active = false;
      //logger("event.key", event.key);
    }
  }
</script>

<svelte:window on:keydown={active && onkeydown} />
{#if active}
  <div class="fullscreen">
    <slot />
  </div>
{:else}
  <slot />
{/if}
