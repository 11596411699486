<script lang="ts">
  import { toZonedTime, format } from "date-fns-tz";
  import identity from "lodash-es/identity";
  import Record from "$components/record/RecordItem.svelte";
  import { title as proper } from "$utils/text";
  import Time from "../Time.svelte";
  export let item: Permittable = null;
  export let record = null;

  //$: logger("item=", item);
</script>

<aside
  class="{item.type} summary"
  class:donotpermit={item.permittable === false}
  data-record="permittables/{item.id}"
>
  <header>
    <h1>
      <dfn>{item.permittable === false ? "Banned" : "Exempted"}</dfn>
    </h1>
  </header>

  <!-- <time datetime={item.issued.datetime}>
    <abbr
      >{format(
        toZonedTime(item.issued.datetime, item.timezone),
        "h:mm a MMM d yyyy zzz",
        { timeZone: item.timezone }
      )}</abbr
    >
    {#each [item.issued.by]
      .filter((item) => item && item.id)
      .slice(0, 1) as user}
      <a href="mailto:{user.email}"
        ><data class="user {user.type}" value={user.id}
          ><dfn>by</dfn> {user.name}</data
        ></a
      >
    {/each}
  </time> -->
  <!-- <ul class="subjects">
    {#each [item.subject, item.vehicle, item.space, item.media, item.tenant].filter((item) => !!item && item.id !== (record?.id || record)) as subject}<li
      >
        <Record item={subject} />
      </li>{/each}
  </ul> -->
  <dl>
    {#each [item.subject, item.vehicle, item.space, item.media, item.tenant].filter((item) => !!item && item.id !== (record?.id || record)) as subject}
      <dt>{proper(subject?.format || subject.type)}</dt>
      <dd><Record item={subject} /></dd>
    {/each}
    <dt>Recorded</dt>
    <dd><Time datetime={item.issued.datetime} timezone={item.timezone} /></dd>
    {#each [item.issued.by]
      .filter((item) => item && item.id)
      .slice(0, 1) as user}
      <dd>
        <a href="mailto:{user.email}"
          ><data class="user {user.type}" value={user.id}
            ><dfn>by</dfn> {user.name}</data
          ></a
        >
      </dd>
    {/each}
    <dt>Reason</dt>
    <dd>
      {item.description || ""}
    </dd>
  </dl>
  <!-- <ul class="notes">
        {#each [ item.description ].filter(identity) as text}
            <li>
            <blockquote class="note">
                <p>{text}</p>
                <cite>
                    {#each [item.issued.by, item.issued.user].filter(item => item && item.id).slice(0, 1) as user}
                    <a href="mailto:{user.email}"><data class="user {user.type}" value="{user.id}"><dfn>by</dfn> {user.name}</data></a>
                    {/each}
                </cite>
            </blockquote>
            </li>
        {/each}
    </ul> -->
  <slot />
</aside>
