<svelte:options runes={true} />

<script lang="ts">
  import Loading from "$components/Loading.svelte";
  import type { Snippet } from "svelte";
  import { zoned } from "../stores";
  import IngressEgressComponent from "./IngressEgress.svelte";
  import { comparer, dateDesc } from "$utils/sort";

  const { vehicle = null } = $props<{ vehicle: Vehicle }>();
  const { scope, valid, result } = zoned();

  $effect(() => {
    $valid = `${Temporal.Now.instant().subtract({ hours: 24 * 30 })}/${Temporal.Now.instant()}`;
    $scope = vehicle?.id;
  });

  let unpermitted = $derived(
    $result && [
      ...Object.values($result.items)
        .filter(
          (item) =>
            item.present.egress &&
            !item.unpermitted.grace.pending &&
            item.unpermitted.status
        )
        .sort(comparer("present.egress.sensor.datetime", dateDesc)),
      ...Object.values($result.items)
        .filter(
          (item) =>
            !item.present.egress &&
            !item.unpermitted.grace.pending &&
            item.unpermitted.status
        )
        .sort(comparer("present.ingress.sensor.datetime", dateDesc)),
    ]
  );
</script>

{#snippet Items(items: null | any[], renderer: Snippet<[any]>)}
  {#if null == items}
    <Loading />
  {:else}
    <ol class="info">
      {#each items as item}
        <li>{@render renderer(item)}</li>
      {/each}
    </ol>
  {/if}
{/snippet}

{#snippet IngressEgress(item: any)}
  <IngressEgressComponent {item} />
{/snippet}

<section>
  <header>
    <h1>Monitor & Detect <dfn class="preview">Preview</dfn></h1>
  </header>
  {@render Items(unpermitted, IngressEgress)}
</section>
