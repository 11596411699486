<svelte:options runes />

<script lang="ts">
  import { title } from "$utils/text";
  import { thumbnail } from "$utils/filepreview";
  import EntryKeypadItem from "./EntryKeypadItem.svelte";
  import { directory as services } from "./service";

  const { item } = $props<{ item: any }>();

  //export let item = null;
  //$: logger("entry=", item);

  let provider = $derived(services.get(item?.provider) ?? item.provider);

  async function remove(e: Event) {
    e.preventDefault();
    logger("remove", item);
    if (confirm("Are you sure you want to remove this item?")) {
      //await fetch(`/api/entry/${item.id}`, { method: "DELETE" });
      //location.reload();
    }
  }

  $effect(() => {
    logger("entry=", item, provider);
  });
</script>

{#if item}
  <article class="details {item.type}">
    <header>
      <h1>
        {[
          item.name,
          //item.keypad?.code?.length && "Entry Code",
          //item.remote?.enabled && `${proper(item.action)} Button`,
          title(item.entry || item.action),
          //item.door && "Door",
          //item.barrier,
        ]
          .filter((i) => null != i)
          .join(" • ")}
      </h1>
    </header>
    {#if item.principal !== item.scope}
      <nav>
        <h1>Options</h1>
        <ul>
          <li>
            <button type="button" class="remove" onclick={remove}>Remove</button
            >
          </li>
        </ul>
      </nav>
    {/if}
    {#if item?.hardware?.id}
      <dl>
        <dt>Hardware</dt>
        <dd>{item.hardware.id}</dd>
      </dl>
    {/if}
    <!-- <p>{item.target}</p> -->
    {#if item.photo}
      <figure>
        <img alt="preview" src={thumbnail(item.photo.url, 500, 250, true)} />
      </figure>
    {/if}
    <!-- <p>{item.target}</p>
    <dl>
      <dt>ID</dt>
      <dd><data class="target id" value={item.target}>{item.target}</data></dd>
    </dl> -->
    <ul>
      {#if true == item.remote?.enabled}
        <li>
          <h2>
            <data value="remote">Live {title(item.action)} Button</data>
          </h2>
        </li>
      {/if}

      {#if true == item.keypad?.dynamic}
        <li><h2><data value="keypad">Auto-generated Entry Codes</data></h2></li>
      {/if}
      {#if item.keypad?.code?.length}
        <li>
          <h2><data value="keypad">Shared Entry Code</data></h2>
          <EntryKeypadItem item={item.keypad} />
        </li>
      {/if}
    </ul>
    {#if provider?.service}
      <img
        alt="{provider.name} logo"
        class="service logo"
        src="/{provider.service}.svg"
      />
    {/if}
  </article>
{/if}
