<script context="module">
  const formatter =
    Intl.ListFormat &&
    new Intl.ListFormat("en", { style: "long", type: "conjunction" });
  function list(list) {
    if (formatter) return formatter.format(list);
    return list.join(", ");
  }
  // expected output: "Motorcycle, Bus, and Car"
</script>

<script>
  import { record } from "$utils/filter";
  import identity from "lodash-es/identity";
  import { format, toZonedTime } from "date-fns-tz";
  import Record from "$components/record/RecordItem.svelte";
  import Time from "./Time.svelte";
  export let item = null;
  //$: logger("suspicious=", item);
</script>

{#if item}
  <article class="{item.type} details" data-id={item.permit.id}>
    <header>
      <h1>
        <dfn
          >Suspicious <data class="permit" value={item.permit.id}
            >{item.permit.policy?.title || item.permit.title}</data
          ></dfn
        >
      </h1>
    </header>
    <dl>
      <dt>For</dt>
      <dd>
        <ul class="subjects">
          {#each [item.permit && item.permit.vehicle, item.permit && item.permit.tenant].filter(record) as item}
            <li><Record {item} /></li>
          {/each}
        </ul>
      </dd>
      <dt>Recorded</dt>
      <dd><Time datetime={item.utc} timezone={item.timezone} /></dd>
      <dd><data class="user" value="system">Smart Guard</data></dd>
      <dt>Similar</dt>
      <dd>
        <ul class="similar">
          {#each Object.values(item.evaluated).slice(1) as evaluated}
            <li>
              {#each [evaluated.subject].filter(record) as item}
                <Record {item} />
              {/each}
              <p>
                {list(
                  [
                    evaluated.conflict && "was blocked",
                    false && evaluated.quota && "had maxed out usage",
                    evaluated.continuous && "was assigned",
                    evaluated.donotpermit && "was banned",
                  ].filter(identity)
                )}
              </p>
            </li>
          {/each}
        </ul>
      </dd>
    </dl>

    <!-- <time datetime={item.utc}
      ><abbr
        >{format(
          toZonedTime(item.utc, item.timezone),
          "h:mm a MMM d yyyy zzz",
          { timeZone: item.evaluated[0].timezone }
        )}</abbr
      ><data class="user" value="system">Smart Guard</data></time
    > -->
    <!-- <h1>Suspicious Activity</h1> -->
    <!-- <ul class="subjects">
      {#each [item.permit && item.permit.vehicle, item.permit && item.permit.tenant].filter(record) as item}
        <li><Record {item} /></li>
      {/each}
    </ul>
    <h2>Matched from history:</h2>
    <ul class="similar">
      {#each Object.values(item.evaluated).slice(1) as evaluated}
        <li>
          {#each [evaluated.subject].filter(record) as item}
            <Record {item} />
          {/each}
          <p>
            {list(
              [
                evaluated.conflict && "was blocked",
                false && evaluated.quota && "had maxed out usage",
                evaluated.continuous && "was assigned",
                evaluated.donotpermit && "was banned",
              ].filter(identity)
            )}
          </p>
        </li>
      {/each}
    </ul> -->
    <!-- <footer><time datetime="{item.utc}"><dfn>Flagged on</dfn> {format(toZonedTime(item.utc, item.timezone), "EE MMM d yyyy h:mm a zzz", { timeZone: item.evaluated[0].timezone })}</time></footer> -->
  </article>
{/if}
