<svelte:options runes />

<script lang="ts">
  import Loading from "$components/Loading.svelte";
  import type { Snippet } from "svelte";
  import { policies } from "$utils/propertystores";
  import EntryCapabilityItem from "$components/EntryCapabilityItem.svelte";
  import PolicySummaryItem from "$components/PolicySummaryItem.svelte";
  import ItemsList from "$components/item/ItemsList.svelte";

  const { property = null, title = null } = $props<{
    property: Property | null;
    title?: string;
  }>();
  //const { scope, result } = scoped();

  // $effect(() => {
  //   $scope = property?.id;
  //   logger("$result=", $result);
  // });
</script>

{#snippet Items(items: null | any[], renderer: Snippet<[any]>)}
  {#if null == items}
    <Loading />
  {:else if items.length}
    <ol class="info">
      {#each items as item}
        <li>{@render renderer(item)}</li>
      {/each}
    </ol>
  {:else}
    <aside class="empty history">There is no automated access</aside>
  {/if}
{/snippet}

{#snippet Sensor(item: any)}
  <EntryCapabilityItem {item} />
{/snippet}

<section>
  <header><h1>{title || "Automated Access Policies"}</h1></header>
  {#each Object.values($policies || {}).filter((i) => i.access || i.entry) as policy}
    <ItemsList
      class="activity"
      items={[
        policy,
        ...Object.values(policy.access?.items ?? policy.entry?.items ?? {}),
      ]}
      types={{
        policy: PolicySummaryItem,
        access: EntryCapabilityItem,
        entry: EntryCapabilityItem,
      }}
    />
  {:else}
    <aside class="empty history">
      There are no linked entry access automations
    </aside>
  {/each}
  <!-- {@render Items($result && Object.values($result?.items), Sensor)} -->
</section>
