<script context="module">
  import { scaleTime } from "d3-scale";
  import { time } from "$utils/timestores";
  const nowutc = time({ seconds: 60 });
</script>

<script>
  import { toZonedTime, format } from "date-fns-tz";
  import {
    parseISO,
    eachDayOfInterval,
    addDays,
    endOfDay,
    startOfDay,
    isWeekend,
    eachMonthOfInterval,
    addMonths,
    isSameDay,
  } from "date-fns";

  import { toMilliseconds } from "duration-fns";
  import { stringToDateInterval } from "./item/ItemsList.svelte";

  export let resolution = "PT24H";

  export let interval = null;
  export let permits = null;
  export let violations = null;
  export let timezone = null;
  export let conflicts = null;
  export let enforcement = null;
  export let accessed = null;
  //export let scans;

  //$: logger("permits=", permits);

  $: resolutionms = toMilliseconds(resolution);

  //$: logger('interval=', interval, "timezone=", timezone);

  $: now = toZonedTime($nowutc, timezone);

  //$: min = startOfDay(addDays(now, -30));
  $: max = endOfDay(addDays(now, 1));

  $: min =
    (interval &&
      (interval.startsWith("/")
        ? startOfDay(addDays(now, -365))
        : startOfDay(stringToDateInterval(interval, timezone).start))) ||
    startOfDay(addDays(now, -30));

  $: durationms = max - min;

  $: resolutionpx = 32;
  //$: mspx = 1 / 1000 / 60 / 60 * 2;
  $: timepx = (durationms / resolutionms) * resolutionpx;

  $: days = eachDayOfInterval({ start: min, end: max }).map((d) => [
    d,
    addDays(d, 1),
  ]);

  $: months = eachMonthOfInterval({ start: min, end: max }).map((d) => [
    Math.max(min, d),
    Math.min(addMonths(d, 1), max),
  ]);

  //$: logger("dayranges=", dayRangesLocal);

  $: timescale = scaleTime().domain([min, max]).range([0, timepx]).clamp(true);

  $: width = timepx;
</script>

<time
  class="timeline horizontal"
  datetime="{format(min, "yyyy-MM-dd'T'HH:mm:ss")}/{format(
    max,
    "yyyy-MM-dd'T'HH:mm:ss"
  )}"
>
  {#each months.reverse() as [start, end], i}
    <time
      class="month"
      style="width:{timescale(end) - timescale(start)}px;"
      datetime="{format(start, "yyyy-MM-dd'T'HH:mm:ss")}/{format(
        end,
        "yyyy-MM-dd'T'HH:mm:ss"
      )}"><abbr>{format(start, "MMM yyyy")}</abbr></time
    >
  {/each}
  <svg class="days" {width} height="48">
    {#each days as [start, end], i}
      <rect
        class="day"
        class:today={isSameDay(now, start)}
        class:weekend={isWeekend(start)}
        x={timescale(start)}
        y="0"
        width={timescale(end) - timescale(start)}
        height="100%"
      />
      <line
        class="day"
        x1={timescale(start)}
        x2={timescale(start)}
        y1="0"
        y2="100%"
      />
      {#if isSameDay(start, now)}
        <circle
          class="today"
          cx={timescale(start) + (timescale(end) - timescale(start)) / 2}
          cy="70%"
          r="12"
        />
      {/if}
      <text
        class="day dayofweek"
        class:today={isSameDay(start, now)}
        x={timescale(start) + (timescale(end) - timescale(start)) / 2}
        y="0%">{format(start, "EEEEE")}</text
      >
      <text
        class="day dayofmonth"
        class:today={isSameDay(start, now)}
        x={timescale(start) + (timescale(end) - timescale(start)) / 2}
        y="70%">{format(start, "d")}</text
      >
    {/each}
    <line
      class="now"
      y1="0"
      y2="100%"
      x1={timescale(now)}
      x2={timescale(now)}
    />
  </svg>
  <svg class="permits violations checks" {width} height="64">
    <defs>
      <svg id="violation" width="16" height="16" viewBox="0 0 70 63"
        ><path
          d="m67.476 50.25-25.98-45c-2.888-5-10.104-5-12.991 0l-25.98 45c-2.888 5 .72 11.25 6.494 11.25h51.962c5.773 0 9.382-6.25 6.495-11.25z"
          fill="#fff"
          stroke="#fff"
          stroke-width="3"
        /><path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M40.197 6c-2.31-4-8.083-4-10.393 0L3.824 51c-2.31 4 .577 9 5.196 9h51.96c4.62 0 7.507-5 5.197-9L40.197 6zm-4.78 45.348c1.155 0 2.189-.398 3.065-1.233.836-.796 1.273-1.791 1.273-2.945 0-1.115-.437-2.11-1.273-2.945a4.655 4.655 0 0 0-1.5-.98 4.222 4.222 0 0 0-1.565-.294c-1.153 0-2.188.438-3.064 1.274a4.45 4.45 0 0 0-.943 1.353 3.928 3.928 0 0 0-.33 1.592c0 1.154.438 2.149 1.273 2.944.876.836 1.911 1.234 3.064 1.234zm3.264-23.12c.318-1.354.597-2.548.835-3.582.2-.995.32-1.83.32-2.468 0-1.114-.44-2.109-1.314-2.944-.876-.836-1.91-1.234-3.105-1.234-1.233 0-2.268.398-3.103 1.234-.876.835-1.314 1.83-1.314 2.944 0 .597.12 1.433.359 2.587.137.668.29 1.337.447 2.013l.349 1.49c.318 1.273.676 2.904 1.074 4.814.437 1.91.875 4.298 1.313 7.163h1.751l.074-.43c.445-2.638.824-4.882 1.239-6.733.398-1.95.757-3.541 1.075-4.855z"
          fill="#000"
        /></svg
      >
    </defs>
    {#each days as [start, end], i}
      <rect
        class="day"
        class:today={isSameDay(now, start)}
        class:weekend={isWeekend(start)}
        x={timescale(start)}
        y="0"
        width={timescale(end) - timescale(start)}
        height="100%"
      />
      <line
        class="day"
        x1={timescale(start)}
        x2={timescale(start)}
        y1="0"
        y2="100%"
      />
    {/each}

    {#each Object.values(permits?.items || permits || {}) as permit}
      <rect
        data-valid={permit.valid.interval}
        class="permit"
        class:valid={!(
          parseISO(permit.valid.min.datetime) > $nowutc ||
          (permit.valid.max && parseISO(permit.valid.max.datetime) < $nowutc)
        )}
        height="30%"
        y="35%"
        x={timescale(toZonedTime(permit.valid.min.datetime, permit.timezone))}
        width={permit.valid.max
          ? Math.abs(
              timescale(
                toZonedTime(permit.valid.max.datetime, permit.timezone)
              ) -
                timescale(
                  toZonedTime(permit.valid.min.datetime, permit.timezone)
                )
            )
          : "100%"}
      />
    {/each}

    {#each [...Object.values(accessed?.items || {}), ...Object.values(enforcement?.items || {}).filter((i) => i.type == "accessed")].map( (item) => [item, timescale(toZonedTime(item.datetime, item.timezone))] ) as [item, ts]}
      <line class={item.type} y1="3" y2="45%" x1={ts} x2={ts} />
      <circle class={item.type} cy="3" cx={ts} r="3" />
      <!-- <use transform="translate(-8,-18)" href="#violation" width="16" height="16" y="100%" x="{ts}" /> -->
    {/each}

    {#each Object.values(conflicts?.items || {}).map( (item) => [item, timescale(toZonedTime(item.recorded, item.timezone))] ) as [item, ts]}
      <line class={item.type} y1="30%" y2="70%" x1={ts} x2={ts} />
    {/each}

    {#each [...Object.values(violations?.items || {}), ...Object.values(enforcement?.items || {}).filter((i) => i.type == "violation")].map( (item) => [item, timescale(toZonedTime(item.issued.datetime, item.timezone))] ) as [item, ts]}
      <line class={item.type} y1="55%" y2="90%" x1={ts} x2={ts} />
      <use
        transform="translate(-8,-18)"
        href="#violation"
        width="16"
        height="16"
        y="100%"
        x={ts}
      />
    {/each}
    <line
      class="now"
      y1="0"
      y2="100%"
      x1={timescale(now)}
      x2={timescale(now)}
    />
  </svg>
  <!-- <svg class="now" width="{width}" height="16">
    {#each days as [ start, end ], i}
        <rect class="day" class:weekend={isWeekend(start)} x="{timescale(start)}" y="0" width="{timescale(end) - timescale(start)}" height="100%" />
        <line class="day" x1={timescale(start)} x2={timescale(start)} y1="0" y2="100%" />
    {/each}
    <line class="now"  y1="0" y2="100%" x1="{timescale(now)}" x2="{timescale(now)}" />
    <text class="now" x="{timescale(now)}" y="100%">{format(now, "h:mm a")}</text>
</svg> -->
</time>
