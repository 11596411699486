<svelte:options runes />

<script lang="ts">
  const { service } = $props<{ service: Service | null | undefined }>();
</script>

<img
  alt="{service.name} logo"
  class="service logo"
  src="/{service.service}.svg"
/>
