<script lang="ts">
  import Vehicle from "$components/Vehicle.svelte";
  import NewPolicyPermit from "$components/form/NewPolicyPermit.svelte";

  export let record: any = null;
  export let tenant: Tenant | null = null;
  export let vehicle: Vehicle | null = null;
  export let property: Property | null = null;
  export let policies: PermitIssuePolicy[] | null = null;
  //$: logger(record, policies);
  let policy: any = null;
</script>

{#if record}
  {#if policy}
    <NewPolicyPermit
      {record}
      {tenant}
      {vehicle}
      {property}
      {policy}
      {policies}
      on:cancel={(e) => (policy = null)}
      on:create={(e) => (policy = null)}
    >
      <!-- <header>
        <h1>Adding:</h1>
        <button type="reset">Cancel</button>
      </header> -->
    </NewPolicyPermit>
  {:else}
    <!-- <nav>
      <ul>
        {#each policies as item}
          <li>
            <a
              class:vehicle={item.vehicle?.request}
              class:media={item.media?.request}
              class:space={item.space?.request}
              class:tenant={item.tenant?.request}
              href="{window.location.href}#permits/policies/{item.subject}/new"
              on:click|preventDefault={(e) => (policy = item)}>{item.title}</a
            >
          </li>
        {/each}
      </ul>
    </nav> -->
    <!-- <nav>
      <ul>
        <li> -->
    <select
      class="new permit policies"
      on:change={({ currentTarget: target }) =>
        (policy = policies.find(
          (item) =>
            item.subject === target.value ||
            (!item.subject && item.title === target.value)
        ))}
    >
      {#if policies}
        <option value="">Add&#x2026</option>
        <!-- <optgroup label="Types"> -->
        {#each policies as item}
          <option value={item.subject ?? item.title}>{item.title}</option>
        {/each}
      {:else}
        <option value="">Loading&#x2026</option>
      {/if}
      <!-- </optgroup> -->
    </select>
    <!-- </li>
      </ul>
    </nav> -->
  {/if}
{:else}
  <!-- <nav> -->
  <slot />
  <!-- </nav> -->
{/if}
