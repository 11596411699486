<svelte:options runes />

<script context="module" lang="ts">
  import { help } from "$utils/help";

  import ConnectedServiceItem from "$components/ConnectedServiceItem.svelte";
  import EntryCapabilityItem from "$components/EntryCapabilityItem.svelte";
  import EntryItem from "$components/EntryCapabilityItem.svelte";
  import ItemsList from "$components/item/ItemsList.svelte";
  import PolicySummaryItem from "$components/PolicySummaryItem.svelte";
  import ServiceItem from "$components/service/ServiceItem.svelte";
</script>

<script lang="ts">
  import SensorsSection from "$components/sensor/SensorsSection.svelte";
  import { list as services } from "$components/service";

  import AccessPointsSection from "$components/access/AccessPointsSection.svelte";
  import AccessPolicySection from "$components/access/AccessPolicySection.svelte";

  const { property = null } = $props<{
    property: Property | null;
  }>();
</script>

<section class="integrations">
  <header>
    <h1>Integrations</h1>
    <aside class="help">
      <p>
        <a
          onclick={help}
          href="mailto:help@communityboss.com?subject=Integration&body=I'd like to learn more about integration setup:"
          >I'd like to learn more</a
        >
      </p>
    </aside>
  </header>
  <section>
    <header>
      <h1>Services</h1>
    </header>
    <ItemsList
      class="info"
      items={services}
      types={{
        service: ServiceItem,
      }}
      context={{
        property,
      }}
    />
  </section>
  <AccessPointsSection {property} />
  <AccessPolicySection {property} />

  <!-- <section>
    <header>
      <h1>Access Control</h1>
    </header>
    <ItemsList
      class="activity"
      items={$points && Object.values($points?.items ?? {})}
      types={{
        access: EntryCapabilityItem,
        entry: EntryCapabilityItem,
      }}
      ><aside class="empty history" slot="empty">
        There are no linked entry access options
      </aside></ItemsList
    >
  </section> -->
  <!-- <section>
    <header>
      <h1>Automated Access</h1>
    </header>
    {#each Object.values($policies || {}).filter((i) => i.access || i.entry) as policy}
      <ItemsList
        class="activity"
        items={[
          policy,
          ...Object.values(policy.access?.items ?? policy.entry?.items ?? {}),
        ]}
        types={{
          policy: PolicySummaryItem,
          access: EntryCapabilityItem,
          entry: EntryCapabilityItem,
        }}
      />
    {:else}
      <aside class="empty history">
        There are no linked entry access automations
      </aside>
    {/each}
  </section> -->
  <SensorsSection {property} />
</section>
