<script lang="ts">
  import { comparer, dateDesc } from "$utils/sort";
  import NoteItem from "$components/note/NoteItem.svelte";
  import { createEventDispatcher } from "svelte";

  export let item: any;
  export let readonly: boolean = true;
  let items: Record<string, Typed & any> = {};

  const events = createEventDispatcher<{ removed: Typed }>();

  $: items = item?.attached?.items ?? item?.items ?? {};
  //$: logger("notes=", items);

  $: notes = Object.values(items ?? {})
    .filter((i: Typed) => i.type == "note")
    .sort(comparer("issued.datetime", dateDesc));
</script>

{#if item && notes?.length}
  <ul class="notes">
    {#each notes as item}
      <li>
        <NoteItem
          {readonly}
          {item}
          on:removed={(e) => events("removed", e.detail)}
        />
      </li>
    {/each}
  </ul>
{/if}
