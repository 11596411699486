import { propertyId } from "$utils/propertystores";
import { points } from "./stores";
const { scope, updated, result } = points();
propertyId.subscribe((id) => {
    scope.set(id);
});

export {
    updated,
    result as points,
}