import { authHeader, base, viewpoint } from "$utils/api";

export async function fetchCameras(scope: string, valid?: string): Promise<any | null> {
    if (!scope) return null;
    const json = await Promise.all([
        fetch(
            `${base()}/cameras?scope=${scope}&viewpoint=${viewpoint()}&valid=${valid ? encodeURIComponent(
                valid
            ) : ""}&${authHeader}`
        ),
    ])
        .then((values) => Promise.all(values.map((res) => res.json())))
        .then((values) => Object.assign({}, ...values))
        .then((values) => {


            //logger('items=', values.items);
            for (const [k1, v1] of Object.entries((values.cameras.for ?? {}) as Record<string, any>)) {

                ///logger('item=', v1);

                for (const [k2, v2] of Object.entries((v1.items ?? {}) as Record<string, any>)) {
                    v1.items[k2] = (values.items[v2] ?? values.items[k2] ?? v2);
                }

            }

            for (const [k1, v1] of Object.entries((values.cameras.items ?? {}) as Record<string, any>)) {
                values.cameras.items[k1] = values.items[v1] ?? values.items[k1] ?? v1;
            }

            //   for (const [k, v] of Object.entries(values.detections?.items || {})) {
            //     const item = values.detections.items[k] = values.items[v] || values.items[k] || v;
            //     if (item.file) item.file = values.items[item.file] || item.file;

            //   }

            return values;

        });

    return json;
}