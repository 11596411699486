<script lang="ts">
  import ItemExpandContent from "$components/item/ItemExpandContent.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import { thumbnail } from "$utils/filepreview";
  import { comparer } from "$utils/sort";
  import { dateparts, datetime } from "$utils/temporal";
  import { autocreate } from "$utils/vehicle";
  import VehicleDetectionsStatusMap from "./VehicleDetectionsStatusMap.svelte";

  export let item: any;
  //logger("item=", item);
  const vehicle =
    typeof item.vehicle === "string"
      ? autocreate(item.vehicle, item.scope)
      : item.vehicle;
  //if (!vehicle) logger("item=", item);
  const files = Object.values(item.items)
    .map((i: any) => i.file)
    .filter(Boolean)
    .sort(comparer("created", Temporal.Instant.compare, true));
</script>

<article class="details {item.type}">
  <header>
    <h1>
      {!!item.reoffending
        ? "Reoffender"
        : !!item.offending
          ? "Offender"
          : "Scanned"}
    </h1>
  </header>
  <dl>
    <dt>Vehicle</dt>
    <dd><RecordItem item={vehicle} /></dd>

    <dt>Scanned</dt>
    <dd>
      {#if item.latest}
        <time datetime={item.latest.created.utc}
          >{datetime(item.latest.created.utc, item.scope?.timezone)}</time
        >
      {:else}
        never
      {/if}
    </dd>

    {#if item.latest}
      <dt>Status</dt>
      <dd>
        <data class="permit" value={item.permitted.latest ?? ""}>
          {#if !!item.permitted.latest}
            Valid permit at time of scan
          {:else}
            No valid permit at time of scan
          {/if}
        </data>
      </dd>
      <dd>
        <data class="permit" value={item.permitted.asof ?? ""}>
          {#if !!item.permitted.asof}
            Valid permit as of now
          {:else}
            No valid permit as of now
          {/if}
        </data>
      </dd>
    {/if}
    {#if !!item.offending && !!item.repeated}
      <dt>Repeat</dt>
      <dd>
        <data class="detections" value={item.repeated.count}
          >Seen {item.repeated.count} times</data
        >
      </dd>
    {/if}
    {#if item.violations?.latest}
      <dt>Violation</dt>
      <dd>
        Recorded {datetime(
          Temporal.Instant.from(
            item.violations.latest.issued.datetime
          ).toZonedDateTimeISO(item.violations.latest.timezone)
        )}
      </dd>
    {/if}
  </dl>
  <slot />
  <!-- <ItemExpandContent {item}>
    <aside class="enforcement map {item.type}">
      <VehicleDetectionsStatusMap {item} />
    </aside>
    <ul class="images">
      {#each files.map( (file) => [file, dateparts(Temporal.Instant.from(file.created))] ) as [file, date]}
        <li>
          <figure class="image" class:uploading={file.local}>
            <a
              download={file.name}
              href={file.url}
              type={file.format}
              target="_blank"
              ><img
                alt="thumbnail"
                loading="lazy"
                src={thumbnail(file, 200, 200, true)}
              /></a
            >
            <figcaption>
              <time datetime={file.created}>{date.month} {date.day}</time>
            </figcaption>
          </figure>
        </li>
      {/each}
    </ul>
  </ItemExpandContent> -->
</article>
