<script lang="ts">
  import { createEventDispatcher, onDestroy } from "svelte";
  import { type VectorGLMap, type ControlPosition, type IControl } from ".";
  import { getSvelteContext, engine } from ".";

  let classname: string = "";
  export { classname as class };

  const { map } = getSvelteContext();
  const control: IControl = new engine.NavigationControl();
  let added: boolean = false;
  export let position: ControlPosition = "top-right";

  // const events = createEventDispatcher<{
  //   click: typeof feature;
  // }>();

  function init(
    $control: any,
    $map: VectorGLMap,
    $placement?: ControlPosition
  ) {
    //logger("init popup", $position, $content);
    if ($control && $map && added) {
      $map.removeControl($control);
      added = false;
      //$control.onRemove($map);
      //return;
    }
    if (!!$placement && !added) {
      $map.addControl($control, $placement);
      added = true;
    }
  }

  $: init(control, $map, position);

  onDestroy(function () {
    init(control, $map);
  });
</script>
