<script lang="ts">
  import PermittableRemove from "./PermittableRemove.svelte";
  import PermittableSummaryItem from "./PermittableSummaryItem.svelte";
  import { state as editing } from "$utils/item";
  import { permittablesUpdated } from "$utils/propertystores";

  export let item;
  export let interactive = true;
  export let record = null;

  //$: logger("item=", item);
</script>

<article
  class="{item.type} details"
  class:donotpermit={item.permittable === false}
  data-record="permittables/{item.id}"
>
  <PermittableSummaryItem {item} {interactive} {record}>
    {#if interactive}
      <nav>
        <h1>Options</h1>
        <ul>
          <li>
            <button
              type="button"
              class="unban"
              on:click={(e) => $editing.start("revoke", item)}>Unban</button
            >
          </li>
        </ul>
      </nav>
    {/if}</PermittableSummaryItem
  >
</article>
