<svelte:options runes={true} />

<script lang="ts">
  import { watch } from "$components/vehicle/detection";
  import IngressSection from "$components/vehicle/detection/IngressSection.svelte";
  import VehiclesPresentEnforceSubsection from "$components/lprd/VehiclesPresentEnforceSubsection.svelte";
  import SensorsSection from "$components/sensor/SensorsSection.svelte";
  import ScansSummarySection from "$components/vehicle/detection/ScansSummarySection.svelte";
  import Help from "$components/Help.svelte";

  const { property = null } = $props<{
    property: Property | null;
  }>();

  const sections = {
    enter: function (id) {
      //logger("scrollTo = ", scrollTo, id);
      if (scrollTo && id != scrollTo) return; // don't update, we're scrolling to another one
      //logger("entered into = ", id);
      section = id;
      if (id == scrollTo) scrollTo = null;
    },
    autoaudits: property?.vehicles?.presence?.analysis
      ? // || import.meta.env.DEV
        {
          element: null,
          title: "Scan Coverage",
        }
      : undefined,
    // ingressunpermitted:
    //   property?.vehicles?.presence?.installed || import.meta.env.DEV
    //     ? {
    //         element: null,
    //         title: "Unauthorized Now",
    //       }
    //     : undefined,
    // ingressgrace:
    //   property?.vehicles?.presence?.installed || import.meta.env.DEV
    //     ? {
    //         element: null,
    //         title: "Monitoring",
    //       }
    //     : undefined,
    // ingressprev:
    //   property?.vehicles?.presence?.installed || import.meta.env.DEV
    //     ? {
    //         element: null,
    //         title: "Recent Unauthorized",
    //       }
    //     : undefined,
  };
</script>

<section class="autointel">
  <header>
    <h1>Monitor & Detect</h1>
    <p>
      Use license plate scanning to proactively identify vehicles in violation
      and flag for enforcement.
    </p>
    <dl>
      <dt>Scan Types</dt>
      <dd>
        <ul>
          <li>Mounted Sensors</li>
          <li>Mobile Patrol</li>
          <li>Field Agent</li>
        </ul>
      </dd>
    </dl>
    <Help
      subject="Monitor & Detect"
      body="Interested in Monitor & Detect functionality"
      >I'd like to learn more</Help
    >
  </header>
  <!-- <section class="<sensors">
    <header><h1>Virtual Gate</h1></header>
  </section> -->
  <!-- <section class="<sensors">
    <header><h1>Mobile Patrol</h1></header>
  </section> -->
  <!-- <IngressSection {property} /> -->
  <!-- <ScansSummarySection {property} /> -->
  <VehiclesPresentEnforceSubsection {property} {sections} />
  <!-- <ScansSummarySection {property} /> -->
  {#if property?.vehicles?.presence?.installed}
    <SensorsSection {property} />
  {/if}
</section>
