<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let levels: { [key: string]: any } = {};
  export let level: string;

  const eventing = createEventDispatcher();

  //$: logger("level=", level);

  $: levelslist = Object.values(levels ?? {})
    .map((v: any) => ({
      ...v,
      value: v.level,
      ref: v["level:ref"],
    }))
    //.filter((i) => "" !== i.level)
    .sort(
      (a: any, b: any) =>
        nandor(parseFloat(a.level), -1000) - nandor(parseFloat(b.level), -1000)
    );
  function nandor(value, fallback) {
    return isNaN(value) ? fallback : value;
  }
  function label(ref: string) {
    if (ref === "Outdoors") return "Outside";
    return ref;
  }
  function change(key: string, value: string) {
    eventing("change", {
      [key]: value,
    });
    eventing(key, value);
  }
  const onchange = ({ target, currentTarget }) =>
    currentTarget instanceof HTMLSelectElement
      ? change(currentTarget.name, currentTarget.value)
      : currentTarget instanceof HTMLInputElement
        ? change(
            currentTarget.name,
            currentTarget.checked ? currentTarget.value : null
          )
        : target instanceof HTMLSelectElement
          ? change(target.name, target.value)
          : target instanceof HTMLInputElement
            ? change(target.name, target.checked ? target.value : null)
            : null;
</script>

<select name="level" bind:value={level} on:change={onchange}>
  {#each levelslist as level}
    <option value={level.value}>{label(level.ref)}</option>
  {:else}
    <option value="outside">Outside</option>
  {/each}
</select>
