<script lang="ts">
  import { createEventDispatcher, tick } from "svelte";
  import Record from "$components/record/RecordItem.svelte";
  import Select from "../record/SelectUnit.svelte";

  export let predefined = true;
  export let value: any = null;
  export let name = "tenant";
  export let label = "Unit";
  export let placeholder: string | null | undefined = null;
  export let required = false;
  export let readonly = false;
  export let items: any[] = [];

  let element: HTMLElement;

  $: id = value && (value.id || value)?.toString();

  $: item = value?.id
    ? value
    : (items?.[value] ??
      Object.values(items ?? []).find((i) => i.id === value));

  //$: logger("tenant field=", value, id, item, items);

  const evented = createEventDispatcher();

  async function change(newvalue: any) {
    value = newvalue;
    await tick();
    if (value)
      element?.scrollIntoView({ behavior: "instant", block: "center" });
    evented("change", {
      name,
      value,
    });
  }
</script>

{#if id && item}
  <figure class="value record" bind:this={element}>
    {#if label}
      <figcaption>{label}</figcaption>
    {/if}
    <input type="hidden" {name} value={id} />
    <Record {item} url={false} />
    {#if !readonly}
      <button class="clear" type="button" on:click={(e) => change(null)}
        >change</button
      >
    {/if}
  </figure>
{:else}
  <Select
    placeholder={required ? "select…" : "select…"}
    name={!predefined ? name : null}
    {label}
    {required}
    {items}
    on:unit={(e) => change(e.detail)}
  />
{/if}
