<script context="module">
  const calendar = {
    P1D: "day",
    P1W: "week",
    P1M: "month",
    P1Y: "year",
  };
  const nowutc = time({ minutes: 30 });
</script>

<script>
  import { scaleTime, scaleLinear } from "d3-scale";
  import {
    addMonths,
    addWeeks,
    differenceInMonths,
    differenceInWeeks,
    eachHourOfInterval,
    eachWeekOfInterval,
    endOfDay,
    endOfWeek,
    isEqual,
    isWithinInterval,
    startOfDay,
    startOfWeek,
  } from "date-fns";
  import { format, toZonedTime } from "date-fns-tz";
  import addDays from "date-fns/addDays";
  import range from "lodash-es/range";
  import { time } from "$utils/timestores";
  import { sortByCreatedDate } from "./components";
  import { stringToDateInterval } from "./item/ItemsList.svelte";
  import SvgTimeNow from "./svg/SVGTimeNow.svelte";
  export let item;

  //const tickCount = 5;

  $: nowlocal = toZonedTime($nowutc, item?.timezone);

  //$: logger("item=", item, nowlocal);

  $: interval =
    item && item.interval && stringToDateInterval(item.interval, item.timezone);

  //$: intervals = Object.entries(item?.checks || {}).map(([ interval, count ]) => ({ interval, count, ...stringToDateInterval(interval, item.timezone) }));

  $: sessions = Object.values(item?.values || {}).map((interval) => ({
    interval,
    ...stringToDateInterval(interval, item.timezone),
  }));

  $: singleDay =
    isEqual(interval.end, addDays(interval.start, 1)) ||
    isEqual(interval.end, endOfDay(interval.start));
  $: tickFormat = singleDay ? "h a" : "MMM d";
  $: months = differenceInMonths(interval.end, interval.start);
  $: start = interval.start;
  $: end = singleDay
    ? endOfDay(start)
    : months > 6 && isWithinInterval(nowlocal, interval)
      ? addMonths(interval.end, 1)
      : endOfWeek(interval.end);

  $: tickCount = singleDay ? 6 : 5;

  //$: logger("start=",start,"end=",end);

  $: timescale = scaleTime().domain([start, end]).range([0, 100]);

  $: containsNow = isWithinInterval(nowlocal, { start, end });

  $: ticks = singleDay
    ? eachHourOfInterval(interval).filter((i) => i.getHours() % 4 == 0)
    : months < 2 && isEqual(start, startOfWeek(start))
      ? eachWeekOfInterval(interval)
      : range(start, end, (end - start) / tickCount);
</script>

<figure class="chart">
  <svg height="52" width="100%">
    <svg class="values" height="32" width="100%">
      <rect width="100%" height="100%" />
      {#if nowlocal && !isNaN(timescale(nowlocal))}
        <rect
          class="today"
          x="{timescale(startOfDay(nowlocal))}%"
          y="0%"
          height="100%"
          width="{timescale(endOfDay(nowlocal)) -
            timescale(startOfDay(nowlocal))}%"
        />
      {/if}
      {#each sessions as item}
        <rect
          class="online"
          x="{timescale(item.start)}%"
          y="0%"
          height="100%"
          width="{timescale(item.end) - timescale(item.start)}%"
        />
      {/each}
      {#each Object.values(item.items)
        .filter((item) => sortByCreatedDate(item))
        .map((item) => [sortByCreatedDate(item), item.type]) as [date, type]}
        <line
          class={type}
          stroke-width="1"
          y1="0%"
          y2="100%"
          x1="{timescale(date)}%"
          x2="{timescale(date)}%"
        />
      {/each}
    </svg>
    <g class="legend">
      <!-- <line class="date min" x1="0%" x2="0%" y1="0%" y2="100%" /> -->
      {#each ticks as tick}
        <line
          class="date"
          class:min={timescale(tick) == 0}
          x1="{timescale(tick)}%"
          x2="{timescale(tick)}%"
          y1="0%"
          y2="100%"
        />
        <text class="date min" x="{timescale(tick)}%" y="100%"
          >{format(tick, tickFormat)}</text
        >
      {/each}
      <line class="date max" x1="100%" x2="100%" y1="0%" y2="100%" />
    </g>

    <svg class="context" height="32" width="100%">
      {#if containsNow}
        <SvgTimeNow x="{timescale(nowlocal)}%" />
      {/if}
    </svg>
  </svg>
</figure>
