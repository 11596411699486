<script context="module" lang="ts">
  import { details } from "$utils/propertystores";
</script>

<script lang="ts">
  import {
    formatISODuration,
    intervalToDuration,
    milliseconds,
  } from "date-fns";

  import { format, toZonedTime } from "date-fns-tz";
  import { stringToDateInterval } from "./item/ItemsList.svelte";
  import Record from "$components/record/RecordItem.svelte";
  import Time from "./Time.svelte";
  export let item: Check;
  export let record = null;
  import { title as proper } from "$utils/text";
  import ItemExpandContent from "./item/ItemExpandContent.svelte";

  //$: logger("check=", item);

  $: interval = stringToDateInterval(item?.interval);
  $: duration = interval && intervalToDuration(interval);
  $: minutes =
    duration && Math.max(Math.ceil(milliseconds(duration) / 1000 / 60));

  $: expand = item && $details[item.id];

  function toggleExpand() {
    if (!item) return;
    details.update(($values) => {
      if ($values[item.id]) delete $values[item.id];
      else $values[item.id] = true;
      //$values[item.id] = !$values[item.id];
      return $values;
    });
  }
</script>

{#if item}
  <article class="accessed details" data-id={item.id}>
    <header>
      <h1>
        <data class="accessed" value={item.id}
          >Enforcement Check
          <!-- ed{#if !record} {startCase(item.subject.format || item.subject.type)}{/if} -->
        </data>
      </h1>
    </header>

    <!-- </time
      > -->

    <!-- </h1> -->
    <dl>
      <!-- <dt>Checked</dt> -->
      {#each [item.subject].filter((item) => item && item.id !== (record?.id || record)) as record}
        <dt>
          {record.title || proper(record.format) || proper(record.type)}
        </dt>
        <dd><Record item={record} /></dd>
      {/each}
      <dt>Recorded</dt>
      <dd>
        <Time datetime={item.datetime} timezone={item.timezone} />
        <!-- <time datetime={item.interval || item.datetime}
        ><abbr
          >{format(
            toZonedTime(item.datetime, item.timezone),
            "h:mm a MMM d yyyy zzz",
            {
              timeZone: item.timezone,
            }
          )}</abbr
        > -->
        {#if minutes}
          <time class="duration" datetime={formatISODuration(duration)}
            ><abbr>{minutes} min</abbr></time
          >{/if}
      </dd>
      {#each [item.principal]
        .filter((item) => item && item.id)
        .slice(0, 1) as user}
        <dd>
          <a href="mailto:{user.email}"
            ><data class="user {user.type}" value={user.id}
              ><dfn>by</dfn> {user.name}</data
            ></a
          >
        </dd>
      {/each}

      <dt>Location</dt>
      <dd>
        <data
          class="position"
          value={(item.geometry?.coordinates || []).join(",")}
          >{item.geometry ? "Recorded" : "No data"}</data
        >
      </dd>
      {#if item.subject?.type === "vehicle" || item.subject?.type === "media"}
        <dt>Status</dt>
        <dd>
          <data class="permitted" value={(!!item.permitted).toString()}>
            {#if !!item.permitted}
              Valid permit at time of check
            {:else if false === item.permitted}
              No valid permit at time of check
            {/if}
          </data>
        </dd>
        {#if Array.isArray(item.permitted) && item.permitted.length}
          <!-- <ItemExpandContent {item}> -->
          <dd>
            <ul class="subjects">
              {#each item.permitted as record}
                <li><Record item={record} /></li>
              {/each}
            </ul>
          </dd>
          <!-- </ItemExpandContent> -->
        {/if}
      {/if}
    </dl>
  </article>
{/if}
