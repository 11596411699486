<script lang="ts">
  import { help } from "$utils/help";
  export let item: Suspend;
</script>

{#if item}
  <figure
    class={item.type}
    class:pending={item.pending}
    class:active={item.active}
  >
    {#if item.pending}
      <h1>This account is at risk of being suspended</h1>
    {:else if item.active}
      <h1>This account has been suspended</h1>
    {/if}
    <figcaption>
      <ul>
        <li>
          <a href="mailto:help@communityboss.com" on:click|preventDefault={help}
            >Get Help Fixing This</a
          >
        </li>
      </ul>
    </figcaption>
  </figure>
{/if}
