<script lang="ts">
  import { property } from "$utils/propertystores";
  import { editors } from "../components";
  export let state: any;

  export let item: Typed = null;
  $: editor = editors[item?.type]?.[state?.action];
  $: logger("ItemEditor=", item, editor, state);
</script>

{#if editor}
  <svelte:component
    this={editor}
    item={item ?? state.source}
    {...state.context}
    property={item.property ?? $property}
    on:complete={(e) => state.complete()}
    on:cancel={(e) => state.cancel()}
    on:change={(e) => state.progress(e.detail)}
    values={state.values}
  />
{/if}
