<script lang="ts" context="module">
  const inviewOptions = {
    threshold: 0.5,
  };
</script>

<script lang="ts">
  import { comparer } from "$utils/sort";
  import { writable } from "svelte/store";
  import { enforce } from ".";

  import VehiclesPresentInfo from "./VehiclesPresentInfo.svelte";
  import { inview } from "svelte-inview";
  import VehiclesEnforceZonedSubsections from "$components/vehicle/present/VehiclesEnforceZonedSubsections.svelte";
  import VehiclesEnforcePlacedSubsections from "$components/vehicle/present/VehiclesEnforcePlacedSubsections.svelte";

  export let property: Property;
  export let sections: any;

  const sortby = comparer("present.created.datetime", undefined, true);

  const scope = writable<string | null>(null);

  //$: logger("$enforce=", $enforce);

  $: scope.set(property?.id ?? null);
  $: logger("enforce=", $enforce);

  // $: warn = Object.values($enforce?.for ?? {})
  //   .filter(filterpermitted)
  //   .sort(sortby);
</script>

{#if property?.vehicles?.presence?.installed}
  <VehiclesEnforceZonedSubsections {property} {sections} />
{/if}
<!-- {#if property?.vehicles?.presence?.analysis}
  <VehiclesEnforcePlacedSubsections {property} {sections} />
{/if} -->
{#if sections.autoaudits}
  <section
    id="autoaudits"
    bind:this={sections.autoaudits.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("autoaudits")}
  >
    <header>
      <h1>{sections.autoaudits.title} <dfn class="preview">Preview</dfn></h1>
    </header>
    <VehiclesPresentInfo
      {property}
      title=""
      details="/{property.type}/{property.id}/parking/presence"
      coverage
    />
  </section>
{/if}
