import { fetchPresent, fetchPresentEnforce, fetchStatus, fetchVehiclePresentEnforce } from "./api";
import { isSystemAdmin, property as propertyd } from "$utils/propertystores";
import { instant } from "$utils/temporal";
import type { Feature } from "geojson";
import { derived, readable, writable, type Readable, type Writable } from "svelte/store";
import { authorize } from "$utils/api";
import { param } from "$utils/params";

interface TPV {
    device: string;
}

export interface Command {
    position?: number;
    detections?: boolean;
    images?: number;
    cameras?: number;
    system?: number;
    flip?: string;
    region?: string;
}
interface State {
    client?: string;
    version?: string;
    valid?: string;
    tpv?: TPV;
    position?: GeolocationPosition;
    cameras?: any[];
    system?: any;
    wwan?: any;
    cmd?: Command;
    property?: Feature;
}
interface Connected {
    online?: boolean;
    offline?: boolean;
}
interface Connection {
    connecting: boolean;
    connected: boolean;
}

export interface Offending {
    items: Readable<Record<string, any>>;
    position: Readable<GeolocationPosition | null | undefined>;
    clear: (id: string) => void;
}

export const interval = param("present");


export const now = instant({ seconds: 1 });

const presencestores: Record<string, Writable<Record<string, any>>> = {};
const statestores: Record<string, any> = {};
export const devices = writable<Record<string, {
    id: string;
    name: string;
    valid: string;
}>>({});


export function offending(lprd: string): Offending {
    const items = presencestores[lprd] ??= writable<Record<string, any>>({});
    return {
        items,
        position: derived(state(lprd), $state => $state?.position),
        clear: function (id) {
            items.update($state => {
                if (!$state[id]) return $state;
                delete $state[id];
                return $state;
            });
        },

    };
}

function offender(lprd: string, status: any) {
    if (!status.offending) return;
    var store = presencestores[lprd] ??= writable<Record<string, any>>({});
    logger("status=", status, lprd);
    store.update($value => {
        status.received = new Date().toISOString();
        $value[status.id] = status;
        return $value;
    });
}

function state(lprd: string): Writable<State> {
    return statestores[lprd] ??= writable<State>({ client: lprd });
}

const eventsource = writable<EventSource | null | undefined>(null);
const eventstate = writable<number>(2);
export const connected = derived<[typeof eventsource, typeof eventstate], boolean>([eventsource, eventstate], ([$source, $state]) => !!$source && $state === 1);
export const connecting = derived<[typeof eventsource, typeof eventstate], boolean>([eventsource, eventstate], ([$source, $state]) => !!$source && $state === 0);

let watching: Writable<Record<string, boolean>> = writable({});

function rf(signal: any): 0 | 1 | 2 | 3 | 4 | 5 {

    const rsrp = null == signal.rsrp || signal.rsrp <= -199 ? signal.rssi : signal.rsrp;
    const snr = signal.sinr ?? signal.snr;

    let rsrp_bars = 0;
    let snr_bars = 0;

    if (rsrp > -85) rsrp_bars = 5
    else if (rsrp > -95) rsrp_bars = 4;
    else if (rsrp > -105) rsrp_bars = 3;
    else if (rsrp > -115) rsrp_bars = 2;
    else if (signal.rsrp > -199) rsrp_bars = 1;

    if (snr >= 13) snr_bars = 5;
    else if (snr >= 4.5) snr_bars = 4;
    else if (snr >= 1) snr_bars = 3;
    else if (snr > -3) snr_bars = 2;
    else if (snr > -99) snr_bars = 1;

    return Math.min(snr_bars, rsrp_bars);


    //     RSRP > -85, rsrp_bars = 5;
    //     else
    //         - 95 < RSRP <= -85, rsrp_bars = 4
    //         - 105 < RSRP <= -95, rsrp_bars = 3
    //         - 115 < RSRP <= -105, rsrp_bars = 2
    //         - 199 < RSRP <= -115, if we're connected to the cellular network, rsrp_bars=1,
    //     if not rsrp_bars = 0
    // If RSRP <= -199, the device uses the RSSI as the value with the same algorithm:

    //     SNR >= 13, snr_bars = 5
    //     4.5 <= SNR < 13, snr_bars = 4
    //     1 <= SNR < 4, snr_bars = 3
    //         - 3 < SNR < 1, snr_bars = 2
    //         - 99 < SNR <= -3, if we're connected to the cellular network, snr_bars=1, if not 
    //snr_bars = 0

    // if (signal.rssi > -65 && signal.rsrp > -84 && signal.rsrq > -5 && (Math.max(signal.sinr, signal.snr) >= 12.5)) return 4;
    // if (signal.rssi >= -75 && signal.rsrp >= -102 && signal.rsrq > -9 && (Math.max(signal.sinr, signal.snr) >= 10)) return 3;
    // if (signal.rssi >= -85 && signal.rsrp >= -111 && signal.rsrq >= -12 && (Math.max(signal.sinr, signal.snr) >= 7)) return 2;
    // //if (signal.rssi < -85 && signal.rsrp < -111 && signal.rsrq < -12 && (Math.max(signal.sinr, signal.snr) < 7)) return 1;

    // return 0;
}

function wwan(modems: any[]): any | null | undefined {
    if (!modems) return null;
    if (!modems.length) return null;
    var modem = modems.find(Boolean);
    if (!modem) return null;
    const signal = {
        quality: modem.data.SignalQuality,
        recent: modem.data.SignalQualityRecent,
        ...Object.values(modem.signal.CurrentSignals ?? modem.signal.signals ?? []).reduce((signal: Record<string, any>, item) => {
            if (typeof item === "string" && item.startsWith("ey")) for (const [k, v] of Object.entries(JSON.parse(atob(item)))) signal[k.toString().toLowerCase()] = v;
            else for (const [k, v] of Object.entries(item)) signal[k.toString().toLowerCase()] = v;
            return signal;
        }, {} as Record<string, any>) as any,
    };
    signal.bars = rf(signal);
    if (typeof modem.data.Sim === "string" && modem.data.Sim?.startsWith("ey")) modem.data.Sim = JSON.parse(atob(modem.data.Sim));
    var data = {
        //id: modem.SIM.SimIdentifier, // sim
        hardware: {
            manufacturer: modem.data.Manufacturer,
            model: modem.data.Model.replace("_", " "),
            rev: modem.data.HardwareRevision,
            id: modem["3GPP"]?.Imei ?? modem.data.EquipmentIdentifier,  //imei
            firmware: modem.data.Revision,
        },
        connection: {
            id: modem.data.Sim?.SimIdentifier, //modem.SIM.SimIdentifier,
            type: modem.simple.AccessTechnology,
            connected: true,
            operator: modem["3GPP"]?.OperatorName ?? modem.simple?.M3GppOperatorName,
            signal,
        },
    };
    logger("wwan=", data);
    return data;
}

function onerror(e: Event) {
    e.target
    console.error("EventSource failed:", e);
    eventstate.set((e.target as EventSource)?.readyState ?? 2);
}

function onopen(e: Event) {
    logger("EventSource connected", e);
    eventstate.set((e.target as EventSource)?.readyState ?? 2);
}

function onmessage(e: MessageEvent) {
    //logger("message", e);
    const data = JSON.parse(e.data);
    //logger("data=", data);
    if (!data) return;

    if (data.devices) {
        devices.set(data.devices);
    }


    const lprd = data.client;
    if (!lprd) return;

    const store = state(lprd);

    store.update($state => {

        const tpv = data.position ?? data.tpv ?? data.gps ?? data.coordinates;
        if (data.version) $state.version = data.version;
        if (data.cmd) {
            $state.cmd = data.cmd;
            //logger("data.cmd=", data.cmd);
        }
        if (data.valid) $state.valid = data.valid;
        if (data.tpv) $state.tpv = data.tpv;
        if (tpv?.lat && tpv?.lon) {
            //logger("tpv=", tpv);
            //$state.valid = tpv.time;
            $state.position = {
                timestamp: new Date(tpv.time).getTime(),
                coords: {
                    latitude: Number(tpv.lat.toFixed(5)),
                    longitude: Number(tpv.lon.toFixed(5)),
                    accuracy: tpv.eph,
                    speed: tpv.speed,
                    heading: tpv.track,
                    altitude: tpv.alt,
                    altitudeAccuracy: tpv.epv,
                },
            };
        }
        if (data.system) {
            //latest(Temporal.Instant.from(data.system.datetime));
            $state.system = {
                datetime: data.system.datetime,
                ...data.system.system,
            };
        }

        if (data.cameras) {
            logger("$cameras=", data.cameras);
            $state.cameras = data.cameras;
        }
        if (data.modem) {
            logger("modems=", data.modem);
            $state.wwan = wwan(data.modem);
            $state.wwan.datetime = data.valid;
        }

        // if (data.cameras) {
        //     $state.cameras = data.cameras;
        // }
        if (data.property) {
            //logger("property", data.property);
            $state.property = data.property as Feature;
        } else if (data.property === false) {
            $state.property = undefined;
        }

        //logger("updating $state=", $state);

        return $state;
    });

    if (data.status) {
        data.status.image = data.image;
        offender(lprd, data.status);
    }



    // const tpv = data.position ?? data.tpv ?? data.gps ?? data.coordinates;
    // const anpr = data.body?.body?.anpr ?? data.body?.anpr ?? data.anpr;
    // if (data.cmd) cmd.set(data.cmd);
    // if (tpv?.lat && tpv?.lon) {
    //     //logger("tpv=", tpv);
    //     latest(Temporal.Instant.from(tpv.time));
    //     position.set({
    //         timestamp: new Date(tpv.time).getTime(),
    //         coords: {
    //             latitude: Number(tpv.lat.toFixed(5)),
    //             longitude: Number(tpv.lon.toFixed(5)),
    //             accuracy: tpv.eph,
    //             speed: tpv.speed,
    //             heading: tpv.track,
    //             altitude: tpv.alt,
    //             altitudeAccuracy: tpv.epv,
    //         },
    //     });
    // }
    // if (data.cameras) {
    //     cameras.set(
    //         (_cameras = {
    //             left: Object.values(data.cameras)[0],
    //             right: Object.values(data.cameras)[1],
    //         })
    //     );
    // }

    // if (data.vehicle && data.camera) {
    //     detections.update(($detections) => {
    //         $detections[data.camera.serial as string] ??= 0;
    //         $detections[data.camera.serial as string]++;
    //         return $detections;
    //     });
    // }


    // if (data.system) {
    //     latest(Temporal.Instant.from(data.system.datetime));
    //     system.set({
    //         datetime: data.system.datetime,
    //         ...data.system.system,
    //     });
    // }
    // if (data.image) {
    //     logger("image=", data.image);
    //     frames.update((frames) => {
    //         frames[data.camera.serial as string] = jpeg(
    //             (data.image.image ?? data.image).jpeg["#text"]
    //         );
    //         return frames;
    //     });
    //     //frames[data.camera.serial as string] = jpeg(data.image.jpeg["#text"]);
    //     // if (data.camera.serial === _cameras.right?.serial) {
    //     //   rightframe.set(jpeg(data.image.jpeg["#text"]));
    //     // }
    //     // if (data.camera.serial === _cameras.left?.serial) {
    //     //   leftframe.set(jpeg(data.image.jpeg["#text"]));
    //     // }
    // }
}

function close($source: EventSource) {
    if (!$source) return $source;
    $source.close();
    $source.removeEventListener("error", onerror);
    $source.removeEventListener("open", onopen);
    $source.removeEventListener("message", onmessage);
    return $source;
}

function open(url: URL) {
    const $source = new EventSource(authorize(url));

    $source.addEventListener("message", onmessage);
    $source.addEventListener("error", onerror);
    $source.addEventListener("open", onopen);

    return $source;
}

watching.subscribe($watching => {
    logger("$watching=", $watching);
    // close out the existing source
    eventsource.update($source => {
        if (!$source) return $source; // nothing to update
        close($source);
        eventstate.set($source.readyState);
        return null;
    });

    // nothing else to watch
    // if (!Object.values($watching).filter(watch => true === watch).length) {
    //     return;
    // }

    const url = new URL("https://events.propertyboss.io/lprd");
    for (const [id, watch] of Object.entries($watching)) {
        if (watch) url.searchParams.append("for", id);
    }

    eventsource.update($source => {
        $source = open(url);
        eventstate.set($source.readyState);
        return $source;
    });

});

export function watch(lprd: string | Readable<string>): Readable<State> {

    logger("start watching=", lprd);

    if (!lprd || typeof lprd !== "string") return readable({});

    watching.update($watching => {
        $watching[lprd] = true;
        return $watching;
    });

    return state(lprd);

}
export function unwatch(lprd: string | null | undefined) {
    if (!lprd) return;
    watching.update($watching => {
        if ($watching[lprd]) $watching[lprd] = false;
        return $watching;
    });
}

export function last($state: State): Temporal.Instant | null | undefined {
    return $state.valid ? Temporal.Instant.from($state.valid) : null;
}

export function ago($state: State, $now: Temporal.ZonedDateTime): Temporal.Duration | null | undefined {
    const $last = last($state);
    //return derived<[typeof now, typeof l], Temporal.Duration | null | undefined>(
    //[now, l],
    //([$now, $last]) => {
    if (!$now || !$last) return null;
    return $now.toInstant().since($last);
    //     }
    // );
}

export function active($state: State, $now: Temporal.ZonedDateTime): boolean | null | undefined {

    //return derived<[typeof state, typeof now], boolean | null | undefined>([state, now], ([$state, $now]) => {
    if (!$state?.valid) return null;
    const $last = Temporal.Instant.from($state.valid);
    const $ago = $now.toInstant().since($last)
    return Temporal.Duration.compare($ago, Temporal.Duration.from({ seconds: 61 })) <
        0;
    // return {
    //     online: Temporal.Duration.compare($ago, Temporal.Duration.from({ seconds: 61 })) <
    //         0,
    //     offline: Temporal.Duration.compare($ago, Temporal.Duration.from({ seconds: 61 })) >=
    //         0
    // };
    //});
}

export function load(scope: typeof propertyd): Readable<any> {
    return derived([scope, isSystemAdmin], ([$scope, $system], set) => {
        if (!$scope) return set(null);
        if (!$scope.vehicles?.presence?.analysis && !$system) return {};
        // Promise.all([
        fetchStatus(
            $scope.id,
            `${Temporal.Now.instant()
                .subtract({ hours: 24 * 7 })
                .toString()}/`
        )
            //   fetchDetectionsAndObservations(
            //     $scope,
            //     `${Temporal.Now.instant()
            //       .subtract({ hours: 24 * 7 })
            //       .toString()}/`
            //   ),
            // ])
            //   .then(([status, detections]) => {
            //     status.items = Object.entries(detections.detections.items).reduce(
            //       (acc, [key, value]) => {
            //         acc[key] = detections.items[key] ?? value;
            //         return acc;
            //       },
            //       {} as Record<string, any>
            //     );
            //     status.scope = $scope;
            //     return status;
            //   })
            .then(set)
            .catch(console.error);
    });
}


export const property = load(propertyd);

export function loadVehiclesPresentEnforce(scope: typeof propertyd, presentinterval?: typeof interval): Readable<any> {
    // interval = interval ?? readable(`${Temporal.Now.instant()
    //     .subtract({ hours: 24 * 3 })
    //     .toString()}/${Temporal.Now.instant()
    //         .subtract({ hours: 24 * 0 })
    //         .toString()}`);
    return derived([scope, presentinterval, isSystemAdmin], function updater([$scope, $interval, $system], set) {

        //logger("state=", $scope?.id, "=", updater.$scope, $interval, "=", updater.$interval, $system, "=", updater.$system);

        if ($scope?.id === updater.$scope && $interval === updater.$interval && $system === updater.$system) return; // no change

        //logger("about to call new enforcement data");

        if (updater.$interval !== $interval) set(null); // need to load new for sure


        updater.$scope = $scope?.id;
        updater.$interval = $interval;
        updater.$system = $system;

        if (!$scope) return set(null);
        if (!$interval) return set(null);


        if (!$scope.vehicles?.presence?.analysis && !$system) return {};
        // Promise.all([
        fetchPresentEnforce(
            $scope.id,
            $interval
        )
            //   fetchDetectionsAndObservations(
            //     $scope,
            //     `${Temporal.Now.instant()
            //       .subtract({ hours: 24 * 7 })
            //       .toString()}/`
            //   ),
            // ])
            //   .then(([status, detections]) => {
            //     status.items = Object.entries(detections.detections.items).reduce(
            //       (acc, [key, value]) => {
            //         acc[key] = detections.items[key] ?? value;
            //         return acc;
            //       },
            //       {} as Record<string, any>
            //     );
            //     status.scope = $scope;
            //     return status;
            //   })
            .then(set)
            .catch(console.error);
    });
}

export function loadVehiclesPresent(scope: typeof propertyd): Readable<any> {
    const interval = readable(`${Temporal.Now.instant()
        .subtract({ hours: 24 * 31 })
        .toString()}/${Temporal.Now.instant()
            .subtract({ hours: 24 * 0 })
            .toString()}`);
    return derived([scope, interval, isSystemAdmin], function updater([$scope, $interval, $system], set) {
        // cache last run so we can skip
        if ($scope?.id === updater.$scope && $interval === updater.$interval && $system === updater.$system) return; // no change

        updater.$scope = $scope?.id;
        updater.$interval = $interval;
        updater.$system = $system;


        if (!$scope) return set(null);
        if (!$scope.vehicles?.presence?.analysis && !$system) return {};
        // Promise.all([
        fetchPresent(
            $scope.id,
            $interval
        )
            //   fetchDetectionsAndObservations(
            //     $scope,
            //     `${Temporal.Now.instant()
            //       .subtract({ hours: 24 * 7 })
            //       .toString()}/`
            //   ),
            // ])
            //   .then(([status, detections]) => {
            //     status.items = Object.entries(detections.detections.items).reduce(
            //       (acc, [key, value]) => {
            //         acc[key] = detections.items[key] ?? value;
            //         return acc;
            //       },
            //       {} as Record<string, any>
            //     );
            //     status.scope = $scope;
            //     return status;
            //   })
            .then(set)
            .catch(console.error);
    });
}

export function loadVehiclePresentEnforce(item: Readable<Vehicle | null | undefined>, presentinterval?: typeof interval): Readable<any> {
    // interval = interval ?? readable(`${Temporal.Now.instant()
    //     .subtract({ hours: 24 * 3 })
    //     .toString()}/${Temporal.Now.instant()
    //         .subtract({ hours: 24 * 0 })
    //         .toString()}`);
    return derived([item, presentinterval ?? readable(Temporal.Now.instant().subtract({ hours: 30 * 24 }).toString() + "/"), isSystemAdmin], function updater([$item, $interval, $system], set) {

        //logger("state=", $scope?.id, "=", updater.$scope, $interval, "=", updater.$interval, $system, "=", updater.$system);

        if ($item?.id === updater.$scope && $interval === updater.$interval && $system === updater.$system) return; // no change

        //logger("about to call new enforcement data");

        if (updater.$interval !== $interval) set(null); // need to load new for sure


        updater.$scope = $item?.id;
        updater.$interval = $interval;
        updater.$system = $system;

        if (!$item) return set(null);
        if (!$interval) return set(null);


        if (!$item.vehicles?.presence?.analysis && !$system) return {};
        // Promise.all([
        fetchVehiclePresentEnforce($item.scope, $item.id, $interval)
            .then(set)
            .catch(console.error);
    });
}

//export const detections = loadVehiclesStatus(propertyd);
export const presence = loadVehiclesPresent(propertyd);
export const enforce = loadVehiclesPresentEnforce(propertyd, interval);
export const vehicle = loadVehiclePresentEnforce;