<script lang="ts">
  import { event } from "$utils/track";
  import { fetchResetUnit as update } from "$utils/api";
  import { unitsUpdated } from "$utils/propertystores";
  import { title as proper } from "$utils/text";
  import { tick } from "svelte";
  import { createEventDispatcher } from "svelte";
  export let confirmation = "This is permanent and cannot be undone. Continue?";
  export let item: any;
  export let label = "";

  $: if (!label) label = `Reset ${proper(item?.format || item.type)}`;

  $: id = item.subject?.id ?? item.subject ?? item.id ?? item;

  var submitting = false;

  var events = createEventDispatcher();

  async function reset(tenantOrUnit) {
    //e.preventDefault();

    // resolve object
    if (!tenantOrUnit) return;
    const item = tenantOrUnit;
    if (!item && !item.id) return;

    if (confirmation && !window.confirm(confirmation)) return;

    submitting = true;

    await tick();

    const res = await update(item, `Moved out ${item.format}`);

    event("reset", "tenant", location.href);

    const id = res.tenants.item;

    //logger("new id = ", id);

    unitsUpdated.set(Date.now()); // update the fact that units changed

    if (res && res.message) {
      //logger("res=", res);
      //submitting = false;

      submitting = false;
      return alert(res.message);
    }

    events("change", id);
  }
</script>

<button
  type="button"
  class="reset"
  on:click={(e) => reset(id)}
  disabled={submitting}
>
  {#if submitting}
    Saving&hellip;
  {:else}
    {label}
  {/if}</button
>
