<script lang="ts">
  import PaymentCard from "$components/PaymentCard.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import Time from "$components/Time.svelte";

  export let payment: {
    type: "payment";
    disputed: any;
    id: string;
    timezone: string;
  };
  //$: logger("payment=", payment);
  $: subjects = [
    payment.fee.subject?.media,
    payment.fee.subject?.vehicle,
    ...Object.values(payment.fee.subject?.spaces ?? []),
    payment.fee.subject?.tenant,
  ].filter(Boolean);
</script>

<article class="disputed {payment.type} details">
  <header>
    <h1>Chargeback</h1>
  </header>
  <dl>
    <dt>Card</dt>
    <dd>
      <PaymentCard item={payment.card} />
    </dd>
    <dt>Disputed</dt>
    <dd>
      <data class="amount" value={payment.disputed.initiated.amount.value}
        >{payment.disputed.initiated.amount.display}</data
      >
    </dd>
    <dd>
      Reported to bank as {payment.disputed.initiated.reason}
    </dd>
    <dd>
      <Time
        datetime={payment.disputed.initiated.datetime}
        timezone={payment.timezone}
      />
    </dd>

    <dt>For</dt>
    <dd>
      <ul class="subjects">
        {#each subjects as item}<li>
            <RecordItem {item} />
          </li>{/each}
      </ul>
    </dd>

    <dt>Bank Fee</dt>
    <dd>
      <data class="amount" value={payment.disputed.fee.value}
        >{payment.disputed.fee.display}</data
      >
    </dd>
    <dt>Original</dt>
    <dd>
      <data class="amount" value={payment.captured.amount.value}
        ><dfn>Charged </dfn>{payment.captured.amount.display}</data
      >
    </dd>
    <dd>
      <Time
        definition="on"
        datetime={payment.captured.datetime}
        timezone={payment.timezone}
      />
    </dd>
    <dd>for {payment.fee.subject.title}</dd>
  </dl>
</article>
