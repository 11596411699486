<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { getSvelteContext } from ".";

  import type { Feature, FeatureCollection, VectorGLMap, Point } from ".";

  import MapboxDraw from "@mapbox/mapbox-gl-draw";

  const { map, loaded, styledmap } = getSvelteContext();
  let MAP: VectorGLMap | null | undefined;

  export let features: FeatureCollection;
  export let deletable: Boolean = true;

  $: deleteFeaturesEnabled = deletable;

  const eventing = createEventDispatcher<{
    select: Feature[];
    change: Feature[];
    delete: Feature[];
    create: Feature[];
  }>();

  let mapHasBeenLoaded = false;
  $: if (!mapHasBeenLoaded && $loaded && $styledmap) mapHasBeenLoaded = true;

  $: logger("mapHasBeenLoaded=", mapHasBeenLoaded, features);

  let selected: Feature[] = [];
  export let mode: string | null | undefined = null;
  // these are old style callbacks
  //   let deleted = null;
  //   let changed = null;
  let points: Point[] = [];

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    keybindings: false,
    //defaultMode: "direct_select",
    // modes: {
    //   ...MapboxDraw.modes,
    // },
    //styles: [],
    // controls: {
    //     polygon: true,
    //     point: true,
    //     line_string:true,
    //     trash: true
    // }
  });

  let addedToMap = false;

  // setup the map editor

  function ondrawselectionchanged(data: any) {
    logger("draw.selectionchange=", data);
    eventing("select", data.features);
    selected = data.features;
    points = data.points;
  }

  function ondrawupdate(data: any) {
    logger("draw.update=", data);
    eventing("change", data.features);
    // if (typeof changed == "function") changed(data.features);
    // else dispatch("change", data.features);
  }

  function ondrawcreate(data: any) {
    logger("draw.create=", data);
    // if (typeof changed == "function") changed(data.features);
    //eventing("change", data.features);
    eventing("create", data.features);
    // selected = data.features;
    // points = data.points;
  }
  //

  //   });
  function ondrawdelete(data: any) {
    logger("draw.delete=", data);
    eventing("delete", data.features);
    //if (typeof deleted == "function") deleted(data.features);
    // selected = data.features;
    // points = data.points;
  }

  function ondrawmodechange(data: any) {
    logger("draw.modechange=", data);
    if (data.mode && data.mode != mode) mode = data.mode;
    //vertice = (data.mode == "direct_select"); // just went into vertice mode
  }

  // 8 - Backspace
  // 46 - Delete
  function keydown(event: KeyboardEvent) {
    // if ((event.srcElement || event.target).classList[0] !== "mapboxgl-canvas")
    //   return; // we only handle events on the map

    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();

      // only trash in certain circumstances
      // if(!points || !points.length) return;
      // if(!selected || !selected.length) return;
      // if(selected[0].geometry.type == "Point") return;
      // if(selected[0].geometry.type == "Polygon" && selected[0].geometry.coordinates[0].length - points.length < 4) return; // there would be less than three points left (4th is the beginning)
      // if(selected[0].geometry.type == "LineString" && selected[0].geometry.coordinates[0].length - points.length < 2) return;

      logger("starting delete");

      if (!selected || !selected.length) return; // nothing to delete

      // only allow deleting features if setup to accept
      if (!deleteFeaturesEnabled) {
        logger("deleting features not enabled");
        if (mode == "simple_select") return;

        if (!points || !points.length) return; // no points to delete and we're not allowing deleting features

        logger("checking delete points", points.length, points);

        for (const feature of selected) {
          if (feature.geometry.type == "Point") return; // can't delete point feature
          const pointsToCheck =
            feature.geometry.type == "Polygon"
              ? feature.geometry.coordinates[0].slice(0, -1)
              : feature.geometry.coordinates;
          const matchingPoints = pointsToCheck.reduce((count, pointA) => {
            for (const pointB of points) {
              if (
                pointA[0] == pointB.geometry.coordinates[0] &&
                pointA[1] == pointB.geometry.coordinates[1]
              )
                return count + 1;
            }

            return count;
          }, 0);

          logger(
            "checking points of feature",
            feature,
            pointsToCheck.length,
            matchingPoints,
            pointsToCheck
          );

          if (
            pointsToCheck.length - matchingPoints <
            (feature.geometry.type == "Polygon" ? 3 : 2)
          )
            return; // too few points remaining
        }
      }

      draw.trash();
    }
  }

  function init(
    $map: VectorGLMap,
    $loaded: boolean
    //, $filter: any[], $state: typeof state
  ) {
    //logger("init for map", $map);
    cleanup(); // cleanup previous map
    if (!$map || !$loaded) return;
    MAP = $map; // set

    // events
    MAP?.on("draw.selectionchange", ondrawselectionchanged);
    MAP?.on("draw.modechange", ondrawmodechange);
    MAP?.on("draw.create", ondrawcreate);
    MAP?.on("draw.update", ondrawupdate);
    MAP?.on("draw.delete", ondrawdelete);
    //MAP?.getCanvas()?.addEventListener("keydown", keydown);

    if (MAP && !addedToMap) {
      logger("adding draw control");
      MAP.addControl(draw, "bottom-left");
      draw.set(features);
      addedToMap = true;
      MAP.getContainer()?.addEventListener("keydown", keydown);
    }

    //selection(MAP, $filter, $state);
  }
  function cleanup() {
    if (!MAP) return; // no map to cleanup

    // events
    MAP?.off("draw.selectionchange", ondrawselectionchanged);
    MAP?.off("draw.modechange", ondrawmodechange);
    MAP?.off("draw.create", ondrawcreate);
    MAP?.off("draw.update", ondrawupdate);
    MAP?.off("draw.delete", ondrawdelete);
    if (addedToMap) {
      //draw.remove();
      //MAP?.removeControl(draw);
      addedToMap = false;
      MAP.getContainer()?.removeEventListener("keydown", keydown);
    }
    //MAP?.getCanvas()?.removeEventListener("keydown", keydown);
    //MAP?.off("click", onclick);

    //selection(MAP, filter, state);

    // set
    MAP = null;
  }

  $: logger(
    "ready?",
    draw && features.features.length && addedToMap,
    !!draw,
    !!features.features.length,
    !!addedToMap
  );
  $: if (draw && features.features.length && addedToMap) {
    logger("setting features on draw", features);
    draw.set(features);
  }

  $: if (draw && addedToMap && mode && mode != draw.getMode())
    draw.changeMode(mode);

  $: init(
    $map,
    mapHasBeenLoaded
    //, filter, state
  );

  onMount(function () {
    return function destroy() {
      cleanup();
    };
  });
</script>
