<script context="module">
  import { scaleTime } from "d3-scale";
  import { time } from "$utils/timestores";
  import {
    addMilliseconds,
    addWeeks,
    endOfDay,
    startOfDay,
    startOfWeek,
  } from "date-fns";
  import { toZonedTime, format } from "date-fns-tz";
  import { stringToDateInterval } from "./item/ItemsList.svelte";
  import range from "lodash-es/range";

  const nowutc = time({ minutes: 30 });
</script>

<script>
  import TimeNow from "./svg/SVGTimeNow.svelte";

  export let item = null;

  const tickCount = 5;

  $: nowlocal = toZonedTime($nowutc, item && item.timezone);

  $: interval =
    item && item.interval && stringToDateInterval(item.interval, item.timezone);

  $: ms = interval.end.getTime() - interval.start.getTime();
  $: min = addMilliseconds(interval.start, Math.ceil(ms * 0)); //startOfWeek(interval.start);
  $: max = addMilliseconds(interval.end, Math.ceil(ms * 0.1));

  $: timescale = interval && scaleTime().domain([min, max]).range([0, 100]);
  $: ticks = range(min, max, (max - min) / tickCount); //timescale.ticks(5);//.slice(0, -1);
  //$: logger("ticks=", ticks);
</script>

<figure class="violations">
  <svg class="violations" height="52" width="100%">
    <defs>
      <svg id="violation" width="16" height="16" viewBox="0 0 70 63"
        ><path
          d="m67.476 50.25-25.98-45c-2.888-5-10.104-5-12.991 0l-25.98 45c-2.888 5 .72 11.25 6.494 11.25h51.962c5.773 0 9.382-6.25 6.495-11.25z"
          fill="#fff"
          stroke="#fff"
          stroke-width="3"
        /><path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M40.197 6c-2.31-4-8.083-4-10.393 0L3.824 51c-2.31 4 .577 9 5.196 9h51.96c4.62 0 7.507-5 5.197-9L40.197 6zm-4.78 45.348c1.155 0 2.189-.398 3.065-1.233.836-.796 1.273-1.791 1.273-2.945 0-1.115-.437-2.11-1.273-2.945a4.655 4.655 0 0 0-1.5-.98 4.222 4.222 0 0 0-1.565-.294c-1.153 0-2.188.438-3.064 1.274a4.45 4.45 0 0 0-.943 1.353 3.928 3.928 0 0 0-.33 1.592c0 1.154.438 2.149 1.273 2.944.876.836 1.911 1.234 3.064 1.234zm3.264-23.12c.318-1.354.597-2.548.835-3.582.2-.995.32-1.83.32-2.468 0-1.114-.44-2.109-1.314-2.944-.876-.836-1.91-1.234-3.105-1.234-1.233 0-2.268.398-3.103 1.234-.876.835-1.314 1.83-1.314 2.944 0 .597.12 1.433.359 2.587.137.668.29 1.337.447 2.013l.349 1.49c.318 1.273.676 2.904 1.074 4.814.437 1.91.875 4.298 1.313 7.163h1.751l.074-.43c.445-2.638.824-4.882 1.239-6.733.398-1.95.757-3.541 1.075-4.855z"
          fill="#000"
        /></svg
      >
      <pattern
        id="diagonalHatch"
        width="3"
        height="3"
        patternTransform="rotate(45 0 0)"
        patternUnits="userSpaceOnUse"
      >
        <line class="violations period" x1="0" y1="0" x2="0" y2="100%" />
      </pattern>
    </defs>
    <svg class="values" width="100%" height="32">
      <rect width="100%" height="100%" />
      <rect
        class="violations exception period"
        x="{timescale(interval.start)}%"
        y="0%"
        height="100%"
        width="{timescale(interval.end) - timescale(interval.start)}%"
      />
      {#each Object.values(item.violations).map( (date) => toZonedTime(date, item.timezone) ) as ts}
        <line
          class="violation"
          x1="{timescale(ts)}%"
          x2="{timescale(ts)}%"
          y1="0%"
          y2="100%"
        />
        <!-- <circle class="violation" cy="3" cx="{timescale(violation)}%" r="3" /> -->
        <use
          transform="translate(-8,-8)"
          href="#violation"
          width="16"
          height="16"
          y="50%"
          x="{timescale(ts)}%"
        />
      {/each}
    </svg>
    <g class="legend">
      <!-- <line class="date min" x1="0%" x2="0%" y1="0%" y2="100%" /> -->
      {#each ticks as ts}
        <line
          class="date"
          class:min={0 == timescale(ts)}
          x1="{timescale(ts)}%"
          x2="{timescale(ts)}%"
          y1="0%"
          y2="100%"
        />
        <text class="date min" x="{timescale(ts)}%" y="100%"
          >{format(ts, "MMM d")}</text
        >
      {/each}
      <line class="date max" x1="100%" x2="100%" y1="0%" y2="100%" />
    </g>
    <svg class="context" width="100%" height="32">
      <TimeNow x="{timescale(nowlocal)}%" />
    </svg>
  </svg>
</figure>
