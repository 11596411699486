<script lang="ts" context="module">
  const enabled = navigator.canShare?.() ?? false;
  function share(e: Event) {
    if (!enabled) return;
    e.preventDefault();
    const a = e.currentTarget as HTMLAnchorElement;
    navigator.share({
      url: a.href,
    });
  }
</script>

<script lang="ts">
  import CopyButton from "./CopyButton.svelte";

  import CopyText from "./CopyText.svelte";

  export let url: string | URL;
  export let label: string;

  $: value = url instanceof URL ? url.toString() : url;
  $: logger("share enabled=", enabled);
</script>

<aside class="copy">
  <h1>{label}</h1>
  <CopyButton {value} />
  <a href={value} target="_blank" on:click={share}>Open</a>
</aside>
<!-- 
<CopyText {label} type="url" {value}>
  
</CopyText> -->
