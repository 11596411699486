import { authHeader, base, viewpoint } from "$utils/api";

export async function fetchDetections(scope: string, valid: string) {
    if (!scope) return null;
    const json = await Promise.all([
        fetch(
            `${base()}/detections/vehicles?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
                valid ?? ""
            )}&${authHeader}`
        ),
        // fetch(
        //     `${base()}/observations?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        //         valid
        //     )}&${authHeader}`
        // ),
    ])
        .then((values) => Promise.all(values.map((res) => res.json())))
        .then((values) => Object.assign({}, ...values))
        .then((values) => {

            for (const [k, v] of Object.entries(values.detections?.items || {})) {
                const item = values.detections.items[k] = values.items[v as string] || values.items[k] || v;
                if (item.file) item.file = values.items[item.file] || item.file;

            }

            return values;

        });

    return json;
}