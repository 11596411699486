<script context="module">
  const now = time({ seconds: 12 });
</script>

<script>
  import NavTo from "$components/NavTo.svelte";
  import SearchResults from "./SearchResults.svelte";
  import { smscreen } from "$utils/behaviorstores";
  import { externalCRM, unitStatusAttention } from "$utils/propertystores";
  import { view } from "$utils/uistores";
  import { help } from "$utils/help";
  import { time } from "$utils/timestores";
  import { format, toZonedTime } from "date-fns-tz";
  import NavSection from "./NavSection.svelte";
  import { autoIntelEnabled } from "$components/vehicle";

  export let active = false;
  export let property;

  $: timezone = property?.timezone;

  $: base = property ? `/properties/${property.id}` : "";
</script>

<nav class="app" class:active={true === active}>
  <SearchResults />

  {#if property}
    <ul class="primary">
      {#if $view == "live"}
        <li>
          <NavTo selected={$view == "live"} url="{base}/live"
            >Live {format(toZonedTime($now, timezone), "h:mm a zzz", {
              timeZone: timezone,
            })}</NavTo
          >
        </li>
      {/if}

      <li>
        <NavTo
          selected={$view == "activity" || $view == "dashboard"}
          url="{base}/activity">Activity</NavTo
        >
      </li>
    </ul>
    <NavSection id="parking" title="Parking Boss">
      <!-- <h2>Parking Boss</h2> -->
      <ul class="primary">
        <li>
          <NavTo selected={$view == "parking"} url="{base}/parking"
            >Parking</NavTo
          >
        </li>
        <li>
          <NavTo selected={$view == "parkingmap"} url="{base}/parking/map"
            >Smart Map</NavTo
          >
        </li>
        <!-- <li>
          <NavTo selected={$view == "parkingev"} url="{base}/parking/ev"
            >EV Charging</NavTo
          >
        </li> -->
        <li>
          <NavTo
            selected={$view && $view == "parkingenforcement"}
            url="{base}/parking/enforcement">Enforcement</NavTo
          >
        </li>
        {#if $autoIntelEnabled}
          <li>
            <NavTo
              selected={$view == "parkingmonitor"}
              url="{base}/parking/monitor">Monitor & Detect</NavTo
            >
          </li>
        {/if}
        <li>
          <NavTo
            selected={$view && $view == "parkinginsights"}
            url="{base}/parking/insights">Limits & Usage</NavTo
          >
        </li>

        <!-- <li>
        <NavTo
          selected={$view == "parking" || $view == "map"}
          url="{base}/parking">Parking</NavTo
        >
      </li> -->

        <!-- {#if !$smscreen}
        <li>
          <NavTo selected={$view == "map"} url="{base}/map">Parking Map</NavTo>
        </li>
      {/if} -->
      </ul>
    </NavSection>
    <NavSection id="amenities" title="Amenity Boss">
      <ul class="primary">
        <li>
          <!-- {#if property.amenities.enabled}
          <a
            target="_blank"
            href={sign(
              `https://amenities.communityboss.app/properties/${property.id}`
            )}>Amenities</a
          >
        {:else} -->

          <NavTo selected={$view == "amenities"} url="{base}/amenities"
            >Amenities</NavTo
          >
          <!-- {/if} -->
        </li>
      </ul>
    </NavSection>
    <!-- <h2>Amenity Boss</h2> -->

    {#if property.tenants?.tour?.enabled}
      <NavSection id="welcome" title="Welcome Boss">
        <!-- <h2>Welcome Boss</h2> -->
        <ul class="primary">
          <li>
            <NavTo selected={$view == "welcome"} url="{base}/welcome"
              >Welcome</NavTo
            >
          </li>
        </ul>
      </NavSection>
    {/if}
    <NavSection id="property" title="Property">
      <!-- <h2>Property</h2> -->
      <ul class="primary">
        {#if !$smscreen}
          <li>
            <NavTo selected={$view == "payments"} url="{base}/payments"
              >Payments</NavTo
            >
          </li>
        {/if}

        {#if property.units?.enabled}
          <li>
            <NavTo
              selected={$view == "units"}
              url="{base}/units"
              attention={$unitStatusAttention &&
                $externalCRM?.enabled &&
                $unitStatusAttention.length}>Units</NavTo
            >
          </li>
        {/if}
        {#if property.spaces?.enabled}
          <li>
            <NavTo selected={$view == "spaces"} url="{base}/spaces"
              >Spaces</NavTo
            >
          </li>
        {/if}
        <li>
          <NavTo selected={$view == "notices"} url="{base}/notices"
            >Documents</NavTo
          >
        </li>

        <li>
          <NavTo selected={$view == "users"} url="{base}/users">Team</NavTo>
        </li>
        {#if property.reports?.enabled}
          <li>
            <a href="{base}/reports">Reports</a>
          </li>
        {/if}
        <li>
          <NavTo selected={$view == "integrations"} url="{base}/integrations"
            >Integrations</NavTo
          >
        </li>
      </ul>
    </NavSection>
  {/if}
  <ul class="system ancillary">
    <li>
      <a href="/help" on:click={help}>Get Help</a>
    </li>
    <li>
      <a href="https://my.communityboss.app/user">Account</a>
    </li>
    <!-- <li>
        <a href="https://updates.parkingboss.com" target="_blank">Updates</a>
    </li> -->
    <li>
      <a href="/logout">Log Out</a>
    </li>
    <!-- <li>
        <a href="mailto:help@communityboss.com" rel="rt" data-unread="0"></a>
    </li> -->
  </ul>
</nav>
