<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import NotesField from "$components/form/NotesField.svelte";
  import { fetchViolationRevoke } from "$utils/api";

  export let item: Violation;
  export let cancel = true;

  export let values: Record<string, any> = {};

  const events = createEventDispatcher();

  let editing: Violation = item;
  let submitting = false;
  let submittable = false;
  let form: HTMLFormElement;

  // I'm not sure we ever need to update this, values will handle any changes
  //$: if (!values || !editing) editing = permit;

  $: if (!values._init && form) {
    form.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    change("_init", true);
  }

  async function onsubmit(e: SubmitEvent) {
    e.preventDefault();
    if (!submittable || submitting) return;
    e.preventDefault();

    // preprocess
    const form = e.target as HTMLFormElement;
    const formdata = new FormData(form);

    submitting = true;

    // do the stuff
    return fetchViolationRevoke(editing, formdata)
      .then(function (json) {
        if (json.message) alert(json.message);
        else {
          //events("complete", editing);
          //form.reset();
        }
      })
      .catch(function (error) {
        alert("Whoops, something went wrong!");
      })
      .then(function () {
        // cleanup
        //submitting = false;
        // onDestroy(function () {
        //   events("complete", editing);
        // });
      }); // how do we handle statuses here?
  }

  function updateSubmittable(form) {
    submittable = !!form?.checkValidity();
  }

  function change(name, value) {
    values[name] = value;
    events("change", values);
  }

  function validate(e) {
    //logger("onchange=", e.target.name, e.target.value, e);
    updateSubmittable(e.target.form); // additional logic?
  }

  function onreset(e) {
    events("cancel", editing);
  }

  $: form && updateSubmittable(form);

  onMount(function () {
    //logger("mounting violation remove = ", editing);
  });

  //$: logger("edits=", values, editing);

  //$: values && events("change", values);
</script>

<form
  bind:this={form}
  class="violation remove"
  on:submit={onsubmit}
  on:input={validate}
  on:change={validate}
  on:reset={onreset}
  novalidate
>
  <!-- <input type="hidden" name="scope" value={editing.scope} /> -->
  <input type="hidden" name={editing.type} value={editing.id} />
  <header><h1>Remove {item.title}:</h1></header>
  <ul>
    <li>
      <NotesField
        label="Reason"
        required={true}
        value={values.notes}
        on:change={(e) => change(e.detail.name, e.detail.value)}
      />
    </li>
  </ul>
  <footer>
    <fieldset>
      <button disabled={!submittable || submitting} type="submit"
        >{submitting ? "Removing…" : "Remove"}</button
      >
      {#if cancel && !submitting}
        <button type="reset">Cancel</button>
      {/if}
    </fieldset>
  </footer>
</form>
