<script lang="ts">
  import RecordAddress from "./RecordAddress.svelte";
  import RecordBuildingLevel from "./RecordBuildingLevel.svelte";
  import RecordExtras from "./RecordExtras.svelte";
  import RecordLink from "./RecordLink.svelte";
  import RecordSpaceCovered from "./RecordSpaceCovered.svelte";

  export let addressed: boolean | null;

  export let item: Unit = null;

  //$: logger("item=", item);

  $: building = item["building:ref"];
  $: floor = item["level:ref"];

  $: addrdisplay =
    !item["addr:unit"] &&
    [item["addr:housenumber"], item["addr:street"]].filter(Boolean).join(" ");

  $: includeAddressExtra =
    addressed ??
    ((!addrdisplay && item["addr:housenumber"] && item["addr:street"]) ||
      Boolean(building) ||
      Boolean(floor));

  $: unitsuffix =
    item?.["addr:unit"] && item.display.indexOf(item["addr:unit"]) == 0
      ? item.display.substring(item["addr:unit"].length)
      : null;
</script>

{#if item}
  <data
    class={["id", item.type, item.format].filter(Boolean).join(" ")}
    value={item.id || item.key}
  >
    <dfn
      ><abbr
        title={[
          item["addr:housenumber"],
          item["addr:street"],
          item["addr:unit"] && `#${item["addr:unit"]}`,
        ]
          .filter(Boolean)
          .join(" ") || item.display}
        >{item.name ||
          [item["addr:unit"], unitsuffix].filter(Boolean).join(" ") ||
          addrdisplay ||
          item.display}
        <slot><RecordExtras {item} /></slot></abbr
      ></dfn
    >
    {#if includeAddressExtra}
      <RecordAddress {item}><RecordBuildingLevel {item} /></RecordAddress
      >{/if}</data
  >
{/if}
