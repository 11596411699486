<svelte:options runes={true} />

<script lang="ts">
  import stored from "store";
  const {
    id,
    class: classname = null,
    children,
    title = "",
  } = $props<{
    id: string;
    class: string | null;
    children?: () => any;
    title?: string;
  }>();

  let open = $state(false !== (stored.get("section-" + id) ?? true));

  function onclick() {
    open = !open;
    stored.set("section-" + id, open);
  }
</script>

<section class={[id, classname]} class:open class:closed={!open}>
  <header>
    <h1><button {onclick} type="button">{title}</button></h1>
  </header>
  {#if open}
    {@render children?.()}
  {/if}
</section>
