<script lang="ts">
  import Contact from "$components/Contact.svelte";
  import { createEventDispatcher } from "svelte";
  import { fetchRemoveContact } from ".";

  export let item: Contact;
  let submitting = false;
  $: logger("contact item = ", item);
  const events = createEventDispatcher<{
    remove: Contact;
    removed: Contact;
  }>();

  async function remove() {
    if (
      !confirm(
        "Are you sure you want to remove this person? This is permanent and cannot be undone."
      )
    )
      return;
    if (submitting) return;
    submitting = true;
    await fetchRemoveContact(item.id);
    submitting = false;
    events("removed", item);
  }
</script>

<article class="{item.type} details">
  <header>
    <h1>{item.name || "No name"}</h1>
    <button type="button" disabled={submitting} class="remove" on:click={remove}
      >{submitting ? "removing…" : "remove"}</button
    >
  </header>
  <dl>
    <!-- {#if item.relationship} -->
    <dt>Type</dt>
    <dd>{item.relationship || "n/a"}</dd>
    <!-- {/if} -->
    <dt>Email</dt>
    <dd>{item.email || ""}</dd>
    <dt>Phone</dt>
    <dd>{item.tel || item.phone || ""}</dd>
  </dl>
</article>
