<script lang="ts" context="module">
  const sameYear = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    //year: "numeric",
    month: "short",
    day: "numeric",
  });

  const diffYear = new Intl.DateTimeFormat("en-US", {
    //weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  export function format(
    plaindate: string | Temporal.PlainDate | Temporal.PlainDateTime
  ) {
    const formattable = parseISO(plaindate.toString());

    const dateFormat =
      formattable.getFullYear() !== new Date().getFullYear()
        ? diffYear
        : sameYear;

    const parts = dateFormat.formatToParts(formattable);
    //logger(parts);
    return parts
      .filter(({ type }) => type !== "literal")
      .map(({ value }) => value)
      .join(" ");
  }
</script>

<script lang="ts">
  import { parseISO } from "date-fns";
  import { createEventDispatcher } from "svelte";

  export let empty: string = "";
  export let id: string = null;
  export let name: string = null;
  export let value: string = "";
  export let min: string = null;
  export let max: string = null;
  export let required: boolean = !empty;
  export let readonly: boolean = false;
  export let disabled: boolean = false;
  let calendar = false;

  const today = Temporal.Now.plainDateISO();
  const selectpre = today.add({ days: -1 });
  const selectpost = today.add({ days: 365 });

  const selectvalues: string[] = [];
  let d = selectpre.add({ days: 1 });
  while (d.toString() < selectpost.toString()) {
    selectvalues.push(d.toString());
    d = d.add({ days: 1 });
  }

  const events = createEventDispatcher();

  function change(newvalue: string) {
    if (value === newvalue) return; // nothing to change
    events("change", (value = newvalue));
  }
</script>

<select
  {id}
  {name}
  {value}
  {disabled}
  on:change={({ currentTarget: target }) => change(target.value)}
>
  {#if empty}
    <option value="">{empty}</option>
  {/if}
  {#each selectvalues as value}
    <option {value}>{format(value)}</option>
  {/each}
  <!-- <option value={selectpost.toString()}>later…</option> -->
</select>
<input
  type="date"
  {id}
  {name}
  {required}
  {readonly}
  {disabled}
  on:click={({ currentTarget: target }) =>
    !target.disabled &&
    !target.readOnly &&
    target.showPicker &&
    target.showPicker()}
  on:input={({ currentTarget: target }) => change(target.value)}
  on:change={({ currentTarget: target }) => change(target.value)}
  {value}
  {min}
  {max}
/>
