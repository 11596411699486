<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { title as proper } from "$utils/text";
  import NotesField from "$components/form/NotesField.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import { fetchPermittableCreate } from "$utils/api";
  import { permittablesUpdated } from "$utils/propertystores";
  export let record: Vehicle | Tenant = null;
  export let cancel = true;

  export let values: Record<string, any> = {};

  let active = false;
  let submittable = false;
  let submitting = false;
  let form: HTMLFormElement;

  $: label = proper(record?.title || record?.format || record.type);

  const events = createEventDispatcher();

  async function onsubmit(e: SubmitEvent) {
    if (!submittable || submitting) return;
    e.preventDefault();

    // preprocess
    const form = e.target as HTMLFormElement;
    const formdata = new FormData(form);

    submitting = true;

    //post
    return fetchPermittableCreate(formdata)
      .then(function (json) {
        switch (json.status) {
          default:
            const item = json.permittables?.item;
            if (!item)
              return alert(
                ["Could not be banned", json.message]
                  .filter((i) => !!i)
                  .join(": ")
              );
            events("create", item);
            form.reset();
            //policy = null;
            break;
        }
      })
      .catch(function (error) {
        logger("error=", error);
        alert("Whoops, something went wrong!");
      })
      .then(function () {
        // cleanup
        submitting = false;
        permittablesUpdated(true);
      }); // how do we handle statuses here?
  }
  function updateSubmittable(form) {
    submittable = !!form?.checkValidity();
  }

  function validate(e) {
    //logger("onchange=", e.target.name, e.target.value, e);
    updateSubmittable(e.target.form); // additional logic?
  }

  function change(name, value) {
    values[name] = value;
    events("change", values);
  }
  function onreset(e) {
    active = false;
    events("cancel", record);
  }
</script>

{#if record && active}
  <form
    bind:this={form}
    class="permittable donotpermit"
    on:submit={onsubmit}
    on:input={validate}
    on:change={validate}
    on:reset={onreset}
    novalidate
  >
    <slot />
    <input type="hidden" name="scope" value={record.scope} />
    <input type="hidden" name={record.type} value={record.id} />
    <input type="hidden" name="permittable" value="false" />
    <ul>
      <li>
        <figure class="value record">
          {#if label}
            <figcaption>{"Ban" || label}</figcaption>
          {/if}
          <RecordItem item={record} url={false} />
        </figure>
      </li>
      <li>
        <NotesField
          required={true}
          label="Reason"
          value={values.notes ?? ""}
          on:change={(e) => change(e.detail.name, e.detail.value)}
        />
      </li>
    </ul>

    <footer>
      <fieldset>
        <button disabled={!submittable || submitting} type="submit"
          >{submitting ? "Saving…" : `Ban ${label}`}</button
        >
        {#if cancel && !submitting}
          <button type="reset">Cancel</button>
        {/if}
      </fieldset>
    </footer>
  </form>
{:else if record}
  <!-- <nav>
    <ul>
      <li> -->
  <button type="button" class="donotpermit" on:click={(e) => (active = true)}
    >Ban {label}…</button
  >
  <!-- </li>
    </ul>
  </nav> -->
{/if}
