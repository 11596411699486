<script context="module">
</script>

<script>
  import ItemsChartItem from "./ItemsChartItem.svelte";
  import PermitConflictsSummaryItem from "./PermitConflictsSummaryItem.svelte";
  export let item;

  //$: logger("conflicts=", item);
</script>

<article class="{item.type} details">
  <PermitConflictsSummaryItem {item} interval={false} />
  <ItemsChartItem {item} />
</article>
