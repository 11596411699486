<script context="module">
  import Record from "$components/record/RecordItem.svelte";
</script>

<script>
  import { display } from "$utils/datetime";
  import { textAsc } from "$utils/sort";
  import { tick } from "svelte";

  export let property;
  export let units;
  export let tenants;

  let url;

  $: parking = property?.attendant?.url;
  $: if (!url && parking) url = parking;
  $: directory =
    property?.tenants.tour?.enabled &&
    `https://my.communityhq.app/?property=${property.id}`;

  //$: logger(url, parking, directory, parking == url, directory == url);

  function preview(e) {
    logger(e);

    if (e.metaKey || e.ctrlKey || e.shiftKey) return;
    if (e.defaultPrevented) return;

    var link = e.target.closest("a");

    if (!link?.href) return;

    url = link.href;

    e.preventDefault();
  }

  async function copy(e) {
    if (!e.target.value || e.target.href) return; // nothing to copy

    var target = e.target;

    if (target.value) target.select();

    await navigator.clipboard.writeText(target.value ?? target.href);

    target.classList.add("copied");

    setTimeout((cl) => target.classList.remove(cl), 3000, "copied");
  }
</script>

<section class="apps">
  <nav on:click={preview}>
    <header><h1>Apps</h1></header>
    <ul>
      <li><a class:selected={url == parking} href={parking}>Parking</a></li>
      <li>
        <a class:selected={url == directory} href={directory}>Directory</a>
      </li>
    </ul>
    <h1>Self-Guided Move-In</h1>
    <ul>
      {#each Object.values(tenants || {})
        .sort((a, b) => textAsc(a.display, b.display))
        .map( (item) => [item, `https://my.communityhq.app/?account=${item.id}`] ) as [item, directory]}
        <li>
          <a class:selected={url == directory} href={directory}>
            <Record {item} url={false} />
          </a>
        </li>
      {/each}
    </ul>
  </nav>
  <figure class="app">
    <iframe src={url || parking} />
    <figcaption>
      <input type="text" value={url} readonly on:click={copy} />
      <!-- <button class="copy" value="{url}" on:click={copy}>Copy</button>
        </figcaption> -->
    </figcaption>
  </figure>
</section>
