<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { title as proper } from "$utils/text";
  import NotesField from "$components/form/NotesField.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import { fetchResetTenantCode } from "$utils/api";
  import { authcodeUpdated } from "$utils/propertystores";
  import NumberInputField from "$components/form/NumberField.svelte";
  export let record: Tenant = null;
  export let cancel = true;
  export let length: number = 6;

  export let values: Record<string, any> = {};

  let active = false;
  let submittable = false;
  let submitting = false;
  let form: HTMLFormElement;

  $: label = proper(record?.title || record?.format || record.type);

  const events = createEventDispatcher();

  async function onsubmit(e: SubmitEvent) {
    if (!submittable || submitting) return;
    e.preventDefault();

    // preprocess
    const form = e.target as HTMLFormElement;
    const formdata = new FormData(form);

    submitting = true;

    //post
    return fetchResetTenantCode(record, formdata)
      .then(function (json) {
        switch (json.status) {
          default:
            // const item = json.permittables?.item;
            // if (!item)
            //   return alert(
            //     ["Could not be banned", json.message]
            //       .filter((i) => !!i)
            //       .join(": ")
            //   );
            // events("create", item);
            form.reset();
            active = false;
            //policy = null;
            break;
        }
      })
      .catch(function (error) {
        logger("error=", error);
        alert("Whoops, something went wrong!");
      })
      .then(function () {
        // cleanup
        active = false;
        values = {};
        submitting = false;
        authcodeUpdated(true);
      }); // how do we handle statuses here?
  }
  function updateSubmittable(form) {
    submittable = !!form?.checkValidity();
  }

  function validate(e) {
    //logger("onchange=", e.target.name, e.target.value, e);
    updateSubmittable(e.target.form); // additional logic?
  }

  function change(name, value) {
    values[name] = value;
    events("change", values);
  }
  function onreset(e) {
    active = false;
    events("cancel", record);
  }
</script>

{#if record && active}
  <form
    bind:this={form}
    class="permittable donotpermit"
    on:submit={onsubmit}
    on:input={validate}
    on:change={validate}
    on:reset={onreset}
    novalidate
  >
    <slot />
    <ul>
      <li>
        <figure class="value record">
          {#if label}
            <figcaption>{label}</figcaption>
          {/if}
          <RecordItem item={record} url={false} />
        </figure>
      </li>
      <li>
        <NumberInputField
          name="code"
          label="Passcode"
          min="1{'0'.repeat(length - 1)}"
          max={"9".repeat(length)}
          placeholder="autogenerate or {length} digits"
          on:change={(e) => change(e.detail.name, e.detail.value)}
        />
      </li>
      <li>
        <NotesField
          required={true}
          label="Reason"
          value={values.notes ?? ""}
          on:change={(e) => change(e.detail.name, e.detail.value)}
        />
      </li>
    </ul>

    <footer>
      <fieldset>
        <button disabled={!submittable || submitting} type="submit"
          >{submitting ? "Saving…" : `Reset`}</button
        >
        {#if cancel && !submitting}
          <button type="reset">Cancel</button>
        {/if}
      </fieldset>
    </footer>
  </form>
{:else if record}
  <!-- <nav>
    <ul>
      <li> -->
  <button type="button" class="passcode" on:click={(e) => (active = true)}
    >Reset Passcode…</button
  >
  <!-- </li>
    </ul>
  </nav> -->
{/if}
