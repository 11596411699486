<script>
  //import { externalCRMRentables } from "$utils/propertystores";
  import { sortBy } from "$utils/sort";
  import ItemsList from "./item/ItemsList.svelte";
  import Loading from "./Loading.svelte";
  import UnitStatusItem from "./unit/UnitStatusItem.svelte";

  export let data;
  export let property;

  // const start = addDays(new Date(), -90);
  // const end = addDays(new Date(), 30);

  // const interval = `${start.toISOString()}/${end.toISOString()}`;

  // $: logger("interval=", interval);

  // $: status = data && data.unitstatus && data.unitstatus["for"] && sortBy(Object.entries(data.unitstatus["for"]).map(([ unit, details]) => [ data.items[unit], details]), ([unit]) => unit.display) || [];

  let submitting = {};

  async function dataToItems(promise) {
    const data = await promise;
    return (
      (data &&
        data.unitstatus &&
        data.unitstatus["for"] &&
        sortBy(
          Object.entries(data.unitstatus["for"]).map(
            ([unit, details]) => details
          ),
          (details) => details.subject?.display
        )) ||
      []
    );
  }

  $: status = dataToItems(data).then((data) => {
    logger(
      "unlinked units",
      data.filter((status) => !status.subject?.id)
    );
    return data;
  });

  // $: linkedRentables =
  //   $externalCRMRentables &&
  //   Object.values($externalCRMRentables)?.filter(
  //     (rentable) => rentable.subject?.id
  //   );
  // $: unlinkedRentables =
  //   $externalCRMRentables &&
  //   Object.values($externalCRMRentables)?.filter(
  //     (rentable) => !rentable.subject?.id
  //   );

  // $: logger("unlinked rentables=", unlinkedRentables);

  // $: logger("status=", status);
  // $: status && status.then(console.log);

  // $: logger("submitting=", submitting);
</script>

{#if status}
  {#await status}
    <figure>Loading&hellip;</figure>
  {:then status}
    <section>
      <header><h1>Needs attention</h1></header>
      <ItemsList
        class="activity highlight"
        items={status.filter(
          (status) =>
            (status.subject?.id && status?.check?.vacant) ||
            status?.check?.reoccupied
        )}
        types={{ unitstatus: UnitStatusItem }}
      >
        <aside class="empty units history">None right now</aside>
      </ItemsList>
    </section>
    <section>
      <header><h1>Vacant</h1></header>
      <ItemsList
        class="items"
        items={status.filter(
          (status) =>
            status.subject?.id && status?.vacant && !status?.check.vacant
        )}
        types={{ unitstatus: UnitStatusItem }}
        ><aside class="empty units history">None right now</aside></ItemsList
      >
    </section>
    <section>
      <header><h1>Occupied</h1></header>
      <ItemsList
        class="items"
        items={status.filter(
          (status) =>
            status.subject?.id && status?.occupied && !status?.check?.reoccupied
        )}
        types={{ unitstatus: UnitStatusItem }}
      />
    </section>
  {/await}
{:else}
  <figure>Loading&hellip;</figure>
{/if}
<section>
  <header>
    <h1>Unlinked</h1>
  </header>
  {#await status}
    <Loading message="Checking unlinked units" />
  {:then status}
    <ItemsList
      class="items"
      items={status.filter((status) => !status.subject?.id)}
      types={{ unitstatus: UnitStatusItem }}
      ><aside class="empty units history" slot="empty">
        All are linked
      </aside></ItemsList
    >
  {/await}
</section>
