<script lang="ts">
  import MapMarker from "$components/map/MapMarker.svelte";
  import MapWebGl from "$components/map/MapWebGL.svelte";

  import {
    derived,
    writable,
    type Readable,
    type Writable,
  } from "svelte/store";

  import type { Feature, FeatureCollection } from "geojson";
  import { query } from "$utils/router";

  import { param } from "$utils/params";
  import MapFeaturesEditor from "$components/map/MapFeaturesEditor.svelte";
  import { propertyId } from "$utils/propertystores";
  import { fetchPropertyGeoFeatures } from "$components/map/api";
  import SelectField from "$components/form/SelectField.svelte";
  import Option from "$components/form/Option.svelte";
  import TextareaField from "$components/form/TextareaField.svelte";
  import { fetchRemoveGeoFeature, fetchUpdateGeoFeature } from "$utils/api";
  import ImageUrlField from "$components/form/ImageUrlField.svelte";
  import type { IdentifiedFeature } from "$sections/PropertySignageSection.svelte";
  import SignFeatureEdit from "./SignFeatureEdit.svelte";

  export let property: Property;
  export let features: Writable<FeatureCollection>;
  let levels: Record<string, any>;

  let submittable = false;
  let submitting = false;
  let selected: IdentifiedFeature[] = [];
  const updated: Record<string, IdentifiedFeature> = {};
  const deleted: Record<string, IdentifiedFeature> = {};
  let editing: IdentifiedFeature | null = null;
  const defaultMode = "simple_select";
  let mode = defaultMode;
  $: adding = mode == "draw_point";

  // update editing state
  $: if (
    selected &&
    selected.length == 1 &&
    (!editing || editing.id != selected[0].id)
  )
    editing = !deleted[selected[0].id]
      ? updated[selected[0].id] || selected[0]
      : null;
  $: if (!selected || selected.length != 1) editing = null;

  function onlevelchange(e: CustomEvent<string>) {
    logger("level change=", e.detail);
    query("level", e.detail);
  }

  function onchange(e: CustomEvent<Feature[]>) {
    logger("geometry changed=", e.detail);
    geometryChanged(e.detail);
  }
  function onselect(e: CustomEvent<Feature[]>) {
    logger("features selected=", e.detail);
    featuresSelected(e.detail as IdentifiedFeature[]);
  }
  function ondelete(e: CustomEvent<Feature[]>) {
    logger("features deleted=", e.detail);
    featuresDeleted(e.detail as IdentifiedFeature[]);
  }
  function oncreate(e: CustomEvent<Feature[]>) {
    logger("features created=", e.detail);
    featuresCreated(e.detail as IdentifiedFeature[]);
  }

  function isDeleted(feature: Feature): Boolean {
    return !!deleted[(feature as unknown as IdentifiedFeature).id];
  }

  function featuresSelected(features: IdentifiedFeature[]) {
    selected = features;
    logger("featuresSelected", selected);
  }

  function featuresDeleted(features: IdentifiedFeature[]) {
    for (const feature of features) {
      deleted[feature.id] = feature;
      if (updated[feature.id]) delete updated[feature.id];
    }
    //deleted = deleted;
    //submittable = true;
    logger("featuresDeleted", deleted);
  }

  function geometryChanged(features: Feature[]) {
    for (const feature of features) {
      (updated[(feature as unknown as IdentifiedFeature).id] ??=
        feature as unknown as IdentifiedFeature).geometry = feature.geometry;
    }
    //updated = updated;
    //submittable = true;
    logger("featuresChanged", updated);
  }

  function featuresCreated(features: IdentifiedFeature[]) {
    for (const feature of features) {
      updated[feature.id] = feature;
      // set properties
      feature.properties = {
        ...feature.properties,
        amenity: "parking",
        man_made: "sign",
        information: "sign",
        placement: "post",
        message: "",
        inscription: "No Parking",
      };
    }
    //updated = updated;
    //submittable = true;
    logger("featuresCreated", updated);
  }

  function propertiesChanged(features: IdentifiedFeature[]) {
    for (const feature of features) {
      (updated[feature.id] ??= feature).properties = feature.properties;

      // if (updated[feature.id])
      //   updated[feature.id].properties = feature.properties;
      // else updated[feature.id] = feature;
    }
    //updated = updated;
    //submittable = true;
    submittable = true;
    logger("propertiesChanged", updated);
  }

  async function update() {
    if (!property || submitting || !submittable) return;

    submitting = true;
    //mode = defaultMode;
    selected = [];

    await fetchRemoveGeoFeature(property, Object.keys(deleted), features);
    // for(const [ id, feature ] of Object.entries(deleted)) {
    //     await fetchRemoveGeoFeature(property, feature, features);
    // }
    for (const key of Object.keys(deleted)) {
      delete deleted[key];
    }

    for (const [id, feature] of Object.entries(updated)) {
      await fetchUpdateGeoFeature(property, feature, features);
    }

    for (const key of Object.keys(updated)) {
      delete updated[key];
    }

    submitting = false;
    submittable = false;
  }

  $: logger("updated=", updated);

  $: if (Object.keys(updated).length || Object.keys(deleted).length)
    submittable = true;
</script>

<section class="signage">
  {#if property}
    <MapWebGl
      class="signs"
      style="https://carto.communityboss.app/v1/properties/{property?.id}/styles/cool.json?level=outside&parking=true&overlay=false"
    >
      {#if adding}
        <button
          title="Cancel Add Sign"
          class="cancel"
          type="button"
          on:click={(e) => (mode = defaultMode)}>Cancel</button
        >
        <!-- <figure class="help">
        <figcaption>Click on the map to add a sign</figcaption>
      </figure> -->
      {:else}
        <button
          title="Add Sign&hellip;"
          class="create"
          type="button"
          on:click={(e) => (mode = "draw_point")}>Add Sign&hellip;</button
        >
      {/if}
      <MapFeaturesEditor
        deletable={false}
        bind:mode
        features={{
          ...$features,
          features: $features.features.filter((f) => !isDeleted(f)),
        }}
        on:delete={ondelete}
        on:select={onselect}
        on:change={onchange}
        on:create={oncreate}
      />
    </MapWebGl>
    <!-- <PropertyMap
        {property}
        level={$level}
        on:level={onlevelchange}
        bind:levels
      ></PropertyMap> -->
  {/if}
  <footer>
    {#if editing}
      <SignFeatureEdit
        feature={editing}
        on:change={(e) => propertiesChanged([e.detail])}
      >
        <footer>
          <fieldset>
            <button
              class="remove"
              type="button"
              on:click={(e) => (
                editing && featuresDeleted([editing]), featuresSelected([])
              )}>Remove</button
            >
          </fieldset>
        </footer>
      </SignFeatureEdit>

      <!-- <dl>
          {#each Object.entries(editing.properties ?? {}) as [key, value]}
            <dt>{key}</dt>
            <dd>{value}</dd>
          {/each}
        </dl> -->
    {/if}
    <button
      on:click={update}
      class="submit"
      type="button"
      disabled={!submittable || submitting}
    >
      {submitting ? "Saving…" : "Save Changes"}
    </button>
  </footer>
</section>
